{
  "common" : {
    "thisWeek" : "Esta semana",
    "lastWeek" : "La semana pasada",
    "lastNumberOfDays" : "los últimos {numberOfDays} días",
    "thisMonth" : "Este mes"
  },
  "subAccounts" : "Subcuenta",
  "submitFeedback" : "Enviar comentario",
  "createSubAccount" : "Crear Subcuenta",
  "viewScheduleReports" : "Ver informes Programado",
  "searchSubAccount" : "Buscar Vía Sub-Cuenta",
  "columns" : "Columnas",
  "sortBy" : "Filtrar por",
  "selectColumns" : "Seleccionar columnas",
  "maxColumns" : "Máx. 8 columnas a la vez",
  "applyColumns" : "Aplicar columnas",
  "activeUsers" : "Usuarios activos",
  "appointments" : "Citas",
  "contacts" : "Contactos",
  "emails" : "Gmail",
  "textMessages" : "Cuerpo de los mensajes",
  "calls" : "Llamadas",
  "submissions" : "Envíos",
  "reviews" : "Reseñas",
  "sitesCreated" : "Sitios Creada",
  "name" : "Nombre",
  "formSubmissions" : "Envíos de Formulario",
  "surveySubmissions" : "Envíos de Encuesta",
  "facebookFormSubmissions" : "Envíos de Formulario de Facebook",
  "funnels" : "Embudos",
  "websites" : "Sitios web",
  "forms" : "Formularios",
  "surveys" : "Encuestas",
  "highestFirst" : "Máx. primero",
  "lowestFirst" : "Primero el más bajo",
  "orderBy" : "Ordenar Vía",
  "noMoreLocations" : "No más Dirección encontrada",
  "preparingReport" : "Cargando tu informe",
  "preparingReportDesc" : "No cerrar O actualizar esta ventana mientras la exportación está in curso",
  "outOf" : "De",
  "subAccountsExported" : "Subcuenta exportada",
  "close" : "Cerrar",
  "scheduledForDeletion" : "Programado para eliminación",
  "readyToSell" : "Listo para vender",
  "closedWon" : "Cerrado (Ganado)",
  "wpSaleSuccessful" : "La venta de WordPress se ha llevado a cabo con éxito",
  "wpNotSold" : "WordPress aún no se ha vendido",
  "yxtSaleSuccessful" : "La venta de Yext se ha llevado a cabo correctamente",
  "yxtNotSold" : "Ytexto que aún no se ha vendido",
  "saasSubInactive" : "La suscripción SaaS está inactiva",
  "saasSubTrialing" : "SaaS Suscripción en prueba",
  "saasSubActive" : "SaaS Suscripción es Dinámico",
  "emailRebillingActive" : "Correo electrónico Refacturación es Dinámico",
  "emailRebillingInactive" : "El correo electrónico Refacturación está inactivo",
  "twilioRebillingActive" : "Twilio Refacturación es Dinámico",
  "twilioRebillingInactive" : "Twilio Refacturación está inactivo",
  "wpRebillingActive" : "WhatsApp Refacturación es Dinámico",
  "wpRebillingInactive" : "WhatsApp Refacturación está inactivo",
  "saasModeActivated" : "El modo SaaS está habilitado",
  "paymentPending" : "El Pago está pendiente",
  "saasModeNotActivated" : "El modo SaaS no está habilitado",
  "managedByLCPhone" : "Gestionar Vía LC Teléfono",
  "migrationToLCPhone" : "En proceso de migración a LC Teléfono",
  "notManagedByLCPhone" : "Not Gestionar Vía LC Teléfono",
  "managedByLCEmail" : "Gestionar a través LeadConnector Email System",
  "notMangedByLCEmail" : "No gestionada a través LeadConnector Email System",
  "remainingWalletBalance" : "Monedero restante Saldo de este SaaS Ubicación",
  "reports" : "Informes",
  "switchToSubAccount" : "Cambiar a la Subcuenta",
  "manageClient" : "Gestionar Cliente",
  "subAccountDeletedIfChangedMind" : "Esta Subcuenta será Eliminado Activado {time}, Si Tú've Dinámico tu mente",
  "subAccountDeletedInMinute" : "Esta Subcuenta será Eliminado dentro del Próximo minuto",
  "changedMind" : "Si Tú've Dinámico tu mente",
  "clickHere" : "hacer clic aquí",
  "subAccountPaused" : "Esta Subcuenta está en pausa. Tus clientes no pueden iniciar sesión, pero la agencia sí. Para reanudar esta cuenta",
  "subAccountRestricted" : "Esta Subcuenta está Pausado Debido a Pendiente de Aprobación.",
  "subAccountVerificationPending" : "Esta subcuenta está pausada debido a la verificación pendiente del correo electrónico y del teléfono del administrador de la subcuenta.",
  "subAccountDeclined" : "Esta subcuenta está pausada debido a la aprobación de solicitudes rechazadas. Tú puedes todavía Reanudar esta Subcuenta De ",
  "here" : "aquí.",
  "reviewAndResume" : "a Reseña y Reanudar esta Subcuenta.",
  "incomingCalls" : "Llamadas entrantes",
  "outgoingCalls" : "Llamadas salientes",
  "totalTalktime" : "Tiempo Total en conversación",
  "incomingEmails" : "Gmailentrante",
  "outgoingEmails" : "Gmailsaliente",
  "incomingSms" : "Mensajes de Cuerpo entrantes",
  "outgoingSms" : "Mensajes de cuerpo salientes",
  "contactsCreated" : "número de Amigos Creada",
  "appointmentsBooked" : "número de Cita reservado",
  "totalFormSubmissions" : "Total de envíos de formularios",
  "totalSurveySubmissions" : "Total de envíos de Encuesta",
  "totalFacebookFormSubmissions" : "Total de envíos de Formulario de Facebook",
  "incomingGMBMessages" : "Mensajes entrantes en GBP",
  "outgoingGMBMessages" : "Mensajes salientes en GBP",
  "gmbMessages" : "Mensajes en GBP",
  "fbMessages" : "Mensajes de Facebook",
  "incomingFbMessages" : "Mensajes entrantes de Facebook",
  "outgoingFbMessages" : "Mensajes salientes de Facebook",
  "igMessages" : "Mensajes de Instagram",
  "incomingIgMessages" : "Mensajes entrantes de Instagram",
  "outgoingIgMessages" : "Mensajes salientes de Instagram",
  "totalReviews" : "Total de reseñas",
  "positiveReviews" : "Reseñas positivas",
  "negativeReviews" : "Reseñas negativas",
  "back" : "Atrás",
  "selectDashboards" : "Seleccionar tableros",
  "next" : "Siguiente",
  "backToSubAccounts" : "Atrás a la Subcuenta",
  "pauseSchedule" : "Pausar la programación",
  "resumeSchedule" : "programación de la Reanudar",
  "editSchedule" : "Editar la programación",
  "addSchedule" : "Añadir programación",
  "selectReportToAdd" : "Seleccionar los informes para incluir in el correo electrónico",
  "selectAll" : "Seleccionar cada",
  "pauseScheduleConfirm" : "¿Estás seguro de que quieres pausar el informe de programación?",
  "resumeScheduleConfirm" : "¿Estás seguro de que quieres reanudar el informe programación?",
  "tasks" : "Tareas",
  "conversations" : "Conversaciones",
  "users" : "Usuario",
  "dealOverview" : "Visión general del Trato",
  "confirmAction" : "Confirmar la Acción",
  "yes" : "Sí",
  "no" : "No",
  "reportDetails" : "Detalles del informe",
  "reportCreatedOn" : "Reportar Creada Activado",
  "searchByEmail" : "Buscar Vía Correo electrónico",
  "lastActivity" : "Última actividad",
  "noRecords" : "No se han encontrado Registros",
  "scheduleReport" : "Informe de la programación",
  "schedule" : "programación",
  "save" : "Guardar",
  "emailSubject" : "Línea de asunto",
  "frequency" : "Frecuencia",
  "daily" : "Diario",
  "weekly" : "Semanal",
  "monthly" : "Mensual",
  "nextScheduleDate" : "Siguiente programación fecha",
  "startingOn" : "Iniciando Activado",
  "timeOfDay" : "Fecha de día",
  "reportsToTimezone" : "El informe se enviará in la zona horaria de la Subcuenta",
  "selectRecipients" : "Seleccionar los destinatarios",
  "invalidEmail" : "Has introducido una no dirección de correo electrónico válida",
  "testEmail" : "Correo electrónico de Prueba",
  "selectTestRecipients" : "Seleccionar los destinatarios de la Prueba",
  "setupSubject" : "Configura el Asunto de arriba para probar el informe",
  "sendTest" : "Subir Prueba",
  "scheduleSimilarSchedules" : "informe similar de programación para Otro Sub-Cuenta",
  "accountSummary" : "Resumen de la Cuenta",
  "scheduleList" : "programación Lista",
  "subAccountName" : "Nombre de la subcuenta",
  "nextRunDate" : "Fecha de la Siguiente ejecución",
  "reportSectionsAdded" : "Informar Segmentos añadidos",
  "status" : "Estado",
  "scheduleAction" : "programación Acción",
  "cancel" : "Cancelar",
  "pleaseWait" : "Esperar",
  "confirm" : "Confirmar",
  "booked" : "Reservado",
  "confirmed" : "Confirmada",
  "showed" : "Asistido",
  "noShow" : "No asistido",
  "cancelled" : "Cancelado",
  "unread" : "No leído",
  "engagement" : "Compromiso",
  "reaction" : "Reacción",
  "deliverySummary" : "Resumen de Entrega",
  "successfulDelivery" : "Entrega satisfactoria",
  "opened" : "Abierto",
  "clicked" : "Clicado",
  "replied" : "Respondidas",
  "unsubscribed" : "Suscripción cancelada",
  "complained" : "Reclamado/denunciado",
  "failed" : "Error",
  "open" : "Abrir",
  "won" : "Ganado",
  "abandoned" : "Abandonado",
  "lost" : "Perdido",
  "sent" : "Enviado",
  "eject" : "Expulsar",
  "delivered" : "Enviado",
  "faceBookLeadForm" : "Formulario de Cliente potencial de Facebook",
  "overdue" : "Atrasado",
  "reviewsReceived" : "Reseñas Recibido",
  "formBuilders" : "Creadores de Formulario",
  "switchToSaaS" : "Cambiar a SaaS",
  "numberOfContacts" : "número de Amigos",
  "numberOfAppointments" : "número de Cita",
  "outreach" : "Divulgación",
  "notSubAccountUser" : "No es un usuario de la Subcuenta",
  "searchSubAccName" : "Buscar Vía Sub-Cuenta's name",
  "recipients" : "Destinatarios",
  "scheduleCreated" : "Correo electrónico Informe sobre la programación Creada para ",
  "scheduleUpdated" : "Correo electrónico Informe de programación Actualizado para ",
  "scheduleNotCreated" : "No se ha podido crear el correo electrónico Informe de la programación para",
  "scheduleNotUpdated" : "El correo electrónico Informar de la programación no ha podido actualizarse para",
  "total" : "Total",
  "inbound" : "Entrante",
  "outbound" : "Saliente",
  "totalEmails" : "Total Gmail",
  "inboundEmails" : "Gmailentrante",
  "outboundEmails" : "Saliente Gmail",
  "totalTextMessages" : "Mensajes de Total Cuerpo",
  "inboundTextMessages" : "Mensajes del cuerpo entrantes",
  "outboundTextMessages" : "Mensajes de Saliente Cuerpo",
  "totalCalls" : "Total de llamadas",
  "inboundCalls" : "Llamadas entrantes",
  "outboundCalls" : "Llamadas Saliente",
  "faceBookLeadFormSubmissions" : "Envíos de Formulario de Cliente potencial de Facebook",
  "inboundGMBMessages" : "Mensajes entrantes en GBP",
  "outboundGMBMessages" : "Mensajes Saliente en GBP",
  "totalIgMessages" : "Total de mensajes de Instagram",
  "inboundIgMessages" : "Mensajes entrantes de Instagram",
  "outboundIgMessages" : "Mensajes Saliente de Instagram",
  "totalfbMessages" : "Total de mensajes de Facebook",
  "inboundfbMessages" : "Mensajes entrantes de Facebook",
  "outboundfbMessages" : "Mensajes Saliente de Facebook",
  "sitesCreatedFunnels" : "Sitios Creada - Embudos",
  "sitesCreatedActiveFunnels" : "Sites Creada - Dinámico Embudos",
  "sitesCreatedWebsites" : "Sitios Creada - sitios web",
  "sitesCreatedActiveWebsites" : "Sites Creada - Dinámico",
  "sitesCreatedForms" : "Sitios Creada - Formularios",
  "sitesCreatedActiveForms" : "Sites Creada - Dinámico Forms",
  "sitesCreatedSurveys" : "Sitios Creada - Encuestas",
  "sitesCreatedActiveSurveys" : "Sites Creada - Dinámico Surveys",
  "googleReviews" : "Reseñas en Google",
  "facebookReviews" : "Reseñas de Facebook",
  "inboundCallDuration" : "Duración de la Llamar entrante (hora)",
  "outboundCallDuration" : "Saliente Llamar Duración (hora)",
  "pleaseSelect" : "Seleccionar",
  "subAccountIsDeleted" : "Esta Subcuenta ha sido eliminada",
  "subAccountIsPaused" : "Esta Subcuenta ha sido pausada",
  "success" : "Conseguido",
  "error" : "Error",
  "testEmailSent" : "Correo electrónico de Prueba enviado",
  "testEmailNotSent" : "No se ha podido enviar la prueba de correo electrónico",
  "reviewRequest" : "Solicitudes de Reseña",
  "pendingTransferDescription" : "Otro Vista de agencia ha solicitado Transferir Sub-Cuenta a Tú.",
  "pendingTransferHeading" : "Tienes solicitudes pendientes de Subcuenta Transferir.",
  "subAccountTransfer" : "Sub-Cuenta Descargar",
  "manageSubAccTransfer" : "Gestionar tu Subcuenta Transferir Solicitudes",
  "subAccRequest" : "Subcuenta {tabName} Solicitudes",
  "receive" : "Heredar",
  "send" : "Enviar",
  "release" : "Publicar",
  "requests" : "{count} Solicitudes",
  "subAccLimit" : "¡Has alcanzado el SLOTMAX de uso de la subcuenta!",
  "subAccPlanLimit" : "Has utilizado los 3/3 de la Subcuenta permitida en tu Plan Actual .",
  "upgradeTime" : "¡Es la Fecha para un Actualizar/mejorar!",
  "upgradeHigher" : "Actualizar/mejorar a un Plan superior para aceptar la subcuenta transferir.",
  "browse" : "Examinar planes",
  "pendingTransferTitle" : "Ya está pendiente una transferencia de solicitudes para esta subcuenta.",
  "unableTransfer" : "No se ha podido transferir la subcuenta a esta agencia.",
  "unableEjectCancel" : "No se han podido cancelar las solicitudes de expulsión de la subcuenta",
  "EjectErrorDetails" : "Las Subcuentas de expulsión de Solicitudes que Tú estás intentando Cancelar no se pueden cancelar.",
  "hippaErrorDescription" : "La Subcuenta que Tú estás intentando Transferir está Habilitado para HIPAA y no se puede Transferir a ciegas a una agencia que no sea HIPAA.",
  "pendingTransferErrorDescription" : "Cancela las solicitudes de transferencia ya actuales para esta subcuenta antes de pasar a Elevar a Nuevo.",
  "receivingAgency" : "Recibido Agencia",
  "releasingAgency" : "Emitido Agencia",
  "noOfContacts" : "N.º de amigos",
  "requestedDate" : "Fecha solicitada",
  "action" : "Acción",
  "cancelTransfer" : "Cancelar la Transferir",
  "decline" : "Rechazar",
  "cancelTransferRequest" : "¿ Cancelar Sub-Cuenta Transferir Solicitudes?",
  "cancelEjectRequest" : "¿Cancelar la subcuenta para expulsar las solicitudes?",
  "cancelRequestDescription" : "El Recibido agencia ya no verá este Transferir solicitudes y la Subcuenta {name} no será Transferencia a ciegas.",
  "cancelEjectRequestDescription" : "El Seleccionado Usuario ya no podrá crear una nueva agencia de la subcuenta {name}.",
  "declineRequest" : "¿Rechazar Sub-Cuenta Transferir Solicitudes?",
  "declineRequestDescription" : "Las solicitudes se rechazarán y la subcuenta {name} no se transferirá a ciegas a tu agencia.",
  "approveTransfer" : "¿Aprobar la subcuenta Transferir a tu agencia?",
  "approveTransferDescription" : "{name} La subcuenta se transferirá a ciegas a tu agencia.",
  "transferNow" : "Transferir ahora",
  "cancelNow" : "Cancelar ahora",
  "transferErrorDetails" : "La Subcuenta que Tú intentas Transferir no se puede Transferir a ciegas",
  "cancelRequest" : "Cancelar solicitudes",
  "received" : "Recibido",
  "approve" : "Aprobar",
  "goBack" : "Deshacer",
  "done" : "Hecho",
  "subAccountTransferred" : "¡La Subcuenta ha sido Transferencia a ciegas!",
  "subAccountTransferDescription" : "Ten en cuenta que Uniforme, aunque la subcuenta ha sido transferencia a ciegas, algunos activos Me gusta Usuario, Número de teléfono, Mailgun Cuenta, Yext listings, etc. aún pueden tardar entre 10 y 15 minutos en reflejarse in el destino.",
  "activeSubscriptionsAlert" : "La Ubicación tiene Dinámico Suscripción. Cancelar cada Suscripción antes de la Transferencia a ciegas la Ubicación. Lista de suscripción dinámica: {subscriptions}",
  "isSaasEnabled" : "SaaS Habilitado",
  "typeToSearch" : "Escribir para buscar",
  "dateAdded" : "Fecha añadida",
  "emailEngagement" : "Interacción Correo electrónico",
  "emailReaction" : "Reacción Correo electrónico",
  "emailDeliverySummary" : "Resumen de Entrega de Correo electrónico",
  "facebook" : "Facebook",
  "sms" : "SMS",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "Resumen de la Cuenta - {subAccountName}",
  "test" : "Prueba",
  "scheduleExists" : "La programación existe",
  "aToZ" : "De la A a la Z",
  "zToA" : "Z - A",
  "onlyAdminUsersCanAddNewSubAccounts" : "Solo Administrador Usuario puede incluir nuevas subcuentas.",
  "locationName" : "Nombre de la Ubicación",
  "transferCompleted" : "La transferencia de solicitudes para esta ubicación ya se ha procesado. Actualiza.",
  "unauthorizedTransfer" : "Tú no estás autorizado para realizar esta acción. Solo los propietarios de la agencia pueden realizar esta acción.",
  "bulkLocationTransfer" : "Masivo/en bloque Transferir Sub Cuenta",
  "singleRequest" : "Solicitudes Individual",
  "bulkRequest" : "Masivo/en bloque Solicitudes",
  "backToLocationTransfer" : "Atrás a Transferir de Ubicación",
  "initiateBulkTransfer" : "Activador Masivo/en bloque Sub-Cuenta Transferir",
  "initiateBulkTransferDescription" : "Selecciona la subcuenta que deseas transferir O introduce los ID de la subcuenta que deseas transferir, separados por comas.",
  "subAccountId" : "Idde la subcuenta",
  "selectFromList" : "Seleccionar De Lista",
  "addManually" : "Añadir manualmente",
  "accountsSelected" : "{count} Seleccionado",
  "selectAllSubAccounts" : "Seleccionar cada",
  "removeAll" : "Extraer cada",
  "addManualLocations" : "Añadir Ubicación Id 's con Separado Vía coma's",
  "manualAllLocationIds" : "Ubicación IDs de la subcuenta Tú deseas Transferir, Separado Vía comas.",
  "enterLocationIds" : "Introducir la Ubicación Id 's",
  "selectSubAccounts" : "Seleccionar subcuenta y transferir a una agencia real",
  "subAccountTransferWarning" : "Cada subcuenta activos y el Usuario obtendrán Transferencia a ciegas.",
  "proceed" : "Proceder",
  "bulkRequestSubmitted" : "Masivo/en bloque Transferir Solicitudes enviadas",
  "bulkRequestSubmittedDescription" : "cada subcuenta será Transferencia a ciegas una vez que el Recibido Agencia acepte las Solicitudes de Transferir. Puedes hacer un seguimiento del estado de las solicitudes activado la ❝{pagename}❞ página. (Emitido: son elegibles)",
  "bulkTransferRequestFailed" : "Masivo/en bloque Transferir Solicitudes Error",
  "errorLocations" : "Subcuenta con error",
  "viewDetails" : "Ver detalles",
  "somethingWrong" : "Algo ha ido mal. Por favor, Vuelve a intentarlo.",
  "confirmBulkRequest" : "¿Confirmar Masivo/en bloque Transferir Solicitudes?",
  "bulkTransferAccounts" : "Masivo/en bloque Transferir '{count} ' Seleccionado Sub Cuenta ?",
  "confirmDetails" : "Confirma Detalles Antes Transferencia a ciegas",
  "transferConsent" : "He leído y comprendido la guía anterior y consiento en todas las limitaciones.",
  "transferInfo" : "La subcuenta Transferir se asunto al Recibido Agencia aceptándola en su Cuenta.",
  "receivingRelNo" : "Relación de la agencia Recibido número",
  "enterRelNo" : "Introducir número de parentesco",
  "transferConfirmation" : "Al completar esta acción, las solicitudes de transferencia masiva/en bloque se enviarán a la agencia Recibido ({ relNo }). Cada subcuenta Tú've Seleccionado será Transferencia a ciegas una vez que la agencia recibida la acepte. Ten en cuenta que esta acción es irreversible para cada subcuenta incluida in el Transferir.",
  "confirmPassword" : "Confirmar escribiendo password debajo",
  "enterPassword" : "Introducir contraseña",
  "requestTransfer" : "Solicitudes Transferir",
  "confirmTransfer" : "Confirmar la Transferir",
  "notNow" : "No ahora",
  "invalidRelNo" : "No hay una relación válida número",
  "invalidPassword" : "No hay password válida .",
  "searchSubAccounts" : "Buscar Sub-Cuenta",
  "searchSubAccountsId" : "Buscar Subcuenta Id",
  "takeAction" : "Pasa a la Acción",
  "sub_account_details" : "Subperfil",
  "no_of_contacts" : "No. de amigos",
  "transfer_status" : "Estado de la Transferir",
  "error_info" : "Información del Error",
  "bulk_transfer_status" : "Masivo/en bloque Sub-Cuenta Transferir Estado",
  "pending" : "Pendiente",
  "processed" : "Procesado",
  "reject_all" : "Rechazar cada",
  "accept_all" : "Aceptar cada",
  "cancel_all" : "Cancelar cada",
  "clearAll" : "Borrar cada",
  "refresh" : "Actualizar",
  "rejected" : "Negado",
  "decline_transfer" : "Rechazar la Transferir",
  "decline_bulk_transfer" : "Rechazar Masivo/en bloque sub-Cuenta Transferir Solicitudes ?",
  "decline_bulk_transfer_description" : "Las solicitudes se rechazarán y la Colección de {length} subcuenta no se transferirá a ciegas a tu agencia.",
  "accept_bulk_transfer" : "¿Aceptar Masivo/en bloque sub-Cuenta Transferir Solicitudes?",
  "accept_bulk_transfer_description" : "Las solicitudes se aceptarán y la Colección de {length} subcuenta se transferirá a ciegas a tu agencia.",
  "cancel_bulk_transfer" : "¿Cancelar la Transferir Masivo/en bloque ?",
  "cancel_bulk_transfer_description" : "¿Estás realmente seguro de que quieres cancelar este Masivo/en bloque Transferir de {length} Subcuenta a « {name} »",
  "consent_sub_account_transfer" : "Leer y dar su consentimiento a {link}",
  "sub_account_transfer_guidelines" : "Pautas para la subcuenta de transferencia",
  "valid_rel" : "Introduce un número de parentesco válido",
  "something_went_wrong" : "Algo ha ido mal. Por favor, Vuelve a intentarlo.",
  "export" : "Exportar",
  "export_success" : "Los datos que Tú solicitas se están procesando. Tú enviarás a Heredar un Correo electrónico con los datos in alguna Fecha.",
  "export_failed" : "No se han podido tratar los datos que tú has solicitado. Vuelve a intentarlo más tarde.",
  "selectAllError" : "Tú solo puedes seleccionar 1000 subcuentas por fecha.",
  "max1000" : "(Máx. . 1000)",
  "subAccountCount" : "Sub-Cuenta Recuento"
}
