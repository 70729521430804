import {
  AlertCircleIcon,
  CheckCircleIcon,
  DotsVerticalIcon,
  SlashCircle01Icon,
} from '@gohighlevel/ghl-icons/24/outline';
import { UIButton, UIDropdown, UITag, renderIcon } from '@gohighlevel/ghl-ui';
import { h, ref } from 'vue';
import {
  BulkTransferStatus,
  EjectStatus,
  LocationTransferTableProps,
  TransferReply,
  TransferStatus,
} from '../components/locationTransfer/Transfer.const';

import { useI18n } from 'vue-i18n';
import { useTable } from './useTable';

const {
  renderNameAndEmail,
  renderNameEmailAndLogo,
  renderDate,
  renderEject,
  renderCheckIconAndText,
} = useTable();

export function useLocationTransferTable(
  emit: any,
  tableProps: LocationTransferTableProps
) {
  const { t } = useI18n();
  const selectedRowDetails = ref<any>({
    id: '',
    action: '',
  });
  const defaultActionValues = {
    show: false,
    type: '', //transfer or eject
    icon: SlashCircle01Icon,
    heading: '',
    description: '',
    negativeButtonText: '',
    positiveButtonText: '',
    modalType: '',
    positiveButtonType: '',
    bulk: false,
  };
  const defaultBulkViewValues = {
    show: false,
    bulkDetails: {},
    type: '', //transfer or eject or bulk-transfer
    status: BulkTransferStatus.PROCESSED,
    tab: 'received', //received, sent, eject
  }
  const actionModalDetails = ref<any>(defaultActionValues);
  const bulkView = ref<any>(defaultBulkViewValues)

  function getSingleTableHeaders() {
    let tableHeaders: any[] = [];

    if (tableProps.tabName === 'received' && !tableProps.bulk) {
      tableHeaders = [
        {
          title: t('subAccountName'),
          key: 'subAccountDetails',
          render(row) {
            return renderNameAndEmail(row.subAccountDetails);
          },
        },
        {
          title: t('releasingAgency'),
          key: 'releasingCompanyDetails',
          render(row) {
            return renderNameEmailAndLogo(row.releasingCompanyDetails);
          },
        },
        {
          title: t('noOfContacts'),
          key: 'contactsCount',
        },
        {
          title: t('requestedDate'),
          key: 'createdAt',
          render(row) {
            return renderDate(row.createdAt);
          },
        },
        {
          title: t('action'),
          key: 'action',
          render(row) {
            if (row.status === TransferStatus.PENDING) {
              return h('div', { class: 'flex items-center gap-2' }, [
                h(
                  UIButton,
                  {
                    id: 'id-testing',
                    size: 'small',
                    disabled: row.status === 'pending' ? false : true,
                    type: 'error',
                    onClick: function () {
                      selectedRowDetails.value = {
                        action: TransferReply.REJECT,
                        id: row._id,
                      };
                      actionModalDetails.value = {
                        show: true,
                        type: 'transfer',
                        icon: AlertCircleIcon,
                        heading: t('declineRequest'),
                        description: t('declineRequestDescription', {
                          name: row.subAccountDetails.name,
                        }),
                        negativeButtonText: t('back'),
                        positiveButtonText: t('cancelTransfer'),
                        positiveButtonType: 'error',
                        modalType: 'error',
                      };
                    },
                  },
                  {
                    default: () => [
                      h(
                        'span',
                        { class: 'hl-text-sm-semibold ' },
                        t('decline')
                      ),
                    ],
                  }
                ),
                h(
                  UIButton,
                  {
                    id: 'accept-transfer',
                    type: 'primary',
                    size: 'small',
                    disabled: row.status === 'pending' ? false : true,
                    onClick: function () {
                      selectedRowDetails.value = {
                        action: TransferReply.ACCEPT,
                        id: row._id,
                      };
                      actionModalDetails.value = {
                        show: true,
                        type: 'transfer',
                        icon: CheckCircleIcon,
                        heading: t('approveTransfer'),
                        description: t('approveTransferDescription', {
                          name: row.subAccountDetails.name,
                        }),
                        negativeButtonText: t('back'),
                        positiveButtonText: t('transferNow'),
                        positiveButtonType: 'primary',
                        modalType: 'success',
                      };
                    },
                  },
                  {
                    default: () => [
                      h('span', { class: 'hl-text-sm-semibold' }, t('approve')),
                    ],
                  }
                ),
              ]);
            }
            let type = 'default';
            switch (row.status) {
              case TransferStatus.COMPLETED:
                type = 'success';
                break;
              case TransferStatus.CANCELED:
                type = 'warning';
                break;
              case TransferStatus.REJECTED:
                type = 'error';
                break;
              default:
                type = 'default';
            }
            return h(
              UITag,
              {
                id: 'request-status',
                type: type,
                round: true,
                bordered: false,
              },
              {
                default: () =>
                  row.status.charAt(0).toUpperCase() + row.status.slice(1),
              }
            );
          },
        },
      ];
    }

    if (tableProps.tabName === 'received' && tableProps.bulk) {
      tableHeaders = [
        {
          title: 'Sub-Accounts Count',
          key: 'subAccountIds',
          render(row) {
            return renderCheckIconAndText(row.subAccountIds.length);
          },
        },
        {
          title: t('releasingAgency'),
          key: 'releasingCompanyDetails',
          render(row) {
            return renderNameEmailAndLogo(row.releasingCompanyDetails);
          },
        },
        {
          title: t('requestedDate'),
          key: 'createdAt',
          render(row) {
            return renderDate(row.createdAt);
          },
        },
        {
          title: t('status'),
          key: 'status',
          render(row) {
            let type = 'default';
            const status = row.status
              ? row.status
              : BulkTransferStatus.PROCESSED;
            switch (status) {
              case BulkTransferStatus.PROCESSED:
                type = 'success';
                break;
              case BulkTransferStatus.CANCELED:
                type = 'error';
                break;
              case BulkTransferStatus.REJECTED:
                type = 'error';
                break;
              case BulkTransferStatus.PENDING:
                type = 'warning';
                break;
              default:
                type = 'success';
            }
            return h(
              UITag,
              {
                id: 'request-status',
                type: type,
                round: true,
                bordered: false,
              },
              {
                default: () =>
                  row.status
                    ? row.status.charAt(0).toUpperCase() + row.status.slice(1)
                    : 'Processed',
              }
            );
          },
        },
        {
          title: t('action'),
          key: 'action',
          render(row) {
            // if (row.status === BulkTransferStatus.PENDING) {
            return h('div', { class: 'flex items-center gap-2' }, [
              h(
                UIButton,
                {
                  id: 'id-testing',
                  size: 'small',
                  disabled: false,
                  type:
                    row.status === BulkTransferStatus.PENDING
                      ? 'primary'
                      : 'default',
                  onClick: function () {
                    selectedRowDetails.value = {
                      action: TransferReply.REJECT,
                      id: row._id,
                    };
                    bulkView.value = {
                      show: true,
                      bulkDetails: row,
                      type: 'transfer',
                      status: row.status || BulkTransferStatus.PROCESSED, //transfer or eject
                      tab: 'received', //received, sent, eject
                    }
                    emit('viewDetails', bulkView.value);
                  },
                },
                {
                  default: () => [
                    h(
                      'span',
                      { class: 'hl-text-sm-semibold ' },
                      row.status === BulkTransferStatus.PENDING
                        ? t('takeAction')
                        : t('viewDetails')
                    ),
                  ],
                }
              ),
            ]);
          },
        },
      ];
    }

    if (tableProps.tabName === 'sent' && !tableProps.bulk) {
      tableHeaders = [
        {
          title: t('subAccountName'),
          key: 'subAccountDetails',
          render(row) {
            return renderNameAndEmail(row.subAccountDetails);
          },
        },
        {
          title: t('receivingAgency'),
          key: 'receivingCompanyDetails',
          render(row) {
            return renderNameEmailAndLogo(row.receivingCompanyDetails)
          },
        },
        {
          title: t('noOfContacts'),
          key: 'contactsCount',
        },
        {
          title: t('requestedDate'),
          key: 'createdAt',
          render(row) {
            return renderDate(row.createdAt);
          },
        },
        {
          title: t('status'),
          key: 'status',
          render(row) {
            let type = 'default';
            switch (row.status) {
              case TransferStatus.COMPLETED:
                type = 'success';
                break;
              case TransferStatus.CANCELED:
                type = 'warning';
                break;
              case TransferStatus.REJECTED:
                type = 'error';
                break;
              default:
                type = 'default';
            }
            return h(
              UITag,
              {
                id: 'request-status',
                type: type,
                round: true,
                bordered: false,
              },
              {
                default: () =>
                  row.status.charAt(0).toUpperCase() + row.status.slice(1),
              }
            );
          },
        },
        {
          title: '',
          key: 'action-buttons',
          render(row) {
            return h(
              UIDropdown,
              {
                options: [
                  {
                    label: t('cancelRequest'),
                    key: TransferReply.CANCEL,
                    disabled:
                      row.status === TransferStatus.PENDING ? false : true,
                  },
                ],
                id: 'valueDropdown',
                onSelect: val => {
                  selectedRowDetails.value = {
                    action: val,
                    id: row._id,
                  };
                  actionModalDetails.value = {
                    show: true,
                    type: 'transfer',
                    icon: AlertCircleIcon,
                    heading: t('cancelTransferRequest'),
                    description: t('cancelRequestDescription', {
                      name: row.subAccountDetails.name,
                    }),
                    negativeButtonText: t('back'),
                    positiveButtonText: t('cancelNow'),
                    positiveButtonType: 'error',
                    modalType: 'error',
                  };
                },
              },
              [
                h(renderIcon(DotsVerticalIcon), {
                  class: '!outline-none cursor-pointer',
                  style: 'outline:none;',
                }),
              ]
            );
          },
        },
      ];
    }

    if (tableProps.tabName === 'sent' && tableProps.bulk) {
      tableHeaders = [
        {
          title: 'Sub-Accounts Count',
          key: 'subAccountIds',
          render(row) {
            return renderCheckIconAndText(row.subAccountIds.length);
          },
        },
        {
          title: t('receivingAgency'),
          key: 'receivingCompanyDetails',
          render(row) {
            return renderNameEmailAndLogo(row.receivingCompanyDetails)
          },
        },
        {
          title: t('requestedDate'),
          key: 'createdAt',
          render(row) {
            return renderDate(row.createdAt);
          },
        },
        {
          title: t('status'),
          key: 'status',
          render(row) {
            let type = 'default';
            const status = row.status
              ? row.status
              : BulkTransferStatus.PROCESSED;
            switch (status) {
              case BulkTransferStatus.PROCESSED:
                type = 'success';
                break;
              case BulkTransferStatus.CANCELED:
                type = 'error';
                break;
              case BulkTransferStatus.REJECTED:
                type = 'error';
                break;
              case BulkTransferStatus.PENDING:
                type = 'warning';
                break;
              default:
                type = 'success';
            }
            return h(
              UITag,
              {
                id: 'request-status',
                type: type,
                round: true,
                bordered: false,
              },
              {
                default: () =>
                  row.status
                    ? row.status.charAt(0).toUpperCase() + row.status.slice(1)
                    : 'Processed',
              }
            );
          },
        },
        {
          title: '',
          key: 'action-buttons',
          render(row) {
            return h(
              UIDropdown,
              {
                options: [
                  {
                    label: t('cancelRequest'),
                    key: TransferReply.CANCEL,
                    disabled:
                      row.status === BulkTransferStatus.PENDING ? false : true,
                  },
                  {
                    label: t('viewDetails'),
                    key: 'view',
                    disabled: false,
                  },
                ],
                id: 'valueDropdown',
                onSelect: val => {
                  selectedRowDetails.value = {
                    action: val,
                    id: row._id,
                  };
                  if (val === TransferReply.CANCEL) {
                    actionModalDetails.value = {
                      show: true,
                      type: 'bulk-transfer',
                      icon: AlertCircleIcon,
                      heading: t('cancel_bulk_transfer'),
                      description: t('cancel_bulk_transfer_description', {
                        length: row.subAccountIds.length,
                        name: row.receivingCompanyDetails.name,
                      }),
                      negativeButtonText: t('back'),
                      positiveButtonText: t('cancelNow'),
                      positiveButtonType: 'error',
                      modalType: 'error',
                    };
                  }
                  if (val === 'view') {
                    bulkView.value = {
                      show: true,
                      bulkDetails: row,
                      type: 'transfer',
                      status: row.status || BulkTransferStatus.PROCESSED, //transfer or eject
                      tab: 'sent',
                    }
                    emit('viewDetails', bulkView.value);
                  }
                },
              },
              [
                h(renderIcon(DotsVerticalIcon), {
                  class: '!outline-none cursor-pointer',
                  style: 'outline:none;',
                }),
              ]
            );
          },
        },
      ];
    }

    if (tableProps.tabName === 'eject' && !tableProps.bulk) {
      tableHeaders = [
        {
          title: t('subAccountName'),
          key: 'subAccountDetails',
          render(row) {
            return renderNameAndEmail(row.subAccountDetails);
          },
        },
        {
          title: t('receivingAgency'),
          key: 'receivingCompanyDetails',
          render(row) {
            return renderEject()
          },
        },

        {
          title: t('requestedDate'),
          key: 'createdAt',
          render(row) {
            return renderDate(row.createdAt);
          },
        },
        {
          title: t('status'),
          key: 'status',
          render(row) {
            let type = 'default';
            switch (row.status) {
              case EjectStatus.COMPLETED:
                type = 'success';
                break;
              case EjectStatus.PAYMENT_PENDING:
                type = 'info';
                break;
              case EjectStatus.CANCELED:
                type = 'warning';
                break;
              default:
                type = 'default';
            }
            return h(
              UITag,
              {
                id: 'request-status',
                type: type,
                round: true,
                bordered: false,
              },
              {
                default: () =>
                  row.status
                    .split('_')
                    .map(
                      status => status.charAt(0).toUpperCase() + status.slice(1)
                    )
                    .join(' '),
              }
            );
          },
        },
        {
          title: '',
          key: 'action-buttons',
          render(row) {
            return h(
              UIDropdown,
              {
                options: [
                  {
                    label: t('cancelRequest'),
                    key: 'cancel',
                    disabled: row.status === EjectStatus.PENDING ? false : true,
                  },
                ],
                id: 'valueDropdown',
                onSelect: val => {
                  selectedRowDetails.value = {
                    action: val,
                    id: row._id,
                  };
                  actionModalDetails.value = {
                    show: true,
                    type: 'eject',
                    icon: AlertCircleIcon,
                    heading: t('cancelEjectRequest'),
                    description: t('cancelEjectRequestDescription', {
                      name: row.subAccountDetails.name,
                    }),
                    negativeButtonText: t('back'),
                    positiveButtonText: t('cancelNow'),
                    positiveButtonType: 'error',
                    modalType: 'error',
                  };
                },
              },
              [
                h(renderIcon(DotsVerticalIcon), {
                  class: '!outline-none cursor-pointer',
                  style: 'outline:none;',
                }),
              ]
            );
          },
        },
      ]
    }
    return tableHeaders;
  }

  return {
    getSingleTableHeaders,
    selectedRowDetails,
    defaultActionValues,
    actionModalDetails,
    defaultBulkViewValues,
    bulkView,
  }
}
