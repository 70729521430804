{
  "common" : {
    "thisWeek" : "Deze week",
    "lastWeek" : "Vorige week",
    "lastNumberOfDays" : "last {numberOfDays} Days",
    "thisMonth" : "Deze maand"
  },
  "subAccounts" : "Sub-account",
  "submitFeedback" : "Feedback verzenden",
  "createSubAccount" : "Sub-AccountCreate",
  "viewScheduleReports" : "View Scheduled Reports",
  "searchSubAccount" : "Search Via subAccount",
  "columns" : "Kolommen",
  "sortBy" : "Sorteren op",
  "selectColumns" : "Kolommen Select",
  "maxColumns" : "Max 8 kolommen op een Date",
  "applyColumns" : "Apply",
  "activeUsers" : "Actieve gebruikers",
  "appointments" : "Afspraken",
  "contacts" : "Contactpersonen",
  "emails" : "Gmail",
  "textMessages" : "Body berichten",
  "calls" : "Oproepen",
  "submissions" : "Inzendingen",
  "reviews" : "Recensies",
  "sitesCreated" : "Created Site",
  "name" : "Naam",
  "formSubmissions" : "Form ingediend",
  "surveySubmissions" : "Ingediende Survey",
  "facebookFormSubmissions" : "Facebook-formulier indienen",
  "funnels" : "Trechters",
  "websites" : "Websites",
  "forms" : "Formulieren",
  "surveys" : "Enquêtes",
  "highestFirst" : "Max eerst",
  "lowestFirst" : "Laagste eerst",
  "orderBy" : "Via Sort",
  "noMoreLocations" : "Geen Address meer found",
  "preparingReport" : "Your report Wij zijn Aan het Loading",
  "preparingReportDesc" : "Dit Window niet Close of opnieuw laden tijdens in Export",
  "outOf" : "From",
  "subAccountsExported" : "Sub-account geëxporteerd",
  "close" : "Afsluiten",
  "scheduledForDeletion" : "Scheduled voor verwijderen",
  "readyToSell" : "Klaar om te verkopen",
  "closedWon" : "Gesloten (gewonnen)",
  "wpSaleSuccessful" : "Verkoop WordPress is succesvol",
  "wpNotSold" : "WordPress nog niet verkocht",
  "yxtSaleSuccessful" : "Verkoop Yext is succesvol",
  "yxtNotSold" : "Yext nog niet verkocht",
  "saasSubInactive" : "SaaS Subscription is Inactive",
  "saasSubTrialing" : "SaaS Subscription wordt uitgeprobeerd",
  "saasSubActive" : "SaaS Subscription is Dynamic",
  "emailRebillingActive" : "Email Rebilling is Dynamic",
  "emailRebillingInactive" : "Email Rebilling is Inactive",
  "twilioRebillingActive" : "Twilio Rebilling is Dynamic",
  "twilioRebillingInactive" : "Twilio Rebilling is Inactive",
  "wpRebillingActive" : "WhatsApp Rebilling is Dynamic",
  "wpRebillingInactive" : "WhatsApp Rebilling is Inactive",
  "saasModeActivated" : "SaaS modus is Enabled",
  "paymentPending" : "Betaling is in afwachting",
  "saasModeNotActivated" : "SaaS modus is niet Enabled",
  "managedByLCPhone" : "Manage Via LC Phone",
  "migrationToLCPhone" : "Onder migratie naar LC Phone",
  "notManagedByLCPhone" : "Not Manage Via LC Phone",
  "managedByLCEmail" : "Manage Via LeadConnector Email System",
  "notMangedByLCEmail" : "Not Manage Via LeadConnector Email System",
  "remainingWalletBalance" : "Resterende Wallet Balance of this SaaS Location",
  "reports" : "Rapporten",
  "switchToSubAccount" : "Overschakelen naar sub-Account",
  "manageClient" : "Manage Client",
  "subAccountDeletedIfChangedMind" : "Deze sub-Account wordt Deleted On {time}, If You Dynamic your Mind",
  "subAccountDeletedInMinute" : "Deze Sub-Account wordt Deleted binnen de Next minute",
  "changedMind" : "If je je geest Dynamic hebt gemaakt",
  "clickHere" : "click here",
  "subAccountPaused" : "DezeAccount is Dashed. Je Customers kunnen niet Login , Agency wel. Dit Account Resume",
  "subAccountRestricted" : "Deze sub -Account is Dashed aan lopende Approval.",
  "subAccountVerificationPending" : "Deze sub-Account is Dashed Owed aan lopende Email en Phone verificatie From Sub-Account Admin.",
  "subAccountDeclined" : "Deze sub -Account is Dashed aan afgewezen Approval . You kunt dit sub-Account From nog steeds Resume ",
  "here" : "hier.",
  "reviewAndResume" : "aan Review en Resume dit sub-Account.",
  "incomingCalls" : "Inkomende gesprekken",
  "outgoingCalls" : "Uitgaande gesprekken",
  "totalTalktime" : "Total gesprektijd",
  "incomingEmails" : "Inkomende Gmail",
  "outgoingEmails" : "Uitgaande Gmail",
  "incomingSms" : "Inkomende Body berichten",
  "outgoingSms" : "Uitgaande Body berichten",
  "contactsCreated" : "number of",
  "appointmentsBooked" : "number of Appointment",
  "totalFormSubmissions" : "Total Form",
  "totalSurveySubmissions" : "Total ingediende Survey",
  "totalFacebookFormSubmissions" : "FacebookTotal -formulier voor aanmeldingen",
  "incomingGMBMessages" : "Inkomende berichten in GBP",
  "outgoingGMBMessages" : "Uitgaande berichten in GBP",
  "gmbMessages" : "GBP Berichten",
  "fbMessages" : "Facebook-berichten",
  "incomingFbMessages" : "Inkomende Facebook-berichten",
  "outgoingFbMessages" : "Uitgaande Facebook-berichten",
  "igMessages" : "Instagram-berichten",
  "incomingIgMessages" : "Inkomende Instagram-berichten",
  "outgoingIgMessages" : "Uitgaande Instagram-berichten",
  "totalReviews" : "Totaal aantal beoordelingen",
  "positiveReviews" : "Positieve beoordelingen",
  "negativeReviews" : "Negatieve beoordelingen",
  "back" : "Terug",
  "selectDashboards" : "Dashboards Select",
  "next" : "Volgende",
  "backToSubAccounts" : "Back naar sub-account",
  "pauseSchedule" : "Pause schedule",
  "resumeSchedule" : "schedule Resume",
  "editSchedule" : "Edit schedule",
  "addSchedule" : "schedule Add",
  "selectReportToAdd" : "Select rapporteert aan Add in de Email",
  "selectAll" : "Select every",
  "pauseScheduleConfirm" : "Weet je zeker dat je de schedule wilt Pause ?",
  "resumeScheduleConfirm" : "Weet je zeker dat je de schedule wilt Resume ?",
  "tasks" : "Taken",
  "conversations" : "Communication",
  "users" : "Operator",
  "dealOverview" : "Overview Deal",
  "confirmAction" : "Actie bevestigen",
  "yes" : "Ja",
  "no" : "Nee",
  "reportDetails" : "Details",
  "reportCreatedOn" : "Created On melden",
  "searchByEmail" : "Search Via Email",
  "lastActivity" : "last Activity",
  "noRecords" : "Geen Records gevonden",
  "scheduleReport" : "schedule Rapport",
  "schedule" : "schedule",
  "save" : "Opslaan",
  "emailSubject" : "Subject Line",
  "frequency" : "Frequentie",
  "daily" : "Dagelijks",
  "weekly" : "Wekelijks",
  "monthly" : "Maandelijks",
  "nextScheduleDate" : "Next schedule Date",
  "startingOn" : "Starten On",
  "timeOfDay" : "Date of Day",
  "reportsToTimezone" : "Rapport wordt sent in de tijdzone van het sub-Account",
  "selectRecipients" : "Ontvangers Select",
  "invalidEmail" : "You hebt een Invalid Email address ingevoerd",
  "testEmail" : "Test Email",
  "selectTestRecipients" : "Ontvangers van Test Select",
  "setupSubject" : "Configure het Subject hierboven om het rapport te Test",
  "sendTest" : "Upload Test",
  "scheduleSimilarSchedules" : "schedule soortgelijk rapport voor Other Sub-Account",
  "accountSummary" : "Account Summary",
  "scheduleList" : "schedule List",
  "subAccountName" : "Sub-Account",
  "nextRunDate" : "Date Next uitvoering",
  "reportSectionsAdded" : "Toegevoegde Segments",
  "status" : "Status",
  "scheduleAction" : "schedule Action",
  "cancel" : "Annuleren",
  "pleaseWait" : "Wait",
  "confirm" : "Bevestigen",
  "booked" : "Geboekt",
  "confirmed" : "Bevestigd",
  "showed" : "Showed",
  "noShow" : "No-show",
  "cancelled" : "Geannuleerd",
  "unread" : "Ongelezen",
  "engagement" : "Betrokkenheid",
  "reaction" : "Reactie",
  "deliverySummary" : "Delivery",
  "successfulDelivery" : "Succesvolle Delivery",
  "opened" : "Geopend",
  "clicked" : "Geklikt",
  "replied" : "Geantwoord",
  "unsubscribed" : "Afgemeld",
  "complained" : "Geklaagd",
  "failed" : "Mislukt",
  "open" : "Open",
  "won" : "Gewonnen",
  "abandoned" : "Verlaten",
  "lost" : "Verloren",
  "sent" : "Verzonden",
  "eject" : "Uitwerpen",
  "delivered" : "Afgeleverd",
  "faceBookLeadForm" : "Facebook Lead Form",
  "overdue" : "Achterstallig",
  "reviewsReceived" : "Ontvangen beoordelingen",
  "formBuilders" : "Form Builders",
  "switchToSaaS" : "Schakel over naar SaaS",
  "numberOfContacts" : "number of Friends",
  "numberOfAppointments" : "number of Appointment",
  "outreach" : "Outreach",
  "notSubAccountUser" : "Geen Operator van subAccount",
  "searchSubAccName" : "Search Via naam sub-Account",
  "recipients" : "Ontvangers",
  "scheduleCreated" : "Email schedule Created voor ",
  "scheduleUpdated" : "Email Report schedule Updated voor ",
  "scheduleNotCreated" : "Email Rapport schedule kon niet worden Created voor",
  "scheduleNotUpdated" : "Email schedule kan niet worden Updated voor",
  "total" : "Totaal",
  "inbound" : "Inkomend",
  "outbound" : "Uitgaande",
  "totalEmails" : "GmailTotal",
  "inboundEmails" : "Inkomende Gmail",
  "outboundEmails" : "GmailOutbound",
  "totalTextMessages" : "Total Body Messages",
  "inboundTextMessages" : "Inkomende Body berichten",
  "outboundTextMessages" : "Outbound Body Messages",
  "totalCalls" : "Totaal aantal oproepen",
  "inboundCalls" : "Inkomende gesprekken",
  "outboundCalls" : "Uitgaande gesprekken",
  "faceBookLeadFormSubmissions" : "Inzendingen Facebook Form",
  "inboundGMBMessages" : "Inkomende berichten in GBP",
  "outboundGMBMessages" : "Uitgaande berichten in GBP",
  "totalIgMessages" : "Totaal aantal Instagram-berichten",
  "inboundIgMessages" : "Inkomende Instagram-berichten",
  "outboundIgMessages" : "Uitgaande Instagram-berichten",
  "totalfbMessages" : "Totaal aantal Facebook-berichten",
  "inboundfbMessages" : "Inkomende Facebook-berichten",
  "outboundfbMessages" : "Uitgaande Facebook-berichten",
  "sitesCreatedFunnels" : "Sites Created - Funnels",
  "sitesCreatedActiveFunnels" : "Sites Created - Dynamic Funnels",
  "sitesCreatedWebsites" : "Created websites - Websites",
  "sitesCreatedActiveWebsites" : "Sites Created - Dynamic Websites",
  "sitesCreatedForms" : "Created Sites - Formulieren",
  "sitesCreatedActiveForms" : "Sites Created - Dynamic Forms",
  "sitesCreatedSurveys" : "Created websites - Enquêtes",
  "sitesCreatedActiveSurveys" : "Sites Created - Dynamic enquêtes",
  "googleReviews" : "Google Reviews",
  "facebookReviews" : "Facebook-reviews",
  "inboundCallDuration" : "Inkomende oproep Duration (hour)",
  "outboundCallDuration" : "Outbound Call Duration (hour)",
  "pleaseSelect" : "Select",
  "subAccountIsDeleted" : "Deze sub-Account is Deleted",
  "subAccountIsPaused" : "DezeAccount is Dashed",
  "success" : "Gelukt",
  "error" : "Fout",
  "testEmailSent" : "Test Email sent",
  "testEmailNotSent" : "Test Email kon niet worden sent",
  "reviewRequest" : "Review Requests",
  "pendingTransferDescription" : "Other Agency View hebben verzocht om Transfer Sub-Account to You.",
  "pendingTransferHeading" : "You hebt lopende Sub-Account Transfer Requests.",
  "subAccountTransfer" : "SubAccount Download",
  "manageSubAccTransfer" : "Manage uw Sub-Account Transfer Requests",
  "subAccRequest" : "Sub-Account {tabName} Requests",
  "receive" : "Inherit",
  "send" : "Verzenden",
  "release" : "Version",
  "requests" : "{count} Verzoeken",
  "subAccLimit" : "Gebruik van sub-account SLOTMAX bereikt!",
  "subAccPlanLimit" : "You hebt de 3/3 sub-account gebruikt die is toegestaan onder uw Current Plan.",
  "upgradeTime" : "Het is Date voor een Upgrade!",
  "upgradeHigher" : "Upgrade naar een hoger Plan om de Sub-Account Transfer te accepteren.",
  "browse" : "Bladeren door plannen",
  "pendingTransferTitle" : "Een Transfer voor deze Sub -Account is al in behandeling.",
  "unableTransfer" : "Kan sub-Account niet Transfer naar dit Agency.",
  "unableEjectCancel" : "Kan subAccount Requests niet Cancel",
  "EjectErrorDetails" : "De sub-Account eject Requests die You wilt Cancel, kan niet worden geannuleerd.",
  "hippaErrorDescription" : "De sub-Account die You probeert Transfer te brengen, is HIPAA Enabled en kan niet worden Download naar een niet-HIPAA Agency.",
  "pendingTransferErrorDescription" : "Vriendelijk verzoek Before de new Current Transfer Requests voor dit subAccount Elevate Cancel .",
  "receivingAgency" : "Received Agency",
  "releasingAgency" : "Issued Agency",
  "noOfContacts" : "of",
  "requestedDate" : "Verzochte Date",
  "action" : "Actie",
  "cancelTransfer" : "Transfer Cancel",
  "decline" : "Deny",
  "cancelTransferRequest" : "Sub-Account Transfer Requests Cancel ?",
  "cancelEjectRequest" : "SubAccount eject Cancel Requests?",
  "cancelRequestDescription" : "Het Received Agency ziet deze Transfer Requests niet meer en de Sub-Account {name} is geen Download.",
  "cancelEjectRequestDescription" : "De Selected Operator zal niet langer in staat zijn om een subAccount {name}{Agency} From Create .",
  "declineRequest" : "DenyAccount Transfer Requests?",
  "declineRequestDescription" : "Het Requests wordt afgewezen en het sub-Account {name} wordt niet Download naar uw Agency.",
  "approveTransfer" : "Sub-Account Approve naar uw Agency ?",
  "approveTransferDescription" : "{name} Het sub-Account wordt Download naar uw Agency.",
  "transferNow" : "Transfer now",
  "cancelNow" : "now Cancel",
  "transferErrorDetails" : "Het sub-Account dat You probeert over te Transfer, kan niet worden Download",
  "cancelRequest" : "Requests Cancel",
  "received" : "Ontvangen",
  "approve" : "Goedkeuren",
  "goBack" : "Ongedaan maken",
  "done" : "Voltooid",
  "subAccountTransferred" : "Het sub-Account is nu Download!",
  "subAccountTransferDescription" : "Houd er Note dat, hoewel het sub-Account is Download , het Even zijn dat het voor sommige items van Like Operator, Phone Number, Mailgun Account, Yext enz. nog steeds 10 tot 15 minuten duurt om in de bestemming weer te geven.",
  "activeSubscriptionsAlert" : "De Location heeft een Dynamic Subscription. Cancel every Subscription Voor Blind Transfer the Location. of Subscription List {subscriptions} {Dynamic}",
  "isSaasEnabled" : "SaaS Enabled",
  "typeToSearch" : "Typ om te zoeken",
  "dateAdded" : "Datum toegevoegd",
  "emailEngagement" : "Email mailbetrokkenheid",
  "emailReaction" : "Email",
  "emailDeliverySummary" : "Email Delivery Summary",
  "facebook" : "Facebook",
  "sms" : "Sms",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "Account - {Summary}{subAccountName}",
  "test" : "Test",
  "scheduleExists" : "schedule bestaat",
  "aToZ" : "A - Zo",
  "zToA" : "Z - A",
  "onlyAdminUsersCanAddNewSubAccounts" : "Alleen Admin Operator kan new -accounts Add .",
  "locationName" : "Naam Location",
  "transferCompleted" : "Transfer voor deze Location is al verwerkt . Vernieuw deze.",
  "unauthorizedTransfer" : "Je bent niet geautoriseerd om deze Action uit te voeren. Alleen Agency kunnen deze Action uitvoeren.",
  "bulkLocationTransfer" : "Sub- Account in Bulk Transfer",
  "singleRequest" : "Individual Requests",
  "bulkRequest" : "Bulk Requests",
  "backToLocationTransfer" : "Back naar Location Transfer",
  "initiateBulkTransfer" : "trigger Bulk Sub-account Transfer",
  "initiateBulkTransferDescription" : "Select de sub-account die You wilt Transfer of Voer de ID's in of de sub-account die You wilt Transfer, Separated Via komma's.",
  "subAccountId" : "Sub-Account Id",
  "selectFromList" : "From Select",
  "addManually" : "Handmatig Add",
  "accountsSelected" : "{count} Geselecteerd",
  "selectAllSubAccounts" : "Select every",
  "removeAll" : "Extract",
  "addManualLocations" : "Add Location Id 's with Separated Via komma's",
  "manualAllLocationIds" : "Location ID's of de sub-account You wish to Transfer, Separated Via komma's.",
  "enterLocationIds" : "Voer Location - Id 's in",
  "selectSubAccounts" : "Sub-account Select en Transfer naar een Current Agency",
  "subAccountTransferWarning" : "every sub-accountactiva en Operator krijgen Download.",
  "proceed" : "Doorgaan",
  "bulkRequestSubmitted" : "Bulk Transfer Requests ingediend",
  "bulkRequestSubmittedDescription" : "every sub-account wordt Download zodra het Received Agency de Transfer Requests geaccepteerd. You kunt de Status of het Requests On de ❝{pagename}❞ Page volgen. (Issued ze komen in aanmerking)",
  "bulkTransferRequestFailed" : "Bulk Transfer Requests",
  "errorLocations" : "Sub Account met fout",
  "viewDetails" : "Bekijk details",
  "somethingWrong" : "Er is iets misgegaan. Try again.",
  "confirmBulkRequest" : "Bulk Transfer Requests?",
  "bulkTransferAccounts" : "Bulk {count}Selected ?",
  "confirmDetails" : "Before Details",
  "transferConsent" : "Ik heb Read en begrepen bovenstaande handleiding en Consent op every van de beperkingen.",
  "transferInfo" : "Sub Account Transfer is een Subject voor het Received om deze op hun Account te accepteren.",
  "receivingRelNo" : "number Received",
  "enterRelNo" : "Voer number relatie in",
  "transferConfirmation" : "Na het voltooien van deze Action worden de Bulk Transfer Requests sent naar het Received Agency ({ relNo }). every sub-Account You Selected wordt Download zodra het Received Agency deze heeft geaccepteerd. Note : deze Action is onomkeerbaar voor every sub-account die is opgenomen in de Transfer.",
  "confirmPassword" : "Bevestigen Via het password in te typen",
  "enterPassword" : "Voer wachtwoord in",
  "requestTransfer" : "Requests Transfer",
  "confirmTransfer" : "Transfer bevestigen",
  "notNow" : "Niet nu",
  "invalidRelNo" : "Invalid number",
  "invalidPassword" : "Invalid password.",
  "searchSubAccounts" : "Search op subaccount",
  "searchSubAccountsId" : "Search Sub-Account Id",
  "takeAction" : "Actie ondernemen",
  "sub_account_details" : "Profile",
  "no_of_contacts" : "Friends of",
  "transfer_status" : "Transfer Status",
  "error_info" : "Info",
  "bulk_transfer_status" : "Bulk Transfer sub-account in Status",
  "pending" : "In afwachting",
  "processed" : "Verwerkt",
  "reject_all" : "Deny",
  "accept_all" : "every accepteren",
  "cancel_all" : "every Cancel",
  "clearAll" : "every",
  "refresh" : "Vernieuwen",
  "rejected" : "Denied",
  "decline_transfer" : "Deny Transfer",
  "decline_bulk_transfer" : "Deny - Account Transfer Requests ?",
  "decline_bulk_transfer_description" : "De Requests worden afgewezen en het sub-account voor het Collection of {length} wordt niet Download naar je Agency.",
  "accept_bulk_transfer" : "Bulk accepteren sub-account Transfer Requests ?",
  "accept_bulk_transfer_description" : "Het Requests wordt Accepted en het sub-account of {length} wordt Download naar uw Agency.",
  "cancel_bulk_transfer" : "Bulk Transfer Cancel ?",
  "cancel_bulk_transfer_description" : "Weet You echt zeker dat You deze {length} -account in Bulk wilt Cancel of “{name}”",
  "consent_sub_account_transfer" : "Read en Consent naar {link}",
  "sub_account_transfer_guidelines" : "Richtlijnen voor sub-Account",
  "valid_rel" : "Voer een geldig number in",
  "something_went_wrong" : "Er is iets misgegaan. Try again.",
  "export" : "Exporteren",
  "export_success" : "De gegevens waar You om hebt gevraagd, worden verwerkt. You Inherit een Email met de gegevens in een Date.",
  "export_failed" : "De gevraagde gegevens You kunnen niet worden verwerkt. Try again .",
  "selectAllError" : "You kunt alleen een Sub- Account Select op 1000 op een Date.",
  "max1000" : "(Max 1000)",
  "subAccountCount" : "Sub-account Count"
}
