{
  "common" : {
    "thisWeek" : "Diese Woche",
    "lastWeek" : "Letzte Woche",
    "lastNumberOfDays" : "letzte {numberOfDays} Tage",
    "thisMonth" : "Dieser Monat"
  },
  "subAccounts" : "Unterkonto",
  "submitFeedback" : "Feedback einreichen",
  "createSubAccount" : "Unterkonto erstellen",
  "viewScheduleReports" : "Geplant Reports Ansicht",
  "searchSubAccount" : "Suchen Über Unterkonto",
  "columns" : "Spalte",
  "sortBy" : "Sortieren nach",
  "selectColumns" : "Spalten Auswählen",
  "maxColumns" : "Max.. 8 Spalten an einem Datum",
  "applyColumns" : "Spalten Anwenden",
  "activeUsers" : "Aktive Nutzer",
  "appointments" : "Termine",
  "contacts" : "Kontakte",
  "emails" : "Gmail",
  "textMessages" : "Textkörper-Nachricht",
  "calls" : "Anrufe",
  "submissions" : "Einreichungen",
  "reviews" : "Bewertungen",
  "sitesCreated" : "Websites Erstellt",
  "name" : "Name",
  "formSubmissions" : "Formular",
  "surveySubmissions" : "Umfrage",
  "facebookFormSubmissions" : "Facebook-Formular",
  "funnels" : "Trichter",
  "websites" : "Websites",
  "forms" : "Formulare",
  "surveys" : "Umfragen",
  "highestFirst" : "Max.. erste",
  "lowestFirst" : "Niedrigste zuerst",
  "orderBy" : "Sortieren Via",
  "noMoreLocations" : "Keine Adresse mehr gefunden",
  "preparingReport" : "Wir sind Ihren Bericht geladen",
  "preparingReportDesc" : "Schließen Sie dieses Fenster nicht oder laden Sie es neu, während das Exportieren in",
  "outOf" : "Von",
  "subAccountsExported" : "Unterkonto exportiert",
  "close" : "Schließen",
  "scheduledForDeletion" : "Zum Löschen Geplant",
  "readyToSell" : "Bereit zum Verkauf",
  "closedWon" : "Geschlossen (Gewinnen)",
  "wpSaleSuccessful" : "WordPress Ververkauf war erfolgreich",
  "wpNotSold" : "WordPress noch nicht verkaufen",
  "yxtSaleSuccessful" : "Yext-Ververkauf war erfolgreich",
  "yxtNotSold" : "Yext noch nicht verkaufen",
  "saasSubInactive" : "SaaS Abonnement ist Inaktiv",
  "saasSubTrialing" : "Das SaaS-Abonnement befindet sich in der Testphase",
  "saasSubActive" : "SaaS Abonnement ist Dynamisch",
  "emailRebillingActive" : "E-Mail Neuberechnung ist Dynamisch",
  "emailRebillingInactive" : "E-Mail Neuberechnung ist Inaktiv",
  "twilioRebillingActive" : "Twilio Neuberechnung ist Dynamisch",
  "twilioRebillingInactive" : "Twilio Neuberechnung ist Inaktiv",
  "wpRebillingActive" : "WhatsApp Neuberechnung ist Dynamisch",
  "wpRebillingInactive" : "WhatsApp Neuberechnung ist Inaktiv",
  "saasModeActivated" : "SaaS -Modus ist Aktiviert",
  "paymentPending" : "Die Bezahlung ist ausstehend",
  "saasModeNotActivated" : "SaaS Modus ist nicht aktiviert",
  "managedByLCPhone" : "Über SM-Telefon verwalten",
  "migrationToLCPhone" : "Im Zuge der Umstellung auf SM- Telefon",
  "notManagedByLCPhone" : "Nicht verwalten über SM-Telefon",
  "managedByLCEmail" : "Über LeadConnector Email Systemverwalten",
  "notMangedByLCEmail" : "Nicht über LeadConnector Email Systemverwalten",
  "remainingWalletBalance" : "Veraltetes Wallet Balance von diesem SaaS -standort",
  "reports" : "Reports",
  "switchToSubAccount" : "Wechsle zum Unterkonto",
  "manageClient" : "Kunde Verwalten",
  "subAccountDeletedIfChangedMind" : "Dieses Unterkonto wird gelöscht An {time}, Falls Sie Ihren Kopf dynamischer haben",
  "subAccountDeletedInMinute" : "Dieses Unterkonto wird innerhalb der nächsten Minute gelöscht",
  "changedMind" : "Wenn Sie Ihren Kopf synchronisieren",
  "clickHere" : "Klicken Sie hier",
  "subAccountPaused" : "Dieses Unterkonto ist pausiert. Ihr Kunde kann sich nicht Login, aber die Agentur kann. Um dieses Konto wieder aufzunehmen",
  "subAccountRestricted" : "Dieses Unterkonto ist pausiert Geschuldet zur ausstehenden Genehmigung.",
  "subAccountVerificationPending" : "Dieses Unterkonto ist pausiert Geschuldet an ausstehende E-Mail- und Telefonverifizierung Von Sub-Konto Admin.",
  "subAccountDeclined" : "Dieses Unterkonto ist pausiert Geschuldet, um abgelehnte Genehmigungsanfragen zu akzeptieren. Sie können dieses Unterkonto Von weiterhin wieder aufnehmen ",
  "here" : "hier.",
  "reviewAndResume" : "zur Bewertung und Wiederaufnahme dieses Unterkontos.",
  "incomingCalls" : "Eingehende Anrufe",
  "outgoingCalls" : "Ausgehende Anrufe",
  "totalTalktime" : "Gesamt Gesprächszeit",
  "incomingEmails" : "Eingehende Gmail",
  "outgoingEmails" : "Ausgehende Gmail",
  "incomingSms" : "Eingehende Textkörper-Nachricht",
  "outgoingSms" : "Ausgehende Textkörper-Nachricht",
  "contactsCreated" : "Nummer von Freunde erstellt",
  "appointmentsBooked" : "Nummer von Termin gebucht",
  "totalFormSubmissions" : "Gesamt Formulareinsendungen",
  "totalSurveySubmissions" : "Gesamte Umfrage-Einsendungen",
  "totalFacebookFormSubmissions" : "Gesamte Facebook-Formular-Einsendungen",
  "incomingGMBMessages" : "Eingehende GBP-Benachrichtigungen",
  "outgoingGMBMessages" : "Ausgehende GBP-Benachrichtigungen",
  "gmbMessages" : "GBP-Benachrichtigungen",
  "fbMessages" : "Facebook-Benachrichtigungen",
  "incomingFbMessages" : "Eingehende Facebook-Nachricht",
  "outgoingFbMessages" : "Ausgehende Facebook-Nachricht",
  "igMessages" : "Instagram Nachrichten",
  "incomingIgMessages" : "Eingehende Instagram-Nachricht",
  "outgoingIgMessages" : "Ausgehende Instagram-Benachrichtigungen",
  "totalReviews" : "Gesamt der Bewertungen",
  "positiveReviews" : "Positiv Bewertungen",
  "negativeReviews" : "Negativ Bewertungen",
  "back" : "Zurück",
  "selectDashboards" : "Dashboards Auswählen",
  "next" : "Nächste",
  "backToSubAccounts" : "Zurück zum Unterkonto",
  "pauseSchedule" : "Zeitplan Pause",
  "resumeSchedule" : "Zeitplan für die Wiederaufnahme",
  "editSchedule" : "Zeitplan Bearbeiten",
  "addSchedule" : "Zeitplan Hinzufügen",
  "selectReportToAdd" : "Auswählen Reports zum Einbeziehen in der E-Mail",
  "selectAll" : "Auswählen jeder",
  "pauseScheduleConfirm" : "Möchten Sie den Bericht Zeitplan wirklich Pause ?",
  "resumeScheduleConfirm" : "Möchten Sie den Zeitplan wirklich wieder aufnehmen?",
  "tasks" : "Aufgaben",
  "conversations" : "Konversationen",
  "users" : "Benutzer",
  "dealOverview" : "Vertrag Übersicht",
  "confirmAction" : "Aktion bestätigen",
  "yes" : "Ja",
  "no" : "Nein",
  "reportDetails" : "Details",
  "reportCreatedOn" : "Bericht Erstellt An",
  "searchByEmail" : "Suchen Sie Via E-Mail",
  "lastActivity" : "letzte Aktivität",
  "noRecords" : "Keine Aufzeichnungen gefunden",
  "scheduleReport" : "Bericht zum Zeitplan",
  "schedule" : "Zeitplan",
  "save" : "Speichern",
  "emailSubject" : "Betreffzeile",
  "frequency" : "Frequenz",
  "daily" : "Täglich",
  "weekly" : "Wöchentlich",
  "monthly" : "Monatlich",
  "nextScheduleDate" : "Nächste Zeitplan Datum",
  "startingOn" : "Starten von An",
  "timeOfDay" : "Datum von Tag",
  "reportsToTimezone" : "Der Bericht wird in der Zeitzone des Unterkontos gesendet",
  "selectRecipients" : "Empfänger Auswählen",
  "invalidEmail" : "Sie haben eine Ungültige E-Mail-Adresse eingegeben",
  "testEmail" : "Test E-Mail",
  "selectTestRecipients" : "Auswählen Test",
  "setupSubject" : "Konfigurieren Sie den Betreff oben, um den Bericht zu Test",
  "sendTest" : "Test Upload",
  "scheduleSimilarSchedules" : "Zeitplan passender Bericht für Andere Sub-Konto",
  "accountSummary" : "Konto Zusammenfassung",
  "scheduleList" : "Zeitplan Liste",
  "subAccountName" : "Name des Unterkontos",
  "nextRunDate" : "Datum der Nächste Ausführung",
  "reportSectionsAdded" : "Berichtssegment hinzugefügt",
  "status" : "Status",
  "scheduleAction" : "Aktion Zeitplan",
  "cancel" : "Stornieren",
  "pleaseWait" : "Bitte Warten",
  "confirm" : "Bestätigen",
  "booked" : "Gebucht",
  "confirmed" : "Bestätigt",
  "showed" : "Erschienen",
  "noShow" : "Nichterscheinen",
  "cancelled" : "Abgesagt",
  "unread" : "Ungelesen",
  "engagement" : "Engagement",
  "reaction" : "Antwort",
  "deliverySummary" : "Zusammenfassung der Lieferung",
  "successfulDelivery" : "Erfolgreich Lieferung",
  "opened" : "Geöffnet",
  "clicked" : "Geklickt",
  "replied" : "Beantwortet",
  "unsubscribed" : "Abmeldung",
  "complained" : "Beschwert",
  "failed" : "Fehlgeschlagen",
  "open" : "Öffnen",
  "won" : "Gewonnen",
  "abandoned" : "Abgebrochen",
  "lost" : "Verlust",
  "sent" : "Gesendet",
  "eject" : "Auswerfen",
  "delivered" : "Zugestellt",
  "faceBookLeadForm" : "Facebook- Lead Formular",
  "overdue" : "Überfällig",
  "reviewsReceived" : "Bewertungen Empfangen",
  "formBuilders" : "Formular Builder",
  "switchToSaaS" : "Wechsle zu SaaS",
  "numberOfContacts" : "Nummer von Freunde",
  "numberOfAppointments" : "Nummer von Termin",
  "outreach" : "Reichweite",
  "notSubAccountUser" : "Kein Subkonto-Benutzer",
  "searchSubAccName" : "Suchen Sie über den Namen des Unterkontos",
  "recipients" : "Empfänger",
  "scheduleCreated" : "E-Mail Report Zeitplan Erstellt für ",
  "scheduleUpdated" : "E-Mail Report Zeitplan aktualisiert für ",
  "scheduleNotCreated" : "E-Mail-Bericht Der Zeitplan konnte nicht erstellt werden",
  "scheduleNotUpdated" : "E-Mail-Bericht Der Zeitplan konnte nicht aktualisiert werden",
  "total" : "Gesamt",
  "inbound" : "Eingehende",
  "outbound" : "Outbound",
  "totalEmails" : "Gesamt Gmail",
  "inboundEmails" : "Eingehende Gmail",
  "outboundEmails" : "Outbound Gmail",
  "totalTextMessages" : "Gesamt Textkörper Nachrichten",
  "inboundTextMessages" : "Eingehende Textkörper-Nachricht",
  "outboundTextMessages" : "Outbound Textkörper-Nachricht",
  "totalCalls" : "Anrufe Gesamt",
  "inboundCalls" : "Eingehende Anrufe",
  "outboundCalls" : "Outbound Anrufe",
  "faceBookLeadFormSubmissions" : "Einreichungen mit Facebook- Lead Formular",
  "inboundGMBMessages" : "Eingehende GBP-Benachrichtigungen",
  "outboundGMBMessages" : "Outbound GBP-Benachrichtigungen",
  "totalIgMessages" : "Gesamt der Instagram-Benachrichtigungen",
  "inboundIgMessages" : "Eingehende Instagram-Benachrichtigungen",
  "outboundIgMessages" : "Outbound Instagram-Benachrichtigungen",
  "totalfbMessages" : "Gesamt der Facebook-Benachrichtigungen",
  "inboundfbMessages" : "Eingehende Facebook-Benachrichtigungen",
  "outboundfbMessages" : "Outbound Facebook-Benachrichtigungen",
  "sitesCreatedFunnels" : "Erstellt Sites – Trichter",
  "sitesCreatedActiveFunnels" : "Sites erstellt - Dynamisch Trichter",
  "sitesCreatedWebsites" : "Erstellt Websites – Websites",
  "sitesCreatedActiveWebsites" : "Websites erstellt – Dynamische Websites",
  "sitesCreatedForms" : "Erstellt Sites – Formulare",
  "sitesCreatedActiveForms" : "Sites erstellt – Dynamische Formulare",
  "sitesCreatedSurveys" : "Erstellt Websites – Umfragen",
  "sitesCreatedActiveSurveys" : "Sites erstellt – Dynamische Umfragen",
  "googleReviews" : "Google Bewertungen",
  "facebookReviews" : "Facebook-bewertungen",
  "inboundCallDuration" : "Eingehende Anruf (stunde)",
  "outboundCallDuration" : "Dauer Outbound Anruf (Stunden)",
  "pleaseSelect" : "Bitte Auswählen",
  "subAccountIsDeleted" : "Dieses Unterkonto wurde gelöscht",
  "subAccountIsPaused" : "Dieses Unterkonto wurde pausiert",
  "success" : "Erfolg",
  "error" : "Fehler",
  "testEmailSent" : "Test E-Mail gesendet",
  "testEmailNotSent" : "Test E-Mail konnte nicht gesendet werden",
  "reviewRequest" : "Bewertungs-Anfragen",
  "pendingTransferDescription" : "Andere Agenturansichten haben angefordert, das Unterkonto auf Sie zu Transfer .",
  "pendingTransferHeading" : "Sie haben ausstehende Anfragen zur Transfer .",
  "subAccountTransfer" : "Sub-Konto Herunterladen",
  "manageSubAccTransfer" : "Verwalten Sie Ihre Subkonto- Transfer",
  "subAccRequest" : "Unterkonto {tabName} Anfragen",
  "receive" : "Erfolgen",
  "send" : "Senden",
  "release" : "Version",
  "requests" : "{count} Anfragen",
  "subAccLimit" : "MAX. ZEITFENSTER erreicht!",
  "subAccPlanLimit" : "Sie haben 3/3 Unterkonto verwendet, die in Ihrem derzeitigen Plan zulässig sind.",
  "upgradeTime" : "Es ist das Datum für ein Upgrade!",
  "upgradeHigher" : "Bitte Upgrade auf einen höheren Plan, um die Unterkonto- Transfer zu akzeptieren.",
  "browse" : "Pläne durchsuchen",
  "pendingTransferTitle" : "Eine Transfer für dieses Unterkonto ist bereits ausstehend.",
  "unableTransfer" : "Das Unterkonto kann nicht auf diese Agentur Transfer werden .",
  "unableEjectCancel" : "Subkonto-Ausweisungsanfragen können nicht abgesagt werden",
  "EjectErrorDetails" : "Die Sub-Konto-Ezuweisen-Anfragen, die Sie absagen möchten, können nicht abgesagt werden.",
  "hippaErrorDescription" : "Das Unterkonto, das Sie zu Transfer versuchen, ist HID-Aktiviert und kann nicht an eine Nicht-HID-Adresse herunterladen.",
  "pendingTransferErrorDescription" : "Bitte geben Sie die bereits bestehenden Transfer für dieses Unterkonto ab, bevor Sie mit der Erhöhen eines Neuen beginnen.",
  "receivingAgency" : "Empfangen Agentur",
  "releasingAgency" : "Ausgestellt Agentur",
  "noOfContacts" : "Nummer von Freunde",
  "requestedDate" : "Angefordertes Datum",
  "action" : "Aktion",
  "cancelTransfer" : "Transfer Absagen",
  "decline" : "Ablehnen",
  "cancelTransferRequest" : "Absagen-Unterkonto- Transfer ?",
  "cancelEjectRequest" : "Absagen Sub-Konto auswerfen Anfragen?",
  "cancelRequestDescription" : "Die empfangende Agentur wird diese Transfer -Anfragen nicht mehr sehen, und das Unterkonto {name} wird nicht herunterladen.",
  "cancelEjectRequestDescription" : "Der ausgewählte Benutzer kann keine neue Agentur vom Sub-Konto {name} erstellen.",
  "declineRequest" : "Subkonto- Transfer ablehnen?",
  "declineRequestDescription" : "Die Anfragen werden abgelehnt und das Subkonto {name} wird nicht an Ihre Agentur herunterladen.",
  "approveTransfer" : "Genehmigen Unterkonto auf Ihre Agentur Transfer ?",
  "approveTransferDescription" : "Das Unterkonto {name} wird an Ihre Agentur herunterladen.",
  "transferNow" : "Jetzt Transfer",
  "cancelNow" : "Absagen jetzt",
  "transferErrorDetails" : "Das Unterkonto, das Sie zu Transfer versuchen, kann nicht herunterladen werden",
  "cancelRequest" : "Absagen Anfragen",
  "received" : "Empfangen",
  "approve" : "Genehmigen",
  "goBack" : "Rückgängig machen",
  "done" : "Erledigt",
  "subAccountTransferred" : "Das Unterkonto wurde erfolgreich herunterladen!",
  "subAccountTransferDescription" : "Bitte beachten Sie, dass es bei bestimmten Vermögenswerten wie Benutzer, Rufnummer, Mailgun Konto, Yext-Einträgen usw. immer noch 10 bis 15 Minuten dauern kann, bis sie in Ziel angezeigt werden.",
  "activeSubscriptionsAlert" : "Der Standort hat Dynamisch Abonnement. Bitte Absagen jedes Abonnements Vorher Blinde Weiterleitung den Standort. Liste von dynamischen Abonnements: {subscriptions}",
  "isSaasEnabled" : "SaaS Aktiviert",
  "typeToSearch" : "Eintippen zum Suchen",
  "dateAdded" : "Datum hinzugefügt",
  "emailEngagement" : "E-Mail Engagement",
  "emailReaction" : "E-Mail Reaktion",
  "emailDeliverySummary" : "Zusammenfassung der E-Mail Lieferung",
  "facebook" : "Facebook",
  "sms" : "SMS",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "Konto Zusammenfassung - {subAccountName}",
  "test" : "Test",
  "scheduleExists" : "Zeitplan existiert",
  "aToZ" : "A - Z",
  "zToA" : "Z - A",
  "onlyAdminUsersCanAddNewSubAccounts" : "Nur Admin Benutzer können neue Unterkonten einbeziehen.",
  "locationName" : "Standort",
  "transferCompleted" : "Transfer für diesen Standort wurden bereits bearbeitet. Bitte aktualisiere.",
  "unauthorizedTransfer" : "Sie sind nicht berechtigt, diese Aktion durchzuführen. Nur Eigentümer der Agentur können diese Aktion ausführen.",
  "bulkLocationTransfer" : "Massedaten Transfer Unterkonto",
  "singleRequest" : "Einzelperson-Anfragen",
  "bulkRequest" : "Massedaten-Anfragen",
  "backToLocationTransfer" : "Zurück zum Standort Transfer",
  "initiateBulkTransfer" : "Auslöser Massedaten- Transfer",
  "initiateBulkTransferDescription" : "Bitte Auswählen des Unterkontos, das Sie Transfer möchten, oder geben Sie die IDs des Unterkontos ein, das Sie Transfer möchten, Getrennt Via Communities.",
  "subAccountId" : "Unterkonto- Id",
  "selectFromList" : "Auswählen von Liste",
  "addManually" : "Manuell Hinzufügen",
  "accountsSelected" : "{count} Ausgewählt",
  "selectAllSubAccounts" : "Auswählen jeder",
  "removeAll" : "Jeder Extrahieren",
  "addManualLocations" : "Standort- Id mit Getrennt Via Komma hinzufügen",
  "manualAllLocationIds" : "Standort-IDs von dem Unterkonto, das Sie Transfer möchten, Getrennt Via Communities.",
  "enterLocationIds" : "Geben Sie Standort Id ein",
  "selectSubAccounts" : "Unterkonto auswählen und an eine derzeitige Agentur Transfer",
  "subAccountTransferWarning" : "Jeder Unterkonto-Verpasst und Benutzer erhalten Herunterladen.",
  "proceed" : "Fortfahren",
  "bulkRequestSubmitted" : "Transfer eingereicht",
  "bulkRequestSubmittedDescription" : "Jeder Unterkonto wird herunterladen, sobald die Empfangen Agentur die Transfer angenommen hat. Sie können den Status der Anfragen auf der ? {pagename} ? Seite verfolgen. (Ausgestellt sind sie wählbar)",
  "bulkTransferRequestFailed" : "Massedaten- Transfer sind Fehlgeschlagen",
  "errorLocations" : "Subkonto mit Fehler",
  "viewDetails" : "Details anzeigen",
  "somethingWrong" : "Etwas ist schiefgelaufen. Bitte Erneut versuchen.",
  "confirmBulkRequest" : "Transfer bestätigen?",
  "bulkTransferAccounts" : "Transfer '{count}' Ausgewähltes Subkonto ?",
  "confirmDetails" : "Bitte bestätigen Sie Details vorher Blinde Weiterleitung",
  "transferConsent" : "Ich habe Lesen und den oben genannten Rat und die Einwilligung zu jeder der Einschränkungen verstehen.",
  "transferInfo" : "Die Subkonto- Transfer ist Betreff an die Empfangen- Agentur, die sie in ihr Konto annehmen wird.",
  "receivingRelNo" : "Empfangen Agentur's Verbindungsnummer",
  "enterRelNo" : "Geben Sie die Verbindungsnummer ein",
  "transferConfirmation" : "Nach Abschluss dieser Aktion werden die Massedaten Transfer Anfragen an die Empfangen Agentur ({ relNo }) gesendet. Jeder Unterkonto, den Sie ausgewählt haben, wird herunterladen, sobald die Empfangen Agentur es angenommen hat. Bitte beachten Sie, dass diese Aktion für jedes in der Transfer enthaltene Unterkonto nicht rückgängig gemacht werden kann.",
  "confirmPassword" : "Bestätigen Sie, Via password unter eingeben",
  "enterPassword" : "Passwort eingeben",
  "requestTransfer" : "Transfervon Anfragen",
  "confirmTransfer" : "Transfer bestätigen",
  "notNow" : "Nicht jetzt",
  "invalidRelNo" : "Ungültige Zuordnungsnummer",
  "invalidPassword" : "Ungültiges password.",
  "searchSubAccounts" : "Unterkonto suchen",
  "searchSubAccountsId" : "Suchen Subkonto- Id",
  "takeAction" : "Aktion werden",
  "sub_account_details" : "Subprofil",
  "no_of_contacts" : "Kein von Freunde",
  "transfer_status" : "Transfer Status",
  "error_info" : "Fehler Info",
  "bulk_transfer_status" : "Transfer StatusMassedaten-Unterkontos",
  "pending" : "Anhängig",
  "processed" : "Verarbeitet",
  "reject_all" : "Ablehnen jeder",
  "accept_all" : "Jeder akzeptieren",
  "cancel_all" : "Absagen jeder",
  "clearAll" : "Klären jeder",
  "refresh" : "Aktualisieren",
  "rejected" : "Abgelehnt",
  "decline_transfer" : "Ablehnen Transfer",
  "decline_bulk_transfer" : "Massedaten Unterkonto Transfer Anfragen ?",
  "decline_bulk_transfer_description" : "Die Anfragen werden abgelehnt und die Sammlung von {length} Unterkonto wird nicht an Ihre Agentur herunterladen.",
  "accept_bulk_transfer" : "Massedaten Sub-Konto Transfer Anfragen akzeptieren?",
  "accept_bulk_transfer_description" : "Die Anfragen werden angenommen und die Sammlung von {length} Unterkonto wird an Ihre Agentur herunterladen.",
  "cancel_bulk_transfer" : "Absagen Transfer ?",
  "cancel_bulk_transfer_description" : "Sind Sie wirklich sicher, dass Sie diese Transfer von {length} Unterkonto auf „ {name}“ absagen möchten?",
  "consent_sub_account_transfer" : "Bitte Lesen und Einwilligung zu {link}",
  "sub_account_transfer_guidelines" : "Regeln Transfer von Unterkonten",
  "valid_rel" : "Geben Sie eine gültige Nummer ein",
  "something_went_wrong" : "Etwas ist schiefgelaufen. Bitte Erneut versuchen.",
  "export" : "Exportieren",
  "export_success" : "Die von Ihnen angeforderten Daten werden bearbeitet. Sie erhalten eine E-Mail mit den Daten in einem Datum.",
  "export_failed" : "Die von Ihnen angeforderten Daten konnten nicht bearbeitet werden. Bitte Erneut versuchen Sie es später.",
  "selectAllError" : "Sie können nur 1000 Subkonto an einem Datum auswählen.",
  "max1000" : "(Max. 1000)",
  "subAccountCount" : "Unterkonto Anzahl"
}
