import axios, { AxiosRequestConfig } from 'axios';

import config from '@/config';
import { useAppStore } from '@/store/app';
import { UserService } from './UserService';

const wait = (ms: number) => new Promise(res => setTimeout(res, ms));

const getHeader = () => {
  return {
    channel: (import.meta.env.VITE_API_CHANNEL as string) || 'APP',
    source: (import.meta.env.VITE_API_SOURCE as string) || 'WEB_USER',
    version: '2021-07-28',
  };
};

export const requests = (host?: string) => {
  const baseUrl = host || config.REST_API_URLS; //
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: getHeader(),
  });

  axiosInstance.interceptors.request.use(
    async (requestConfig: AxiosRequestConfig) => {
      const appStore = useAppStore();
      try {
        if (requestConfig && requestConfig.headers) {
          if (appStore.token) {
            requestConfig.headers['token-id'] = appStore.token;
          }

          if (appStore.authorization) {
            requestConfig.headers.authorization = `Bearer ${appStore.authorization}`;
          }
        }

        const APIAuthorization = import.meta.env
          .VITE_API_Authorization as string;
        const APISourceId = import.meta.env.VITE_API_SOURCE_ID as string;
        if (requestConfig && requestConfig.headers) {
          if (APIAuthorization) {
            requestConfig.headers.Authorization = APIAuthorization;
          }
          if (APISourceId) {
            requestConfig.headers['source-id'] = APISourceId;
          }
        }
      } catch (e) {}
      return requestConfig;
    }
  );

  axiosInstance.interceptors.response.use(
    function (res) {
      // console.info(`Method=${res.config.method} URL=${res.config.url} Code=${res.status}`);
      return res;
    },
    async function axiosRetryInterceptor(err) {
      const appStore = useAppStore();

      if (err.config && err.response && err.response.status === 401) {
        const retryCount = appStore.requestRetryCount;

        if (appStore.appHandshake) {
          appStore.appHandshake.then(parent => {
            parent.emit('refresh-token');
            parent.emit('refresh-authorization-token');
          });
        }

        if (retryCount >= 5) {
          appStore.updateRequestRetryCount(0);
          return Promise.reject(err);
        }
        await wait(2 ** retryCount * 100); // exponential backoff time. -> wait for token
        appStore.updateRequestRetryCount(retryCount + 1);
        return axiosInstance.request(err.config);
      }
      return Promise.reject(err);
    }
  );

  return axiosInstance;
};

export { UserService };
