{
  "common" : {
    "thisWeek" : "Tämä viikko",
    "lastWeek" : "Viime viikko",
    "lastNumberOfDays" : "viimeisin {numberOfDays} päivää",
    "thisMonth" : "Tämä kuukausi"
  },
  "subAccounts" : "Sub-Tili",
  "submitFeedback" : "Lähetä palautetta",
  "createSubAccount" : "Luoda Sub-Tili",
  "viewScheduleReports" : "Katsoa Ajoitettu raportit",
  "searchSubAccount" : "Hakea Kautta Sub-Tili",
  "columns" : "Sarakkeet",
  "sortBy" : "Lajitteluperuste",
  "selectColumns" : "Valita sarakkeet",
  "maxColumns" : "Maksimi 8 saraketta Päivämäärässä",
  "applyColumns" : "Käyttää sarakkeita",
  "activeUsers" : "Aktiiviset käyttäjät",
  "appointments" : "Tapaamiset",
  "contacts" : "Kontaktit",
  "emails" : "Gmail",
  "textMessages" : "Runko-viestejä",
  "calls" : "Puhelut",
  "submissions" : "Vastauslähetykset",
  "reviews" : "Arvostelut",
  "sitesCreated" : "Sivustot Luotu",
  "name" : "Nimi",
  "formSubmissions" : "Lomake vastauslähetykset",
  "surveySubmissions" : "Kysely vastauslähetykset",
  "facebookFormSubmissions" : "Facebook-lomake vastauslähetykset",
  "funnels" : "Suppilot",
  "websites" : "Verkkosivustot",
  "forms" : "Lomakkeet",
  "surveys" : "Kyselyt",
  "highestFirst" : "Maksimi ensin",
  "lowestFirst" : "Alhaisin ensin",
  "orderBy" : "Järjestää Kautta",
  "noMoreLocations" : "Ei enempää Osoite löytämistä",
  "preparingReport" : "Olemme Ladataan raporttisi",
  "preparingReportDesc" : "Älä lataa Sulkea Tai tätä Ikkunaa uudelleen, kun Viedä on in",
  "outOf" : "-",
  "subAccountsExported" : "Sub-Tili viety",
  "close" : "Sulje",
  "scheduledForDeletion" : "Ajoitettu poistettavaksi",
  "readyToSell" : "Valmis myytäväksi",
  "closedWon" : "Suljettu (voitettu)",
  "wpSaleSuccessful" : "WordPress-myynti on onnistunut",
  "wpNotSold" : "WordPress ei ole vielä myyty",
  "yxtSaleSuccessful" : "Yext-myynti on onnistunut",
  "yxtNotSold" : "Yextiä ei ole vielä myyty",
  "saasSubInactive" : "SaaS Tilaus on Inaktiivinen",
  "saasSubTrialing" : "SaaS Tilaus on kokeilussa",
  "saasSubActive" : "SaaS Tilaus on Dynaaminen",
  "emailRebillingActive" : "Sähköposti Uudelleenlaskutus on Dynaaminen",
  "emailRebillingInactive" : "Sähköposti Uudelleenlaskutus on Inaktiivinen",
  "twilioRebillingActive" : "Twilio Uudelleenlaskutus on Dynaaminen",
  "twilioRebillingInactive" : "Twilio Uudelleenlaskutus on Inaktiivinen",
  "wpRebillingActive" : "WhatsApp Uudelleenlaskutus on Dynaaminen",
  "wpRebillingInactive" : "WhatsApp Uudelleenlaskutus on Inaktiivinen",
  "saasModeActivated" : "SaaS -tila on Käytössä",
  "paymentPending" : "Maksu on käynnissä",
  "saasModeNotActivated" : "SaaS -tila ei ole Käytössä",
  "managedByLCPhone" : "Hallinnoida Kautta LC Puhelin",
  "migrationToLCPhone" : "Siirto käynnissä LC Puhelin",
  "notManagedByLCPhone" : "Ei Hallinnoida Kautta LC Puhelin",
  "managedByLCEmail" : "Hallinnoida Kautta LeadConnector Email System",
  "notMangedByLCEmail" : "Ei Hallinnoida Kautta LeadConnector Email System",
  "remainingWalletBalance" : "Jäljellä Lompakko Saldo - tämä SaaS sijainti",
  "reports" : "Raportit",
  "switchToSubAccount" : "Vaihda Sub-Tiliin",
  "manageClient" : "Hallinnoida asiakasta",
  "subAccountDeletedIfChangedMind" : "Tämä Sub-Tili tulee olemaan Poistettu Päällä {time}, Jos Sinä/Te've Dynaaminen mieltäsi",
  "subAccountDeletedInMinute" : "Tämä Sub-Tili Poistettu Seuraavassa minuutissa",
  "changedMind" : "Jos Sinä/Te've Dynaaminen mieltäsi",
  "clickHere" : "napsauttaa tässä",
  "subAccountPaused" : "Tämä Sub-Tili on Keskeytetty. Palvelusi voi't Kirjautua sisään, mutta Toimisto voi. Jatkaalle tämä Tili",
  "subAccountRestricted" : "Tämä Sub-Tili on Keskeytetty Maksettavaa topending Hyväksyntään.",
  "subAccountVerificationPending" : "Tämä Sub-Tili on Keskeytetty Maksettavaa to odottaa sähköposti ja puhelin vahvistusta - Sub-Tili Ylläpitäjä.",
  "subAccountDeclined" : "Tämä Sub-Tili on Keskeytetty Maksettavaa tohylätty Hyväksyntä Pyynnöt. Sinä/Te voi vielä Jatkaa tämän Sub-Tilin - ",
  "here" : "tässä.",
  "reviewAndResume" : "Arvostelulle ja Jatkaalle tässä Sub-Tilissä.",
  "incomingCalls" : "Saapuvat puhelut",
  "outgoingCalls" : "Lähtevät puhelut",
  "totalTalktime" : "Puheaika Yhteensä",
  "incomingEmails" : "Saapuva Gmail",
  "outgoingEmails" : "Lähtevä Gmail",
  "incomingSms" : "Saapuvat Runko-viestit",
  "outgoingSms" : "Lähtevät Runko-viestit",
  "contactsCreated" : "luku - Kaveri Luotu",
  "appointmentsBooked" : "luku - Tapaaminen varattu",
  "totalFormSubmissions" : "Lomake-lähetyksiä yhteensä",
  "totalSurveySubmissions" : "Kyselyn yhteensä vastauslähetyksiä",
  "totalFacebookFormSubmissions" : "Facebook-lomakelähetyksiä yhteensä",
  "incomingGMBMessages" : "Saapuvat GBP-viestit",
  "outgoingGMBMessages" : "Lähtevät GBP-viestit",
  "gmbMessages" : "GBP viestejä",
  "fbMessages" : "Facebook-viestit",
  "incomingFbMessages" : "Saapuvat Facebook-viestit",
  "outgoingFbMessages" : "Lähtevät Facebook-viestit",
  "igMessages" : "Instagram-viestit",
  "incomingIgMessages" : "Saapuvat Instagram-viestit",
  "outgoingIgMessages" : "Lähtevät Instagram-viestit",
  "totalReviews" : "Arvosteluja Yhteensä",
  "positiveReviews" : "Positiiviset arvostelut",
  "negativeReviews" : "Negatiiviset arvostelut",
  "back" : "Takaisin",
  "selectDashboards" : "Valita raporttinäkymät",
  "next" : "Seuraava",
  "backToSubAccounts" : "Takaisin Sub-Tiliin",
  "pauseSchedule" : "aikataulu Keskeytä",
  "resumeSchedule" : "Jatkaa aikataulu",
  "editSchedule" : "Muokata aikataulu",
  "addSchedule" : "Lisätä aikataulu",
  "selectReportToAdd" : "Valita raportoi Sisällyttäälle in",
  "selectAll" : "Valita huone",
  "pauseScheduleConfirm" : "Haluatko Sinä/Te varma, että Sinä/Te Keskeytä raporttia aikataulussa?",
  "resumeScheduleConfirm" : "Haluatko Sinä/Te varma Sinä/Te Jatkaa raportin aikataulun?",
  "tasks" : "Tehtävät",
  "conversations" : "Keskustelut",
  "users" : "Käyttäjä",
  "dealOverview" : "Sopimus Yleiskatsaus",
  "confirmAction" : "Vahvista Toiminta",
  "yes" : "Kyllä",
  "no" : "Ei",
  "reportDetails" : "Raportin Yksityiskohdat",
  "reportCreatedOn" : "Ilmianna Luotu Päällä",
  "searchByEmail" : "Hakea Kautta sähköposti",
  "lastActivity" : "viimeisin toiminta",
  "noRecords" : "Ei Tietueet löytäty",
  "scheduleReport" : "aikataulu Raportti",
  "schedule" : "aikataulu",
  "save" : "Tallenna",
  "emailSubject" : "Aiherivi",
  "frequency" : "Esiintymistaajuus",
  "daily" : "Päivittäinen",
  "weekly" : "Viikoittainen",
  "monthly" : "Kuukausitttainen",
  "nextScheduleDate" : "Seuraava aikataulu päivämäärä",
  "startingOn" : "Alkaen Päällä",
  "timeOfDay" : "Päivämäärä - Päivä",
  "reportsToTimezone" : "Raportti lähetetään Sub-Tilin in",
  "selectRecipients" : "Valita vastaanottajat",
  "invalidEmail" : "Sinä/Te on lisännyt Virheellinen sähköpostiosoite",
  "testEmail" : "Testata Sähköposti",
  "selectTestRecipients" : "Valita Testata vastaanottajat",
  "setupSubject" : "Määrittää edellä olevan Aihe Testatalle raportin",
  "sendTest" : "Ladata Testata",
  "scheduleSimilarSchedules" : "aikataulun vastaava raportti Muu Sub-Tilille",
  "accountSummary" : "Tili Yhteenveto",
  "scheduleList" : "aikataulu Luettelo",
  "subAccountName" : "Sub-Tili nimi",
  "nextRunDate" : "Seuraava Päivämäärä",
  "reportSectionsAdded" : "Raporttisegmenttiit lisätty",
  "status" : "Tila",
  "scheduleAction" : "aikataulu toiminto",
  "cancel" : "Peruuta",
  "pleaseWait" : "Odottaa",
  "confirm" : "Vahvista",
  "booked" : "Varattu",
  "confirmed" : "Vahvistettu",
  "showed" : "Osallistunut",
  "noShow" : "Ilmestymätön jääminen",
  "cancelled" : "Peruutettu",
  "unread" : "Lukemattomat",
  "engagement" : "Kihlaus",
  "reaction" : "Reaktio",
  "deliverySummary" : "Toimitus Yhteenveto",
  "successfulDelivery" : "Onnistui Toimitus",
  "opened" : "Avattu",
  "clicked" : "Napsautettu",
  "replied" : "Vastattu",
  "unsubscribed" : "Tilaus peruutettu",
  "complained" : "Valitettu",
  "failed" : "Epäonnistunut",
  "open" : "Avoinna",
  "won" : "Voitettu",
  "abandoned" : "Hylätty",
  "lost" : "Menetetty",
  "sent" : "Lähetetty",
  "eject" : "Poistaa",
  "delivered" : "Toimitettu",
  "faceBookLeadForm" : "Facebook- Lomake",
  "overdue" : "Erääntynyt",
  "reviewsReceived" : "Arvostelut Vastaanotettu",
  "formBuilders" : "Lomake rakennustyökalut",
  "switchToSaaS" : "Vaihda SaaS",
  "numberOfContacts" : "luku - Kaveri",
  "numberOfAppointments" : "luku - Tapaaminen",
  "outreach" : "Yhteistyö",
  "notSubAccountUser" : "Ei Sub-Tili käyttäjä",
  "searchSubAccName" : "Hakea Kautta Sub-Tilin nimi",
  "recipients" : "Vastaanottajat",
  "scheduleCreated" : "Sähköpostiraportti aikataulu Luotu for ",
  "scheduleUpdated" : "Sähköpostiraportti aikataulu päivitetty seuraavalle: ",
  "scheduleNotCreated" : "Sähköposti Raportin aikataulu ei voinut olla Luotu:lle",
  "scheduleNotUpdated" : "Sähköpostiraportin aikataulua ei voitu päivittää kohteelle",
  "total" : "Yhteensä",
  "inbound" : "Saapuva",
  "outbound" : "Lähtevä",
  "totalEmails" : "Yhteensä Gmail",
  "inboundEmails" : "Saapuva Gmail",
  "outboundEmails" : "Lähtevä Gmail",
  "totalTextMessages" : "Runko-viestejä yhteensä",
  "inboundTextMessages" : "Saapuvat Runko-viestit",
  "outboundTextMessages" : "Lähtevä Runko viestiä",
  "totalCalls" : "Puheluita Yhteensä",
  "inboundCalls" : "Saapuvat puhelut",
  "outboundCalls" : "Lähtevä puhelut",
  "faceBookLeadFormSubmissions" : "Facebook- Lomake vastauslähetykset",
  "inboundGMBMessages" : "Saapuvat GBP-viestit",
  "outboundGMBMessages" : "Lähtevä GBP-viestit",
  "totalIgMessages" : "Instagram-viestejä Yhteensä",
  "inboundIgMessages" : "Saapuvat Instagram-viestit",
  "outboundIgMessages" : "Lähtevä Instagram-viestit",
  "totalfbMessages" : "Facebook-viestien Yhteensä",
  "inboundfbMessages" : "Saapuvat Facebook-viestit",
  "outboundfbMessages" : "Lähtevä Facebook-viestit",
  "sitesCreatedFunnels" : "Luotu sivustot – Suppilot",
  "sitesCreatedActiveFunnels" : "Sivustot Luotu - Dynaaminen Suppilot",
  "sitesCreatedWebsites" : "Luotu sivustot – Verkkosivustot",
  "sitesCreatedActiveWebsites" : "Sivustot Luotu - Dynaamisen verkkosivustot",
  "sitesCreatedForms" : "Luotu sivustot – lomakkeet",
  "sitesCreatedActiveForms" : "Sivustot Luotu - Dynaaminen Forms",
  "sitesCreatedSurveys" : "Luotu sivustot – kyselyt",
  "sitesCreatedActiveSurveys" : "Sivustot Luotu - Dynaaminen Kyselyt",
  "googleReviews" : "Google-arvostelut",
  "facebookReviews" : "Facebook-arvostelut",
  "inboundCallDuration" : "Saapuva puhelu Kesto (tunti)",
  "outboundCallDuration" : "Lähtevä Soita Kesto (tunti)",
  "pleaseSelect" : "Valita",
  "subAccountIsDeleted" : "Tämä Sub-Tili on ollut Poistettu",
  "subAccountIsPaused" : "Tämä Sub-Tili on Keskeytetty",
  "success" : "Onnistui",
  "error" : "Virhe",
  "testEmailSent" : "Testata sähköposti lähetetty",
  "testEmailNotSent" : "Testata-sähköpostia ei voitu lähettää",
  "reviewRequest" : "Arvostelu Pyynnöt",
  "pendingTransferDescription" : "Muu Toimistonäkymä on pyytänyt Siirtää Sub-Tiliä kohteeseen Sinä/Te.",
  "pendingTransferHeading" : "Sinä/Te've on keskeneräinen Sub-Tili Siirtää Pyynnöt.",
  "subAccountTransfer" : "Sub-Tili Ladata",
  "manageSubAccTransfer" : "Hallinnoida sinun Sub-Tili Siirtää Pyynnöt",
  "subAccRequest" : "Sub-Tili {tabName} Pyynnöt",
  "receive" : "Periä",
  "send" : "Lähetä",
  "release" : "Julkaista",
  "requests" : "{count} Pyynnöt",
  "subAccLimit" : "Sub-Tilin käyttö SLOTMAX saavutettu!",
  "subAccPlanLimit" : "Sinä/Te on käyttänyt 3/3 Sub-Tiliä, joka on sallittu tämänhetkinen Suunnitelmassasi.",
  "upgradeTime" : "Se on Päivittämisen päivämäärä; korottaa tasoa!",
  "upgradeHigher" : "Ole hyvä ja Päivittää; korottaa tasoa korkeampaan Suunnitelmaan hyväksymään Sub-Tili Siirtää.",
  "browse" : "Selaa suunnitelmia",
  "pendingTransferTitle" : "Tämän Sub-Tilin Siirtää Pyynnöt on jo käynnissä.",
  "unableTransfer" : "Siirtää Sub-Tili tähän Toimistoon ei onnistu.",
  "unableEjectCancel" : "Peruuttaa Sub-Tilin poistaminen Pyynnöt ei onnistu",
  "EjectErrorDetails" : "Sub-Tili-poistoa Pyynnöt, jota Sinä/Te yrittävät Peruuttaa ei voi peruuttaa.",
  "hippaErrorDescription" : "Sub-Tili, jota Sinä/Te yrittää Siirtää, on HIPAA Käytössä, eikä voi olla Ladata ei-HIPAA Toimistolle.",
  "pendingTransferErrorDescription" : "Ystävällisesti Peruuttaa the jo Tämänhetkinen Siirtää Pyynnöt tälle Sub-Tilille Ennen kuin jatkat Nostaa a uusien.",
  "receivingAgency" : "Vastaanotettu Toimisto",
  "releasingAgency" : "Julkaistu Toimisto",
  "noOfContacts" : "Ei. - Kaveri",
  "requestedDate" : "Pyydetty Päivämäärä",
  "action" : "Toiminta",
  "cancelTransfer" : "Peruuttaa Siirtää",
  "decline" : "Hylätä",
  "cancelTransferRequest" : "Peruuttaa Sub-Tili Siirtää Pyynnöt?",
  "cancelEjectRequest" : "Peruuttaa Sub-Tili eject Pyynnöt?",
  "cancelRequestDescription" : "Vastaanotettu Toimisto ei enää näe tätä Siirtää Pyynnöt, eikä Sub-Tili {name} ole Ladata.",
  "cancelEjectRequestDescription" : "Valittu Käyttäjä ei voi enää luoda Luoda uusi Toimisto - Sub-Tili {name}.",
  "declineRequest" : "Hylätä Sub-Tili Siirtää Pyynnöt?",
  "declineRequestDescription" : "Pyynnöt hylätään ja Sub-Tili {name} ei ole Toimistosi Ladata.",
  "approveTransfer" : "Hyväksyä Sub-Tili Siirtää Toimistoosi?",
  "approveTransferDescription" : "{name} Sub-Tili on Toimistosi Ladata.",
  "transferNow" : "Siirtää nyt",
  "cancelNow" : "Peruuttaa nyt",
  "transferErrorDetails" : "Sub-Tili, jota Sinä/Te yrittää Siirtää, ei voi olla Ladata",
  "cancelRequest" : "Peruuttaa Pyynnöt",
  "received" : "Vastaanotettu",
  "approve" : "Hyväksyä",
  "goBack" : "Kumota",
  "done" : "Valmis",
  "subAccountTransferred" : "Sub-Tili on onnistunut Ladata!",
  "subAccountTransferDescription" : "Muistiinpano, että Tasainen Sub-Tilistäkin on ollut Ladata, jotkin omaisuuserät Tykätä Käyttäjä, Puhelinnumero, Mailgun Tili, Yext-listaukset jne. voi vielä kestää 10-15 minuuttia heijastua in .",
  "activeSubscriptionsAlert" : "Sijaintissa on Dynaaminen Tilaus. Ole hyvä Peruuttaa Tilaus Ennen Sokkosiirtoa the sijainti. Luettelo – Dynaaminen Tilaus: {subscriptions}",
  "isSaasEnabled" : "SaaS Käytössä",
  "typeToSearch" : "Hae kirjoittamalla",
  "dateAdded" : "Päivämäärä",
  "emailEngagement" : "Sähköposti",
  "emailReaction" : "Sähköposti",
  "emailDeliverySummary" : "Sähköposti Toimitus Yhteenveto",
  "facebook" : "Facebook",
  "sms" : "SMS",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "Tili Yhteenveto – {subAccountName}",
  "test" : "Testata",
  "scheduleExists" : "aika on olemassa",
  "aToZ" : "A–Z",
  "zToA" : "Z–A",
  "onlyAdminUsersCanAddNewSubAccounts" : "Vain Ylläpitäjä käyttäjät voivat Sisällyttää uuden sub-Tilin.",
  "locationName" : "Sijainti nimi",
  "transferCompleted" : "Tämän sijainnin Siirtää Pyynnöt on jo käsitelty. Päivitä.",
  "unauthorizedTransfer" : "Sinä/Te ei ole valtuutettu suorittamaan tätä toimintoa. Vain Toimiston omistajat voivat suorittaa tämän toiminnan.",
  "bulkLocationTransfer" : "Massa- Siirtää Sub Tili",
  "singleRequest" : "Yksilöllinen Pyynnöt",
  "bulkRequest" : "Massa- Pyynnöt",
  "backToLocationTransfer" : "Takaisin Sijainti Siirtää",
  "initiateBulkTransfer" : "aloitus Massa- Sub-Tili Siirtää",
  "initiateBulkTransferDescription" : "Valita ala-Tili Sinä/Te haluaa Siirtää Tai kirjoita tunnukset - alaTili Sinä/Te haluaa Siirtää, Erotettu Kautta pilkkuja.",
  "subAccountId" : "Sub-Tili Id",
  "selectFromList" : "Valita - Luettelo",
  "addManually" : "Lisätä manuaalisesti",
  "accountsSelected" : "{count} Valittu",
  "selectAllSubAccounts" : "Valita huone",
  "removeAll" : "Poimia neljä",
  "addManualLocations" : "Lisätä Sijainti Id 's Erotettu Kautta pilkulla",
  "manualAllLocationIds" : "Sijaintitunnukset - ala-Tili Sinä/Te-toiveet Siirtää, Erotettu Kautta pilkulla.",
  "enterLocationIds" : "Anna Id",
  "selectSubAccounts" : "Valita Sub-Tili Ja Siirtää tämänhetkinen Toimistoon",
  "subAccountTransferWarning" : "prie sub-Tilivarat ja Käyttäjät saavat Ladata.",
  "proceed" : "Edetä",
  "bulkRequestSubmitted" : "Massa- Siirtää Pyynnöt Lähetetty",
  "bulkRequestSubmittedDescription" : "prie sub-Tili on Ladata, kun Vastaanotettu Toimisto hyväksyy Siirtää Pyynnöt. Sinä/Te voi seurata Tila - the Pyynnöt Päällä ❝{pagename}❞ Sivu. (Julkaistu he ovat oikeutettuja)",
  "bulkTransferRequestFailed" : "Massa- Siirtää Pyynnöt Epäonnistunut",
  "errorLocations" : "Sub Tili, jossa on virhe",
  "viewDetails" : "Näytä tiedot",
  "somethingWrong" : "Jokin meni pieleen. Ole hyvä ja Yrittää uudelleen.",
  "confirmBulkRequest" : "Vahvista Massa- Siirtää Pyynnöt?",
  "bulkTransferAccounts" : "Massa- Siirtää '{count} ' Valittu Sub Tili ?",
  "confirmDetails" : "Vahvista yksityiskohdat Ennen Sokkosiirtoa",
  "transferConsent" : "Minulla on Lukea ja ymmärtänyt yllä olevan oppaan ja Suostumus ton rajoitukset.",
  "transferInfo" : "Sub Tili Siirtää on Aihe ja Vastaanotettu Toimisto hyväksyy sen Tiliinsä.",
  "receivingRelNo" : "Vastaanotettu Toimiston suhde luku",
  "enterRelNo" : "Lisää suhde luku",
  "transferConfirmation" : "Tämän toiminnan päätyttyä Massa-Siirtää Pyynnöt lähetetään osoitteeseen Vastaanotettu Toimisto ({ relNo }). prie sub-Tili Sinä/Te've Valittu on Ladata, kun Vastaanotettu Toimisto hyväksyy sen. Muistiinpano, että tämä on peruuttamatonta in sisältyvälle Toiminta-ala-Tilille.",
  "confirmPassword" : "Vahvista Kautta- password (jonkin) ala side",
  "enterPassword" : "Anna salasana",
  "requestTransfer" : "Pyynnöt Siirtää",
  "confirmTransfer" : "Vahvista Siirtää",
  "notNow" : "Ei nyt",
  "invalidRelNo" : "Virheellinen Suhdeluku",
  "invalidPassword" : "Virheellinen password.",
  "searchSubAccounts" : "Hakea Sub-Tili",
  "searchSubAccountsId" : "Hakea Sub-Tili Id",
  "takeAction" : "Ryhdy Toiminta",
  "sub_account_details" : "Alaprofiili",
  "no_of_contacts" : "Ei – Kaveri",
  "transfer_status" : "Siirtää Tila",
  "error_info" : "Tiedot",
  "bulk_transfer_status" : "Massa- Sub-Tili Siirtää Tila",
  "pending" : "Odottavat",
  "processed" : "Käsitelty",
  "reject_all" : "Hylätä sitten",
  "accept_all" : "Hyväksy",
  "cancel_all" : "Peruuttaa enemmän",
  "clearAll" : "Tyhjennä osa",
  "refresh" : "Päivitä",
  "rejected" : "Kielletty",
  "decline_transfer" : "Hylätä Siirtää",
  "decline_bulk_transfer" : "Hylätä Massa- sub-Tili Siirtää Pyynnöt ?",
  "decline_bulk_transfer_description" : "Pyynnöt hylätään ja Kokoelma - {length} sub-Tili ei ole Ladata Toimistoasi.",
  "accept_bulk_transfer" : "Hyväksytään Massa- sub-Tili Siirtää Pyynnöt ?",
  "accept_bulk_transfer_description" : "Pyynnöt on Hyväksytty ja Kokoelma - {length} sub-Tili on Ladata Toimistoasi.",
  "cancel_bulk_transfer" : "Peruuttaa Massa- Siirtää ?",
  "cancel_bulk_transfer_description" : "Onko Sinä/Te todella varma Sinä/Te haluat Peruuttaa tätä Massa- Siirtää - {length} Sub-Tili kohteeseen “{name}”",
  "consent_sub_account_transfer" : "Lukea ja Suostumus vastaanottajalle {link}",
  "sub_account_transfer_guidelines" : "Sub-Tili Siirtää Ohjeet",
  "valid_rel" : "Anna kelvollinen luku",
  "something_went_wrong" : "Jokin meni pieleen. Ole hyvä ja Yrittää uudelleen.",
  "export" : "Vie",
  "export_success" : "Sinä/Teen pyytämiä tietoja käsitellään. Sinä/Te lähettää sähköpostin, jossa on tiedot in päivämäärässä.",
  "export_failed" : "Sinä/Te pyytämiä tietoja ei voitu käsitellä. Yrittää myöhemmin uudelleen.",
  "selectAllError" : "Sinä/Te voi vain Valita 1000 Sub Tili Päivämääränä.",
  "max1000" : "(Maksimi 1 000)",
  "subAccountCount" : "Sub-Tili Lukumäärä"
}
