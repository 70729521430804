{
  "common" : {
    "thisWeek" : "Questa settimana",
    "lastWeek" : "Settimana scorsa",
    "lastNumberOfDays" : "ultimi {numberOfDays} giorni",
    "thisMonth" : "Questo mese"
  },
  "subAccounts" : "Account secondari",
  "submitFeedback" : "Invia feedback",
  "createSubAccount" : "Crea Account secondario",
  "viewScheduleReports" : "Visualizza i rapporti Programmato",
  "searchSubAccount" : "Cerca tramiteAccountsecondario",
  "columns" : "Colonne",
  "sortBy" : "Ordina per",
  "selectColumns" : "Seleziona colonne",
  "maxColumns" : "Massimo 8 colonne a Dati",
  "applyColumns" : "Applica colonne",
  "activeUsers" : "Utenti attivi",
  "appointments" : "Appuntamenti",
  "contacts" : "Contatti",
  "emails" : "Gmail",
  "textMessages" : "Messaggi corporei",
  "calls" : "Chiamate",
  "submissions" : "Invii",
  "reviews" : "Recensioni",
  "sitesCreated" : "Siti Creato",
  "name" : "Nome",
  "formSubmissions" : "Invii Modulo",
  "surveySubmissions" : "Invii Sondaggio",
  "facebookFormSubmissions" : "Invii Modulo di Facebook",
  "funnels" : "Funnel",
  "websites" : "Siti web",
  "forms" : "Moduli",
  "surveys" : "Sondaggi",
  "highestFirst" : "Massimo prima",
  "lowestFirst" : "Prima il più basso",
  "orderBy" : "Ordina tramite",
  "noMoreLocations" : "Non più Indirizzo trovato",
  "preparingReport" : "Siamo Caricamento il tuo rapporto",
  "preparingReportDesc" : "Non chiudere O ricaricare questa finestra mentre l'esportazione è in corso",
  "outOf" : "Da",
  "subAccountsExported" : "Account secondari esportati",
  "close" : "Chiudi",
  "scheduledForDeletion" : "Eliminazione Programmato",
  "readyToSell" : "Pronto per la vendita",
  "closedWon" : "Chiuso (Vinto)",
  "wpSaleSuccessful" : "La vendita di WordPress è andata a buon fine",
  "wpNotSold" : "WordPress non ancora venduto",
  "yxtSaleSuccessful" : "La vendita Yext è andata a buon fine",
  "yxtNotSold" : "Yext non ancora venduto",
  "saasSubInactive" : "SaaS Abbonamento è Inattivo",
  "saasSubTrialing" : "SaaS Abbonamento è in fase di prova",
  "saasSubActive" : "SaaS Abbonamento è Dinamico",
  "emailRebillingActive" : "Email Rifatturazione e Dinamico",
  "emailRebillingInactive" : "Email Rifatturazione è Inattivo",
  "twilioRebillingActive" : "Twilio Rifatturazione è Dinamico",
  "twilioRebillingInactive" : "Twilio Rifatturazione è Inattivo",
  "wpRebillingActive" : "WhatsApp Rifatturazione e Dinamico",
  "wpRebillingInactive" : "WhatsApp Rifatturazione è Inattivo",
  "saasModeActivated" : "La modalità SaaS è Abilitato",
  "paymentPending" : "Il Pagamento è in sospeso",
  "saasModeNotActivated" : "La modalità SaaS non è Abilitato",
  "managedByLCPhone" : "Gestisci tramite LC Telefono",
  "migrationToLCPhone" : "In fase di migrazione a LC Telefono",
  "notManagedByLCPhone" : "Non Gestisci tramite LC Telefono",
  "managedByLCEmail" : "Gestisci tramite LeadConnector Email System",
  "notMangedByLCEmail" : "Non gestisci tramite LeadConnector Email System",
  "remainingWalletBalance" : "Restante Portafoglio Saldo di questo SaaS Luogo",
  "reports" : "Report",
  "switchToSubAccount" : "Passa Account secondario",
  "manageClient" : "Gestisci Cliente",
  "subAccountDeletedIfChangedMind" : "QuestoAccount secondario sarà Eliminato Attivo {time}, Se Tu've Dinamico la tua mente",
  "subAccountDeletedInMinute" : "QuestoAccount secondario sarà Eliminato entro Successivo minuto",
  "changedMind" : "Se hai dinamico la tua mente",
  "clickHere" : "fai clic qui",
  "subAccountPaused" : "QuestoAccount secondario è Sospeso. I tuoi clienti non possono accedere ma l'Agenzia può. Per riprendi questo Account",
  "subAccountRestricted" : "QuestoAccount secondario è Sospeso Dovuto in attesa di approvazione.",
  "subAccountVerificationPending" : "QuestoAccount secondario è Sospeso Dovuto per Email e telefono in attesa di verifica Da Sub-Account Amministratore.",
  "subAccountDeclined" : "QuestoAccount secondario è Sospeso Dovuto per il rifiuto dell'approvazione Richieste. Tu puoi ancora riprendi questoAccount secondario Da ",
  "here" : "qui.",
  "reviewAndResume" : "per recensire e riprendi questoAccount secondario.",
  "incomingCalls" : "Chiamate in entrata",
  "outgoingCalls" : "Chiamate in uscita",
  "totalTalktime" : "Tempo di conversazione Totale",
  "incomingEmails" : "Gmailin entrata",
  "outgoingEmails" : "Gmailin uscita",
  "incomingSms" : "Messaggi Corpo in entrata",
  "outgoingSms" : "Messaggi Corpo in uscita",
  "contactsCreated" : "numero di Amici Creato",
  "appointmentsBooked" : "numero di Appuntamento prenotato",
  "totalFormSubmissions" : "Totale invii modulo",
  "totalSurveySubmissions" : "Totale invii Sondaggio",
  "totalFacebookFormSubmissions" : "Totale degli invii di Modulo di Facebook",
  "incomingGMBMessages" : "Messaggi in GBP in entrata",
  "outgoingGMBMessages" : "Messaggi in GBP",
  "gmbMessages" : "Messaggi in GBP",
  "fbMessages" : "Messaggi di Facebook",
  "incomingFbMessages" : "Messaggi Facebook in arrivo",
  "outgoingFbMessages" : "Messaggi Facebook in uscita",
  "igMessages" : "Messaggi di Instagram",
  "incomingIgMessages" : "Messaggi Instagram in arrivo",
  "outgoingIgMessages" : "Messaggi Instagram in uscita",
  "totalReviews" : "Recensioni Totale",
  "positiveReviews" : "Recensioni positive",
  "negativeReviews" : "Recensioni negative",
  "back" : "Indietro",
  "selectDashboards" : "Seleziona dashboard",
  "next" : "Successivo",
  "backToSubAccounts" : "Indietro ad Account secondari",
  "pauseSchedule" : "Sospendi il programma",
  "resumeSchedule" : "Riprendi il programma",
  "editSchedule" : "Modifica programma",
  "addSchedule" : "Aggiungi programma",
  "selectReportToAdd" : "Seleziona rapporti a Email in",
  "selectAll" : "Seleziona ogni",
  "pauseScheduleConfirm" : "Sei sicuro che tu vuoi sospendere il programma del report?",
  "resumeScheduleConfirm" : "Sei sicuro che tu vuoi riprendi il report programma?",
  "tasks" : "Attività",
  "conversations" : "Conversazioni",
  "users" : "Utente",
  "dealOverview" : "Panoramica Accordo",
  "confirmAction" : "Conferma Azione",
  "yes" : "Sì",
  "no" : "No",
  "reportDetails" : "Dettagli del rapporto",
  "reportCreatedOn" : "Segnala Creato Attivo",
  "searchByEmail" : "Cerca tramite Email",
  "lastActivity" : "ultime attività",
  "noRecords" : "Nessun Record trovato",
  "scheduleReport" : "Programma Report",
  "schedule" : "programma",
  "save" : "Salva",
  "emailSubject" : "Oggetto Email",
  "frequency" : "Frequenza",
  "daily" : "Quotidiana",
  "weekly" : "Settimanale",
  "monthly" : "Mensile",
  "nextScheduleDate" : "Data del Successivo programma",
  "startingOn" : "A partire da Attivo",
  "timeOfDay" : "Dati di giorno",
  "reportsToTimezone" : "Il rapporto sarà inviato in fuso orarioAccount secondario",
  "selectRecipients" : "Seleziona i destinatari",
  "invalidEmail" : "Tu hai inserito un'email Non valido Indirizzo",
  "testEmail" : "Email Test",
  "selectTestRecipients" : "Seleziona Test i destinatari",
  "setupSubject" : "Configura l'Argomento sopra per Test il rapporto",
  "sendTest" : "Test di carica",
  "scheduleSimilarSchedules" : "programma report simile per Account secondario Altro",
  "accountSummary" : "Riepilogo Account",
  "scheduleList" : "programma Elenco",
  "subAccountName" : "Nome Account secondario",
  "nextRunDate" : "Data Successivo esecuzione",
  "reportSectionsAdded" : "Aggiunto Report Segmenti",
  "status" : "Stato",
  "scheduleAction" : "programma Azione",
  "cancel" : "Annulla",
  "pleaseWait" : "Attendi per favore",
  "confirm" : "Conferma",
  "booked" : "Prenotato",
  "confirmed" : "Confermato",
  "showed" : "Presentazione",
  "noShow" : "Mancata presentazione",
  "cancelled" : "Annullato",
  "unread" : "Non letto",
  "engagement" : "Fidanzamento",
  "reaction" : "Reazione",
  "deliverySummary" : "Riepilogo Consegna",
  "successfulDelivery" : "Consegna riuscita",
  "opened" : "Aperto",
  "clicked" : "Cliccato",
  "replied" : "Con risposta",
  "unsubscribed" : "Annullato l'iscrizione",
  "complained" : "Lamentato",
  "failed" : "Non riuscito",
  "open" : "Aperta",
  "won" : "Vinta",
  "abandoned" : "Abbandonata",
  "lost" : "Persa",
  "sent" : "Inviato",
  "eject" : "Espelli",
  "delivered" : "Consegnato",
  "faceBookLeadForm" : "Modulo Lead di Facebook",
  "overdue" : "Scaduto",
  "reviewsReceived" : "Recensioni Ricevuto",
  "formBuilders" : "Costruttori Modulo",
  "switchToSaaS" : "Passa a SaaS",
  "numberOfContacts" : "numero di Amici",
  "numberOfAppointments" : "numero di Appuntamento",
  "outreach" : "Sensibilizzazione",
  "notSubAccountUser" : "Non un utenteAccount secondario",
  "searchSubAccName" : "Cerca tramite il nomeAccount secondario",
  "recipients" : "Destinatari",
  "scheduleCreated" : "Report Email programma Crea per ",
  "scheduleUpdated" : "Email Report programma Aggiornato per ",
  "scheduleNotCreated" : "Non è stato possibile creare il programma Report Email per",
  "scheduleNotUpdated" : "Il programma di report Email non può essere aggiornato per",
  "total" : "Totale",
  "inbound" : "In entrata",
  "outbound" : "In uscita",
  "totalEmails" : "Totale Gmail",
  "inboundEmails" : "Gmailin entrata",
  "outboundEmails" : "In uscita Gmail",
  "totalTextMessages" : "Totale messaggi Corpo",
  "inboundTextMessages" : "Messaggi Corpo in entrata",
  "outboundTextMessages" : "Messaggi In uscita Corpo",
  "totalCalls" : "Chiamate Totale",
  "inboundCalls" : "Chiamate in entrata",
  "outboundCalls" : "Chiamate In uscita",
  "faceBookLeadFormSubmissions" : "Invii Modulo Lead di Facebook",
  "inboundGMBMessages" : "Messaggi in GBP in entrata",
  "outboundGMBMessages" : "Messaggi in GBP In uscita",
  "totalIgMessages" : "Totale messaggi Instagram",
  "inboundIgMessages" : "Messaggi Instagram in entrata",
  "outboundIgMessages" : "Messaggi Instagram In uscita",
  "totalfbMessages" : "Totale messaggi Facebook",
  "inboundfbMessages" : "Messaggi Facebook in entrata",
  "outboundfbMessages" : "Messaggi Facebook In uscita",
  "sitesCreatedFunnels" : "Siti Creato - Funnel",
  "sitesCreatedActiveFunnels" : "Sites Creato - Dinamico Funnel",
  "sitesCreatedWebsites" : "Siti Creato - Siti web",
  "sitesCreatedActiveWebsites" : "Sites Creato - Dinamico Websites",
  "sitesCreatedForms" : "Siti Creato - Moduli",
  "sitesCreatedActiveForms" : "Sites Creato - Dinamico Forms",
  "sitesCreatedSurveys" : "Siti Creato - Sondaggi",
  "sitesCreatedActiveSurveys" : "Sites Creato - Dinamico Surveys",
  "googleReviews" : "Recensioni Google",
  "facebookReviews" : "Recensioni su Facebook",
  "inboundCallDuration" : "Durata Chiama in entrata (ora)",
  "outboundCallDuration" : "In uscita Chiama Durata (ora)",
  "pleaseSelect" : "Seleziona",
  "subAccountIsDeleted" : "QuestoAccount secondario è stato Eliminato",
  "subAccountIsPaused" : "QuestoAccount secondario è stato Sospeso",
  "success" : "Successo",
  "error" : "Errore",
  "testEmailSent" : "Email Test inviato",
  "testEmailNotSent" : "Email Test non ha potuto essere inviato",
  "reviewRequest" : "Richieste Recensione",
  "pendingTransferDescription" : "Altro Vista agenzia ha richiesto di trasferiresci Account secondario a Tu.",
  "pendingTransferHeading" : "Hai in sospesoAccount secondario Trasferisci Richieste.",
  "subAccountTransfer" : "Account secondario Scarica",
  "manageSubAccTransfer" : "Gestisci il tuoAccount secondario Trasferisci Richieste",
  "subAccRequest" : "Richieste Account secondario {tabName}",
  "receive" : "Eredita",
  "send" : "Invia",
  "release" : "Versione",
  "requests" : "{count} Richieste",
  "subAccLimit" : "LIMITE DI FASCE ORARI di utilizzo Account secondario raggiunto!",
  "subAccPlanLimit" : "Hai utilizzato 3/3 Account secondario consentiti con il tuo piano Attuale.",
  "upgradeTime" : "Sono dati per un aggiornamento!",
  "upgradeHigher" : "Aggiorna a un piano superiore per accettare l' Account secondario Trasferisci.",
  "browse" : "Sfoglia i piani",
  "pendingTransferTitle" : "Un Trasferisci Richieste per questoAccount secondario è già in sospeso.",
  "unableTransfer" : "Impossibile trasferiresciAccount secondario a questa Agenzia.",
  "unableEjectCancel" : "Impossibile annullareAccount secondario- Rimuovi Richieste",
  "EjectErrorDetails" : "L'eliminazioneAccount secondario Richieste che stai tentando di annullare non può essere annullata.",
  "hippaErrorDescription" : "L' Account secondario che stai cercando di trasferire è HIPAA Abilitato e non può essere scaricato a un'agenzia non HIPAA.",
  "pendingTransferErrorDescription" : "Si prega di annullare il già Attuale Trasferisci Richieste per questoAccount secondario Prima procedendo a quello di Alza a Nuovo.",
  "receivingAgency" : "Ricevuto Agenzia",
  "releasingAgency" : "Emesso Agenzia",
  "noOfContacts" : "No. di Amici",
  "requestedDate" : "Data richiesta",
  "action" : "Azione",
  "cancelTransfer" : "Annulla Trasferisci",
  "decline" : "Rifiuta",
  "cancelTransferRequest" : "AnnullaAccount secondario Trasferisci Richieste?",
  "cancelEjectRequest" : "Annulla Sub- EliminazioneAccount Richieste?",
  "cancelRequestDescription" : "La Ricevuto Agenzia non vedrà più questo Trasferisci Richieste e l' Account secondario {name} non sarà Scarica.",
  "cancelEjectRequestDescription" : "L'utente selezionato non sarà più in grado di creare un nuovo agenziaAccount secondario {name}.",
  "declineRequest" : "Account secondario Rifiuta Trasferisci Richieste?",
  "declineRequestDescription" : "Il Richieste verrà rifiutato e l' Account secondario {name} non sarà scaricabile per la tua Agenzia.",
  "approveTransfer" : "ApprovazioneAccount secondario Trasferisci alla tua Agenzia?",
  "approveTransferDescription" : "{name} Account secondario verrà scaricato sulla tua Agenzia.",
  "transferNow" : "Trasferisci ora",
  "cancelNow" : "Annulla ora",
  "transferErrorDetails" : "Il Sub-Account che stai tentando di Trasferisci non può essere Scarica",
  "cancelRequest" : "Annulla Richieste",
  "received" : "Ricevuto",
  "approve" : "Approva",
  "goBack" : "Annulla",
  "done" : "Fatto",
  "subAccountTransferred" : "L' Account secondario è stato scaricato con successo!",
  "subAccountTransferDescription" : "Tieni presente che Uniforme, sebbene l' Account secondario sia stato Scarica, alcune risorse Metti Mi piace Utente, Numero di telefono, AccountMailgun , elenchi Yext, ecc. Potrebbero ancora richiedere 10-15 minuti per riflettere in destinazione.",
  "activeSubscriptionsAlert" : "Il Luogo ha Dinamico Abbonamento. Annullare ogni Abbonamento Prima trasferimento a freddo the Luogo. Elenco di Dinamico Abbonamento: {subscriptions}",
  "isSaasEnabled" : "SaaS Abilitato",
  "typeToSearch" : "Digita per cercare",
  "dateAdded" : "Data di aggiunta",
  "emailEngagement" : "Coinvolgimento Email",
  "emailReaction" : "Reazione Email",
  "emailDeliverySummary" : "Riepilogo Consegna Email",
  "facebook" : "Facebook",
  "sms" : "SMS",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "Riepilogo Account - {subAccountName}",
  "test" : "Test",
  "scheduleExists" : "il programma esiste",
  "aToZ" : "Dalla A alla Z",
  "zToA" : "Z - A",
  "onlyAdminUsersCanAddNewSubAccounts" : "Solo l'Amministratore Utente può includere gli account secondari.",
  "locationName" : "Nome Luogo",
  "transferCompleted" : "Trasferisci Richieste per questo Luogo è già elaborato. Aggiorna.",
  "unauthorizedTransfer" : "Tu non sei autorizzato a compiere questa Azione. Solo i titolari di Agenzia possono eseguire questa Azione.",
  "bulkLocationTransfer" : "Account secondari Trasferisci In blocco",
  "singleRequest" : "Individuo Richieste",
  "bulkRequest" : "In blocco Richieste",
  "backToLocationTransfer" : "Indietro a Trasferisci Luogo",
  "initiateBulkTransfer" : "trigger In blocco Account secondario Trasferisci",
  "initiateBulkTransferDescription" : "Per favore seleziona il sub-account che desideri Trasferisci O inserisci gli ID del sub-account Tu desideri Trasferisci, Separato tramite virgole.",
  "subAccountId" : "secondarioAccount Id",
  "selectFromList" : "Seleziona Da elenco",
  "addManually" : "Aggiungi manualmente",
  "accountsSelected" : "{count} Selezionato",
  "selectAllSubAccounts" : "Seleziona ogni",
  "removeAll" : "Estrai ogni",
  "addManualLocations" : "Aggiungi Id luogo con Separato tramite virgole",
  "manualAllLocationIds" : "I Luogo IDs del sub-account desiderano Trasferisci, Separato tramite virgole.",
  "enterLocationIds" : "Inserisci Id del luogo",
  "selectSubAccounts" : "Seleziona Account secondario e Trasferisci a un Attuale Agenzia",
  "subAccountTransferWarning" : "ogni risorsa dell'account secondario e utente riceverà Scarica.",
  "proceed" : "Procedi",
  "bulkRequestSubmitted" : "In blocco Trasferisci Richieste Inviato",
  "bulkRequestSubmittedDescription" : "ogni account secondario sarà Scarica una volta che la Ricevuto Agenzia accetta i Trasferisci Richieste. Tu puoi tracciare lo Stato di the Richieste Attivo la ❝{pagename}❞ Pagina. (Emesso sono idonei)",
  "bulkTransferRequestFailed" : "In blocco Trasferisci Richieste Non riuscito",
  "errorLocations" : "Account secondari con errore",
  "viewDetails" : "Visualizza dettagli",
  "somethingWrong" : "Qualcosa è andato storto. Riprova.",
  "confirmBulkRequest" : "Conferma in blocco Trasferisci Richieste?",
  "bulkTransferAccounts" : "In blocco Trasferisci '{count} ' Selezionato Account secondario ?",
  "confirmDetails" : "Conferma Dettagli Prima Trasferimento a Freddo",
  "transferConsent" : "Ho letto e compreso la guida di cui sopra e consenso a ogni limitazione.",
  "transferInfo" : "Sub Account Trasferisci is Argomento alla Ricevuto Agenzia accettandolo sul proprio Account.",
  "receivingRelNo" : "La relazione di Ricevuto Agenzia numero",
  "enterRelNo" : "Inserisci il numero di relazione",
  "transferConfirmation" : "Al completamento di questa Azione, l'In blocco Trasferisci Richieste sarà inviato alla Ricevuto Agenzia ({ relNo }). Ogni account secondario che hai selezionato sarà Scarica una volta che Ricevuto Agenzia lo accetta. Si prega di notare che questa Azione è irreversibile per ogni account secondario incluso in Trasferisci.",
  "confirmPassword" : "Conferma tramite digitando password sotto",
  "enterPassword" : "Inserisci la password",
  "requestTransfer" : "Richieste Trasferisci",
  "confirmTransfer" : "Conferma Trasferisci",
  "notNow" : "Non ora",
  "invalidRelNo" : "Non valido Relazione numero",
  "invalidPassword" : "password non valida .",
  "searchSubAccounts" : "Account secondario Cerca",
  "searchSubAccountsId" : "secondario CercaAccount Id",
  "takeAction" : "Azione",
  "sub_account_details" : "Sottoprofilo",
  "no_of_contacts" : "No di Amici",
  "transfer_status" : "Stato Trasferisci",
  "error_info" : "Informazioni Errore",
  "bulk_transfer_status" : "In blocco Account secondario Trasferisci Stato",
  "pending" : "In sospeso",
  "processed" : "Elaborato",
  "reject_all" : "Rifiuta ogni",
  "accept_all" : "Accetta ogni",
  "cancel_all" : "Annulla ogni",
  "clearAll" : "Rimuovi ogni",
  "refresh" : "Aggiorna",
  "rejected" : "Negato",
  "decline_transfer" : "Rifiuta il Trasferisci",
  "decline_bulk_transfer" : "Rifiuta in blocco account secondario Trasferisci Richieste ?",
  "decline_bulk_transfer_description" : "Il Richieste verrà rifiutato e l'account secondario Collezione di {length} non sarà scaricabile per la tua Agenzia.",
  "accept_bulk_transfer" : "Accetta In blocco account secondario Trasferisci Richieste ?",
  "accept_bulk_transfer_description" : "Il Richieste sarà Accettato e l'account secondario Collezione di {length} sarà Scarica per la tua Agenzia.",
  "cancel_bulk_transfer" : "Annulla il Trasferisci In blocco ?",
  "cancel_bulk_transfer_description" : "Sei davvero sicuro di voler Annulla questo In blocco Trasferisci da {length} Account secondario a \"{name} \"",
  "consent_sub_account_transfer" : "Leggi e acconsenti a {link}",
  "sub_account_transfer_guidelines" : "Linee guida Trasferisci Account secondario",
  "valid_rel" : "Inserisci numero di relazione valido",
  "something_went_wrong" : "Qualcosa è andato storto. Riprova.",
  "export" : "Esporta",
  "export_success" : "I dati che Tu ha richiesto sono in fase di elaborazione. Tu eredita Email con i dati in alcuni Dati.",
  "export_failed" : "I dati Tu richiesti non hanno potuto essere trattati. Riprova più tardi.",
  "selectAllError" : "Puoi solo selezionare 1000 Account secondario presso un Data.",
  "max1000" : "(Massimo 1000)",
  "subAccountCount" : "Account secondario Numero"
}
