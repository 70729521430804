 {
    "common": {
    "thisWeek": "This Week",
    "lastWeek": "Last Week",
    "lastNumberOfDays": "Last {numberOfDays} Days",
    "thisMonth": "This Month"
    },
    "subAccounts": "Sub-Accounts",
    "submitFeedback": "Submit Feedback",
    "createSubAccount": "Create Sub-Account",
    "viewScheduleReports": "View Scheduled Reports",
    "searchSubAccount": "Search by Sub-Account",
    "columns": "Columns",
    "sortBy": "Sort By",
    "selectColumns": "Select columns",
    "maxColumns": "Max 8 columns at a time",
    "applyColumns": "Apply Columns",
    "activeUsers": "Active users",
    "appointments": "Appointments",
    "contacts": "Contacts",
    "emails": "Emails",
    "textMessages": "Text messages",
    "calls": "Calls",
    "submissions": "Submissions",
    "reviews": "Reviews",
    "sitesCreated": "Sites Created",
    "name": "Name",
    "formSubmissions": "Form Submissions",
    "surveySubmissions": "Survey Submissions",
    "facebookFormSubmissions": "Facebook Form Submissions",
    "funnels": "Funnels",
    "websites": "Websites",
    "forms": "Forms",
    "surveys": "Surveys",
    "highestFirst": "Highest first",
    "lowestFirst": "Lowest first",
    "orderBy": "Order by",
    "noMoreLocations": "No more locations found",
    "preparingReport": "We are preparing your report",
    "preparingReportDesc": "Do not close or reload this window while export is in progress",
    "outOf": "out of",
    "subAccountsExported": "Sub-Accounts exported",
    "close": "Close",
    "scheduledForDeletion": "Scheduled for deletion",
    "readyToSell": "Ready to sell",
    "closedWon": "Closed (Won)",
    "wpSaleSuccessful": "WordPress sale is successful",
    "wpNotSold": "WordPress not sold yet",
    "yxtSaleSuccessful": "Yext sale is successful",
    "yxtNotSold": "Yext not sold yet",
    "saasSubInactive": "SaaS subscription is inactive",
    "saasSubTrialing": "SaaS subscription is trialing",
    "saasSubActive": "SaaS subscription is active",
    "emailRebillingActive": "Email rebilling is active",
    "emailRebillingInactive": "Email rebilling is inactive",
    "twilioRebillingActive": "Twilio rebilling is active",
    "twilioRebillingInactive": "Twilio rebilling is inactive",
    "wpRebillingActive": "WhatsApp Rebilling is active",
    "wpRebillingInactive": "WhatsApp Rebilling is inactive",
    "saasModeActivated": "SaaS mode is activated",
    "paymentPending": "Payment is pending",
    "saasModeNotActivated": "SaaS mode is not activated",
    "managedByLCPhone": "Managed by LC Phone",
    "migrationToLCPhone": "Under migration to LC Phone",
    "notManagedByLCPhone": "Not managed by LC Phone",
    "managedByLCEmail": "Managed by LC Email",
    "notMangedByLCEmail": "Not managed by LC Email",
    "remainingWalletBalance": "Remaining wallet balance of this SaaS Location",
    "reports": "Reports",
    "switchToSubAccount": "Switch to Sub-Account",
    "manageClient": "Manage Client",
    "subAccountDeletedIfChangedMind": "This Sub-Account will be deleted on {time}, if you've changed your mind",
    "subAccountDeletedInMinute": "This Sub-Account will be deleted within the next minute",
    "changedMind": "if you've changed your mind",
    "clickHere": "Click here",
    "subAccountPaused": "This Sub-Account is paused. Your clients can't login but Agency can. To resume this account",
    "subAccountRestricted": "This Sub-Account is paused due to pending approval.",
    "subAccountVerificationPending": "This Sub-Account is paused due to pending email and phone verification from Sub-Account admin.",
    "subAccountDeclined": "This Sub-Account is paused due to declined approval request. You can still resume this Sub-Account from ",
    "here": "here.",
    "reviewAndResume": "to review and resume this Sub-Account.",
    "incomingCalls": "Incoming calls",
    "outgoingCalls": "Outgoing calls",
    "totalTalktime": "Total talktime",
    "incomingEmails": "Incoming emails",
    "outgoingEmails": "Outgoing emails",
    "incomingSms": "Incoming text messages",
    "outgoingSms": "Outgoing text messages",
    "contactsCreated": "Number of contacts created",
    "appointmentsBooked": "Number of appointments booked",
    "totalFormSubmissions": "Total form submissions",
    "totalSurveySubmissions": "Total survey submissions",
    "totalFacebookFormSubmissions": "Total Facebook form submissions",
    "incomingGMBMessages": "Incoming GBP Messages",
    "outgoingGMBMessages": "Outgoing GBP Messages",
    "gmbMessages": "GBP Messages",
    "fbMessages": "Facebook Messages",
    "incomingFbMessages": "Incoming Facebook messages",
    "outgoingFbMessages": "Outgoing Facebook messages",
    "igMessages": "Instagram Messages",
    "incomingIgMessages": "Incoming Instagram messages",
    "outgoingIgMessages": "Outgoing Instagram messages",
    "totalReviews": "Total reviews",
    "positiveReviews": "Positive reviews",
    "negativeReviews": "Negative reviews",
    "back": "Back",
    "selectDashboards": "Select dashboards",
    "next": "Next",
    "backToSubAccounts": "Back to Sub-Accounts",
    "pauseSchedule": "Pause schedule",
    "resumeSchedule": "Resume schedule",
    "editSchedule": "Edit Schedule",
    "addSchedule": "Add Schedule",
    "selectReportToAdd": "Select reports to add in the email",
    "selectAll": "Select all",
    "pauseScheduleConfirm": "Are you sure you want to pause the report schedule?",
    "resumeScheduleConfirm": "Are you sure you want to resume the report schedule?",
    "tasks": "Tasks",
    "conversations": "Conversations",
    "users": "Users",
    "dealOverview": "Deal overview",
    "confirmAction": "Confirm Action",
    "yes": "Yes",
    "no": "No",
    "reportDetails": "Report Details",
    "reportCreatedOn": "Report created on",
    "searchByEmail": "Search by email",
    "lastActivity": "Last Activity",
    "noRecords": "No Records Found",
    "scheduleReport": "Schedule Report",
    "schedule": "Schedule",
    "save": "Save",
    "emailSubject": "Email subject",
    "frequency": "Frequency",
    "daily": "Daily",
    "weekly": "Weekly",
    "monthly": "Monthly",
    "nextScheduleDate": "Next schedule date",
    "startingOn": "Starting on",
    "timeOfDay": "Time of day",
    "reportsToTimezone": "Report will be sent in the Sub-Account's timezone",
    "selectRecipients": "Select recipients",
    "invalidEmail": "You have entered an invalid email address",
    "testEmail": "Test email",
    "selectTestRecipients": "Select test recipients",
    "setupSubject": "Set up the subject above to test the report",
    "sendTest": "Send test",
    "scheduleSimilarSchedules": "Schedule similar report for other Sub-Accounts",
    "accountSummary": "Account Summary",
    "scheduleList": "Schedule List",
    "subAccountName": "Sub-Account Name",
    "nextRunDate": "Next Run Date",
    "reportSectionsAdded": "Report Sections Added",
    "status": "Status",
    "scheduleAction": "Schedule Action",
    "cancel": "Cancel",
    "pleaseWait": "Please Wait",
    "confirm": "Confirm",
    "booked": "Booked",
    "confirmed": "Confirmed",
    "showed": "Showed",
    "noShow": "No Show",
    "cancelled": "Cancelled",
    "unread": "Unread",
    "engagement": "Engagement",
    "reaction": "Reaction",
    "deliverySummary": "Delivery Summary",
    "successfulDelivery": "Successful Delivery",
    "opened": "Opened",
    "clicked": "Clicked",
    "replied": "Replied",
    "unsubscribed": "Un-subscribed",
    "complained": "Complained",
    "failed": "Failed",
    "open": "Open",
    "won": "Won",
    "abandoned": "Abandoned",
    "lost": "Lost",
    "sent": "Sent",
    "eject": "Eject",
    "delivered": "Delivered",
    "faceBookLeadForm": "Facebook Lead Form",
    "overdue": "Overdue",
    "reviewsReceived": "Reviews received",
    "formBuilders": "Form Builders",
    "switchToSaaS": "Switch to SaaS",
    "numberOfContacts": "Number of Contacts",
    "numberOfAppointments": "Number of Appointments",
    "outreach": "Outreach",
    "notSubAccountUser": "Not a Sub-Account user",
    "searchSubAccName": "Search by Sub-Account's name",
    "recipients": "Recipients",
    "scheduleCreated": "Email Report schedule created for ",
    "scheduleUpdated": "Email Report schedule updated for ",
    "scheduleNotCreated": "Email Report schedule could not be created for",
    "scheduleNotUpdated": "Email Report schedule could not be updated for",
    "total": "Total",
    "inbound": "Inbound",
    "outbound": "Outbound",
    "totalEmails": "Total Emails",
    "inboundEmails": "Inbound Emails",
    "outboundEmails": "Outbound Emails",
    "totalTextMessages": "Total Text messages",
    "inboundTextMessages": "Inbound Text messages",
    "outboundTextMessages": "Outbound Text messages",
    "totalCalls": "Total Calls",
    "inboundCalls": "Inbound Calls",
    "outboundCalls": "Outbound Calls",
    "faceBookLeadFormSubmissions": "Facebook Lead Form Submissions",
    "inboundGMBMessages": "Inbound GBP Messages",
    "outboundGMBMessages": "Outbound GBP Messages",
    "totalIgMessages": "Total Instagram Messages",
    "inboundIgMessages": "Inbound Instagram Messages",
    "outboundIgMessages": "Outbound Instagram Messages",
    "totalfbMessages": "Total Facebook Messages",
    "inboundfbMessages": "Inbound Facebook Messages",
    "outboundfbMessages": "Outbound Facebook Messages",
    "sitesCreatedFunnels": "Sites Created - Funnels",
    "sitesCreatedActiveFunnels": "Sites Created - Active Funnels",
    "sitesCreatedWebsites": "Sites Created - Websites",
    "sitesCreatedActiveWebsites": "Sites Created - Active Websites",
    "sitesCreatedForms": "Sites Created - Forms",
    "sitesCreatedActiveForms": "Sites Created - Active Forms",
    "sitesCreatedSurveys": "Sites Created - Surveys",
    "sitesCreatedActiveSurveys": "Sites Created - Active Surveys",
    "googleReviews": "Google Reviews",
    "facebookReviews": "Facebook Reviews",
    "inboundCallDuration": "Inbound Call Duration (hours)",
    "outboundCallDuration": "Outbound Call Duration (hours)",
    "pleaseSelect": "Please Select",
    "subAccountIsDeleted": "This Sub-Account has been deleted",
    "subAccountIsPaused": "This Sub-Account has been paused",
    "success": "Success",
    "error": "Error",
    "testEmailSent": "Test email sent",
    "testEmailNotSent": "Test email could not be sent",
    "reviewRequest": "Review Requests",
    "pendingTransferDescription": "Other agencies have requested to transfer Sub-Accounts to you.",
    "pendingTransferHeading": "You've got pending Sub-Account transfer requests.",
    "subAccountTransfer": "Sub-Account Transfers",
    "manageSubAccTransfer": "Manage your Sub-Account transfer requests",
    "subAccRequest": "Sub-Account {tabName} Requests",
    "receive": "Receive",
    "send": "Send",
    "release": "Release",
    "requests": "{count} Requests",
    "subAccLimit": "Sub-Accounts usage limit reached!",
    "subAccPlanLimit": "You have used 3/3 Sub-Accounts allowed under your current plan.",
    "upgradeTime": "It's time for an Upgrade!",
    "upgradeHigher": "Please upgrade to a higher plan to accept the Sub-Account transfer.",
    "browse": "Browse Plans",
    "pendingTransferTitle": "A transfer request for this Sub-Account is already pending.",
    "unableTransfer": "Unable to transfer Sub-Account to this Agency.",
    "unableEjectCancel": "Unable to cancel Sub-Account Eject Request",
    "EjectErrorDetails": "The Sub-Account eject request that you are trying to cancel can not be cancelled.",
    "hippaErrorDescription": "The Sub-Account that you are trying to transfer is HIPAA enabled, and can not be transferred to a non-HIPAA Agency.",
    "pendingTransferErrorDescription": "Kindly cancel the already existing transfer request for this Sub-Account before proceeding to raise a new one.",
    "receivingAgency": "Receiving Agency",
    "releasingAgency": "Releasing Agency",
    "noOfContacts": "No. of Contacts",
    "requestedDate": "Requested Date",
    "action": "Action",
    "cancelTransfer": "Cancel Transfer",
    "decline": "Decline",
    "cancelTransferRequest": "Cancel Sub-Account transfer request?",
    "cancelEjectRequest": "Cancel Sub-Account eject request?",
    "cancelRequestDescription": "The receiving Agency will no longer see this transfer request, and the Sub-Account {name} will not be transferred.",
    "cancelEjectRequestDescription": "The selected user will no longer be able to create new agency out of the Sub-Account {name}.",
    "declineRequest": "Decline Sub-Account transfer request?",
    "declineRequestDescription": "The request will be declined and the Sub-Account {name} won't be transferred to your Agency.",
    "approveTransfer": "Approve Sub-Account transfer to your Agency?",
    "approveTransferDescription": "{name} Sub-Account will be transferred to your Agency.",
    "transferNow": "Transfer Now",
    "cancelNow": "Cancel Now",
    "transferErrorDetails": "The Sub-Account that you are trying to transfer can not be transferred",
    "cancelRequest": "Cancel Request",
    "received": "Received",
    "approve": "Approve",
    "goBack": "Go Back",
    "done": "Done",
    "subAccountTransferred": "The Sub-Account has been successfully transferred!",
    "subAccountTransferDescription": "Please note that even though the Sub-Account has been transferred, some assets like users, phone numbers, Mailgun accounts, Yext listings etc. may still take 10-15 minutes to reflect in the destination.",
    "activeSubscriptionsAlert": "The location has active subscriptions. Please cancel all subscriptions before transferring the location. List of active subscriptions: {subscriptions}",
    "isSaasEnabled": "SaaS Enabled",
    "typeToSearch": "Type to search",
    "dateAdded": "Date Added",
    "emailEngagement": "Email Engagement",
    "emailReaction": "Email Reaction",
    "emailDeliverySummary": "Email Delivery Summary",
    "facebook": "Facebook",
    "sms": "SMS",
    "instagram": "Instagram",
    "accountSummarySubAccount": "Account Summary - {subAccountName}",
    "test": "Test",
    "scheduleExists": "Schedule Exists",
    "aToZ": "A - Z",
    "zToA": "Z - A",
    "onlyAdminUsersCanAddNewSubAccounts": "Only Admin users can add new sub-account's.",
    "locationName": "Location Name",
    "transferCompleted": "Transfer request for this location is already processed. Please refresh.",
    "unauthorizedTransfer": "You are not authorized to perform this action. Only agency owners can perform this action.",
    "bulkLocationTransfer": "Bulk Transfer Sub Accounts",
    "singleRequest": "Single Request",
    "bulkRequest": "Bulk Request",
    "backToLocationTransfer": "Back to Location Transfer",
    "initiateBulkTransfer": "Initiate Bulk Sub-account Transfer",
    "initiateBulkTransferDescription": "Please select the sub-accounts you wish to transfer or enter the IDs of the sub-accounts you wish to transfer, separated by commas.",
    "subAccountId": "Sub-Account ID",
    "selectFromList": "Select from List",
    "addManually": "Add Manually",
    "accountsSelected": "{count} Selected",
    "selectAllSubAccounts": "Select all",
    "removeAll": "Remove All",
    "addManualLocations": "Add Location id's with Separated by comma's",
    "manualAllLocationIds": "Location IDs of the sub-accounts you wish to transfer, separated by commas.",
    "enterLocationIds": "Enter Location ID's",
    "selectSubAccounts": "Select Sub-accounts And Transfer to an Existing Agency",
    "subAccountTransferWarning": "All sub-account assets and users will get transferred.",
    "proceed": "Proceed",
    "bulkRequestSubmitted": "Bulk Transfer Request Submitted",
    "bulkRequestSubmittedDescription": "Each sub-account will be transferred once the receiving agency accepts the transfer request. You can track the status of the request on the ❝{pagename}❞ page. (Provided they are eligible)",
    "bulkTransferRequestFailed": "Bulk Transfer Request Failed",
    "errorLocations": "Sub Accounts with error",
    "viewDetails": "View Details",
    "somethingWrong": "Something went wrong. Please try again.",
    "confirmBulkRequest": "Confirm Bulk Transfer request?",
    "bulkTransferAccounts": "Bulk Transfer '{count}' selected Sub accounts ?",
    "confirmDetails": "Please confirm details before transferring",
    "transferConsent": "I have read and understood the above guide and consent to all the limitations.",
    "transferInfo": "Sub Account transfer is subject to the receiving agency accepting it into their account.",
    "receivingRelNo": "Receiving Agency's Relationship Number",
    "enterRelNo": "Enter Relationship Number",
    "transferConfirmation": "Upon completing this action, the bulk transfer request will be sent to the receiving agency ({ relNo }). Each sub-account you've selected will be transferred once the receiving agency accepts it. Please note that this action is irreversible for all sub-accounts included in the transfer.",
    "confirmPassword": "Confirm by typing password below",
    "enterPassword": "Enter Password",
    "requestTransfer": "Request Transfer",
    "confirmTransfer": "Confirm Transfer",
    "notNow": "Not Now",
    "invalidRelNo": "Invalid Relationship Number",
    "invalidPassword": "Invalid password.",
    "searchSubAccounts": "Search Sub-Accounts",
    "searchSubAccountsId": "Search Sub-Account ID",
    "takeAction": "Take Action",
    "sub_account_details": "Sub Account Details",
    "no_of_contacts": "No of Contacts",
    "transfer_status": "Transfer Status",
    "error_info": "Error Info",
    "bulk_transfer_status": "Bulk Sub-account Transfer Status",
    "pending": "Pending",
    "processed": "Processed",
    "reject_all": "Reject All",
    "accept_all": "Accept All",
    "cancel_all": "Cancel All",
    "clearAll": "Clear All",
    "refresh": "Refresh",
    "rejected": "Rejected",
    "decline_transfer": "Decline Transfer",
    "decline_bulk_transfer": "Decline bulk sub-account transfer request ?",
    "decline_bulk_transfer_description": "The request will be declined and the Set of {length} sub-account won't be transferred to your agency.",
    "accept_bulk_transfer": "Accept bulk sub-account transfer request ?",
    "accept_bulk_transfer_description": "The request will be accepted and the Set of {length} sub-account will be transferred to your agency.",
    "cancel_bulk_transfer": "Cancel Bulk Transfer ?",
    "cancel_bulk_transfer_description": "Are you really sure you want cancel this bulk transfer of {length} Sub-account to “{name}”",
    "consent_sub_account_transfer": "Please read and consent to {link}",
    "sub_account_transfer_guidelines": "Sub-Account Transfer Guidelines",
    "valid_rel": "Enter valid relationship number",
    "something_went_wrong": "Something went wrong. Please try again.",
    "export": "Export",
    "export_success": "The data that you requested is being processed. You will receive an email with the data in some time.",
    "export_failed": "The data you requested could not be processed. Please try again later.",
    "selectAllError": "You can only select 1000 Sub accounts at a time.",
    "max1000": "(Max 1000)",
    "subAccountCount": "Sub-Accounts count" 
    }
