{
  "common" : {
    "thisWeek" : "Cette semaine",
    "lastWeek" : "La semaine dernière",
    "lastNumberOfDays" : "{numberOfDays} jours",
    "thisMonth" : "Ce mois-ci"
  },
  "subAccounts" : "sous-compte",
  "submitFeedback" : "Envoyer des commentaires",
  "createSubAccount" : "Créer un sous-compte",
  "viewScheduleReports" : "afficher les rapports programmé",
  "searchSubAccount" : "Rechercher via sous-compte",
  "columns" : "Colonnes",
  "sortBy" : "Trier par",
  "selectColumns" : "Sélectionner des colonnes",
  "maxColumns" : "8 colonnes au maximum à une Date",
  "applyColumns" : "Appliquer les colonnes",
  "activeUsers" : "utilisateurs actifs",
  "appointments" : "Rendez-vous",
  "contacts" : "Contacts",
  "emails" : "Gmail",
  "textMessages" : "messages de corps",
  "calls" : "appels",
  "submissions" : "Envois",
  "reviews" : "avis",
  "sitesCreated" : "Sites Créé",
  "name" : "Nom",
  "formSubmissions" : "Envois de Formulaire",
  "surveySubmissions" : "sondage",
  "facebookFormSubmissions" : "Envois de Formulaire Facebook",
  "funnels" : "entonnoirs",
  "websites" : "Sites web",
  "forms" : "Formulaires",
  "surveys" : "sondages",
  "highestFirst" : "maximum d'abord",
  "lowestFirst" : "en plus de",
  "orderBy" : "essai via",
  "noMoreLocations" : "Aucune adresse e-mail n'a été trouvée",
  "preparingReport" : "Nous chargement votre rapport",
  "preparingReportDesc" : "in pas renvoyer",
  "outOf" : "de",
  "subAccountsExported" : "Sous-compte exporté",
  "close" : "Fermer",
  "scheduledForDeletion" : "programmé pour la suppression",
  "readyToSell" : "prêt -vous vendre",
  "closedWon" : "fermé (remporté)",
  "wpSaleSuccessful" : "La vente de WordPress est réussie",
  "wpNotSold" : "WordPress n'est pas encore livré",
  "yxtSaleSuccessful" : "Oui, la vente est réussie",
  "yxtNotSold" : "Oui, il n'y a pas encore de vente",
  "saasSubInactive" : "L'abonnement SaaS est inactif",
  "saasSubTrialing" : "Abonnement SaaS en cours d'essai",
  "saasSubActive" : "L'abonnement SaaS est dynamique",
  "emailRebillingActive" : "La refacturation des adresses e-mail est dynamique",
  "emailRebillingInactive" : "La refacturation par E-mail est inactif",
  "twilioRebillingActive" : "La refacturation Twilio est dynamique",
  "twilioRebillingInactive" : "La refacturation Twilio est inactif",
  "wpRebillingActive" : "La refacturation WhatsApp est dynamique",
  "wpRebillingInactive" : "La refacturation WhatsApp est inactif",
  "saasModeActivated" : "Le mode SaaS est activé",
  "paymentPending" : "Le paiement est en attente",
  "saasModeNotActivated" : "Le mode SaaS n'est pas activé",
  "managedByLCPhone" : "gérer via ACH",
  "migrationToLCPhone" : "dans le cadre de la migration vers le Téléphone ACH",
  "notManagedByLCPhone" : "Ne pas gérer via ACH",
  "managedByLCEmail" : "gérer via LeadConnector Email System",
  "notMangedByLCEmail" : "Ne pas gérer LeadConnector Email System",
  "remainingWalletBalance" : "E-mail restant dans cet emplacement SaaS",
  "reports" : "Rapports",
  "switchToSubAccount" : "Passer au sous-compte",
  "manageClient" : "gérer le client",
  "subAccountDeletedIfChangedMind" : "Ce sous-compte sera supprimé de {time}, si vous avez dynamique",
  "subAccountDeletedInMinute" : "Ce sous-compte sera supprimé de la minutesuivant",
  "changedMind" : "si vous êtes dynamique",
  "clickHere" : "ici",
  "subAccountPaused" : "Ce sous-compte est en pause. Vos clients ne peuvent pas se connecter, mais agence peut. Pour CV ce compte",
  "subAccountRestricted" : "Ce sous-compte est en pause en raison de l'attente de l'approbation.",
  "subAccountVerificationPending" : "Ce sous-compte est en pause en raison de l'attente de la vérification de l'email et du téléphone par admin du sous-compte.",
  "subAccountDeclined" : "Ce sous-compte est en pause en raison du rejet des demandes d'approbation. Vous pouvez toujours envoyer un CV à ce sous-compte par ",
  "here" : "ici.",
  "reviewAndResume" : "pour avis et CV ce sous-compte.",
  "incomingCalls" : "Appels entrants",
  "outgoingCalls" : "Appels sortants",
  "totalTalktime" : "Temps de conversation total",
  "incomingEmails" : "Gmailentrant",
  "outgoingEmails" : "Gmailsortant",
  "incomingSms" : "Messages entrants",
  "outgoingSms" : "Messages de corps sortants",
  "contactsCreated" : "Nombre de amis",
  "appointmentsBooked" : "Nombre de rendez-vous réservés",
  "totalFormSubmissions" : "Nombre total d'envois de formulaires",
  "totalSurveySubmissions" : "Nombre total d'envois de sondages",
  "totalFacebookFormSubmissions" : "Nombre total d'envois de formulaires Facebook",
  "incomingGMBMessages" : "Messages GBP entrants",
  "outgoingGMBMessages" : "Messages GBP sortants",
  "gmbMessages" : "messages GBP",
  "fbMessages" : "Messages Facebook",
  "incomingFbMessages" : "Messages Facebook entrants",
  "outgoingFbMessages" : "Messages Facebook sortants",
  "igMessages" : "Instagram Messenger",
  "incomingIgMessages" : "Messages Instagram entrants",
  "outgoingIgMessages" : "Messages Instagram sortants",
  "totalReviews" : "Nombre total d'avis",
  "positiveReviews" : "E-mails actifs",
  "negativeReviews" : "évaluations négatives",
  "back" : "Retour",
  "selectDashboards" : "Sélectionner des tableau de bord",
  "next" : "Suivant",
  "backToSubAccounts" : "Retour au sous-compte",
  "pauseSchedule" : "Mettre le calendrier en mettre en pause",
  "resumeSchedule" : "CV le calendrier",
  "editSchedule" : "calendrier",
  "addSchedule" : "calendrier",
  "selectReportToAdd" : "Rapports à inclure in l'e-mail",
  "selectAll" : "chaque",
  "pauseScheduleConfirm" : "Voulez-vous vraiment mettre en pause le calendrier du rapport ?",
  "resumeScheduleConfirm" : "Voulez-vous vraiment le calendrier du rapport sur les CV ?",
  "tasks" : "Tâches",
  "conversations" : "Conversations",
  "users" : "utilisateur",
  "dealOverview" : "accord sur l'ensemble",
  "confirmAction" : "Confirmer Action",
  "yes" : "Oui",
  "no" : "Non",
  "reportDetails" : "informations sur le rapport",
  "reportCreatedOn" : "rapport créé",
  "searchByEmail" : "Rechercher via adresse e-mail",
  "lastActivity" : "activité",
  "noRecords" : "Aucun enregistrements trouvé",
  "scheduleReport" : "rapport sur calendrier",
  "schedule" : "calendrier",
  "save" : "Enregistrer",
  "emailSubject" : "ligne d'objet",
  "frequency" : "fréquence",
  "daily" : "Quotidiennement",
  "weekly" : "Hebdomadaire",
  "monthly" : "Mensuellement",
  "nextScheduleDate" : "Date du calendrier Suivant",
  "startingOn" : "Démarrage de votre",
  "timeOfDay" : "Date de jour",
  "reportsToTimezone" : "Le rapport sera envoyé in le fuseau horaire du sous-compte",
  "selectRecipients" : "Sélectionner les destinataires",
  "invalidEmail" : "Vous avez saisi une adresse e-mail non valide",
  "testEmail" : "E-mail de Test",
  "selectTestRecipients" : "destinataires de Test",
  "setupSubject" : "configurer le sujet ci-dessus pour Test le rapport",
  "sendTest" : "Test",
  "scheduleSimilarSchedules" : "rapport de calendrier similaire pour un autre sous-compte",
  "accountSummary" : "résumé du compte",
  "scheduleList" : "liste du calendrier",
  "subAccountName" : "Nom du sous-compte",
  "nextRunDate" : "Date de la Suivant exécution",
  "reportSectionsAdded" : "Segments de rapport ajoutés",
  "status" : "Statut",
  "scheduleAction" : "Actionen calendrier",
  "cancel" : "Annuler",
  "pleaseWait" : "Veuillez attendre",
  "confirm" : "Confirmer",
  "booked" : "réservé",
  "confirmed" : "Confirmé",
  "showed" : "Honoré",
  "noShow" : "absence",
  "cancelled" : "Annulé",
  "unread" : "Non lus",
  "engagement" : "engagement",
  "reaction" : "réponse",
  "deliverySummary" : "résumé de livraison",
  "successfulDelivery" : "livraison réussie",
  "opened" : "Ouvert",
  "clicked" : "Cliqué",
  "replied" : "Répondu",
  "unsubscribed" : "désinscrit",
  "complained" : "insatisfait",
  "failed" : "Échec",
  "open" : "Ouvert",
  "won" : "Remporté",
  "abandoned" : "Abandonné",
  "lost" : "Perdu",
  "sent" : "Envoyé",
  "eject" : "e-mail",
  "delivered" : "Envoyé",
  "faceBookLeadForm" : "Formulaire de prospect Facebook",
  "overdue" : "En retard",
  "reviewsReceived" : "Échec de l'analyse",
  "formBuilders" : "Générateur de Formulaire",
  "switchToSaaS" : "Passer à SaaS",
  "numberOfContacts" : "nombre d' amis",
  "numberOfAppointments" : "Nombre de rendez-vous",
  "outreach" : "extension",
  "notSubAccountUser" : "Pas un utilisateur de sous-compte",
  "searchSubAccName" : "Rechercher via le nom du sous-compte",
  "recipients" : "destinataires",
  "scheduleCreated" : "Rapport par e-mail créé pour ",
  "scheduleUpdated" : "calendrier de rapport par E-mail mis à jour pour ",
  "scheduleNotCreated" : "Le calendrier de rapport par e-mail n'a pas pu être créé pour",
  "scheduleNotUpdated" : "Le calendrier de rapport par E-mail n'a pas pu être mis à jour pour",
  "total" : "Total",
  "inbound" : "entrant",
  "outbound" : "sortant",
  "totalEmails" : "total Gmail",
  "inboundEmails" : "Gmailentrant",
  "outboundEmails" : "Gmail",
  "totalTextMessages" : "Nombre total de messages de corps",
  "inboundTextMessages" : "Messages entrants",
  "outboundTextMessages" : "messages de corps",
  "totalCalls" : "total d'appels",
  "inboundCalls" : "Appels entrants",
  "outboundCalls" : "sortant sortants",
  "faceBookLeadFormSubmissions" : "Envois de Formulaire de prospect Facebook",
  "inboundGMBMessages" : "Messages GBP entrants",
  "outboundGMBMessages" : "Messages GBP sortant",
  "totalIgMessages" : "total de messages Instagram",
  "inboundIgMessages" : "Instagram Messenger entrants",
  "outboundIgMessages" : "Instagram Messenger sortant",
  "totalfbMessages" : "total de messages Facebook",
  "inboundfbMessages" : "Facebook entrants",
  "outboundfbMessages" : "Messages Facebook sortant",
  "sitesCreatedFunnels" : "Sites Créé - entonnoirs",
  "sitesCreatedActiveFunnels" : "Sites web",
  "sitesCreatedWebsites" : "Sites Créé - Sites web",
  "sitesCreatedActiveWebsites" : "Sites web",
  "sitesCreatedForms" : "Sites Créé - Formulaires",
  "sitesCreatedActiveForms" : "Sites web",
  "sitesCreatedSurveys" : "Sites Créé - Sondage",
  "sitesCreatedActiveSurveys" : "Sites créés - sondages dynamiques",
  "googleReviews" : "avis Google",
  "facebookReviews" : "évaluations Facebook",
  "inboundCallDuration" : "Durée des appeler entrants (heure)",
  "outboundCallDuration" : "durée appeler (heure)",
  "pleaseSelect" : "Veuillez Sélectionner",
  "subAccountIsDeleted" : "Ce sous-compte a été supprimé",
  "subAccountIsPaused" : "Ce sous-compte a été en pause",
  "success" : "Bravo",
  "error" : "Erreur",
  "testEmailSent" : "E-mail de Test envoyé",
  "testEmailNotSent" : "E-mail de Test n'a pas pu être envoyé",
  "reviewRequest" : "demandes avis",
  "pendingTransferDescription" : "autre vue que l'agence a demandé de transmettre un sous-compte à vous.",
  "pendingTransferHeading" : "Vous avez des demandes en attente.",
  "subAccountTransfer" : "sous-compte",
  "manageSubAccTransfer" : "Gérer votre sous-compte pour éviter les demandes",
  "subAccRequest" : "demandes de sous-compte {tabName}",
  "receive" : "hériter",
  "send" : "Envoyer",
  "release" : "version",
  "requests" : "demandes de {count}",
  "subAccLimit" : "Utilisez le sous-compte pour l'utilisation maximale !",
  "subAccPlanLimit" : "Vous avez utilisé 3/3 sous-comptes autorisés sous votre plan en cours.",
  "upgradeTime" : "Il Date de une mise à niveau !",
  "upgradeHigher" : "Veuillez mettre à niveau le niveau vers un plan supérieur pour accepter le sous-compte.",
  "browse" : "Parcourez les forfaits",
  "pendingTransferTitle" : "Les demandes de ce sous-compte sont déjà en attente.",
  "unableTransfer" : "Impossible de transférer le sous-compte vers cette agence.",
  "unableEjectCancel" : "Impossible de annuler les demandes de sous-compte",
  "EjectErrorDetails" : "Les demandes de sous-compte que vous essayez de annuler ne peuvent pas être annulées.",
  "hippaErrorDescription" : "Le sous-compte que vous essayez de transmettre est informations d'API.",
  "pendingTransferErrorDescription" : "Veuillez annuler les demandes déjà en cours pour ce sous-compte avant de procéder à la suppression d'un nouveau.",
  "receivingAgency" : "agence refusé",
  "releasingAgency" : "agence",
  "noOfContacts" : "Non honoré",
  "requestedDate" : "Date demandée",
  "action" : "Action",
  "cancelTransfer" : "Annuler le transférer",
  "decline" : "refusé",
  "cancelTransferRequest" : "Annuler le sous-compte pour les demandes ?",
  "cancelEjectRequest" : "Annuler les demandes de sous-compte ?",
  "cancelRequestDescription" : "L'agence refusée ne verra plus ce chiffre d'affaires, et le sous-compte {name} ne sera pas télécharger.",
  "cancelEjectRequestDescription" : "Le Messager Interactif ne sera plus autorisé à créer une nouvelle agence dans le sous-compte {name}.",
  "declineRequest" : "Refuser les demandes de sous-compte ?",
  "declineRequestDescription" : "Les demandes seront refusées et le sous-compte {name} ne sera pas téléchargé vers votre agence.",
  "approveTransfer" : "Vous n'avez pas reçu de texte à traduire. Veuillez fournir le texte que vous souhaitez faire traduire en français.",
  "approveTransferDescription" : "{name} sous-compte sera téléchargé vers votre agence.",
  "transferNow" : "transférer maintenant",
  "cancelNow" : "Annuler maintenant",
  "transferErrorDetails" : "Le sous-compte que vous essayez de transmettre ne peut pas être téléchargé",
  "cancelRequest" : "Annuler les demandes",
  "received" : "reçu",
  "approve" : "approuver",
  "goBack" : "auteur",
  "done" : "Terminé",
  "subAccountTransferred" : "Le sous-compte a été téléchargé avec succès !",
  "subAccountTransferDescription" : "Veuillez noter que même si le sous-compte a été téléchargé, des actifs n'ont pas reçu de texte à traduire. Pourriez-vous s'il vous plaît fournir le texte que Mailgun souhaitez faire traduire en in ?",
  "activeSubscriptionsAlert" : "L'emplacement a un abonnement dynamique. Veuillez annuler chaque abonnement avant le transfert vers l'emplacement. Liste des abonnements dynamiques : {subscriptions}",
  "isSaasEnabled" : "SaaS activé",
  "typeToSearch" : "Saisissez votre terme pour rechercher",
  "dateAdded" : "Date d'ajout",
  "emailEngagement" : "E-mail' engagement",
  "emailReaction" : "Réaction par E-mail",
  "emailDeliverySummary" : "résumé de livraison par E-mail",
  "facebook" : "Facebook",
  "sms" : "SMS",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "résumé du compte - {subAccountName}",
  "test" : "Test",
  "scheduleExists" : "Le calendrier existe",
  "aToZ" : "a - z",
  "zToA" : "Zo",
  "onlyAdminUsersCanAddNewSubAccounts" : "Seul l'administrateur peut inclure les nouveaux sous-comptes.",
  "locationName" : "Nom du emplacement",
  "transferCompleted" : "Les demandes de cet emplacement sont déjà traitée. Veuillez actualiser.",
  "unauthorizedTransfer" : "Vous n'avez pas l'autorisation de réaliser cette Action. Seuls les propriétaires d'agence peuvent effectuer cette Action.",
  "bulkLocationTransfer" : "sous-compte en masse",
  "singleRequest" : "demandes du personnel",
  "bulkRequest" : "dans les demandes en masse",
  "backToLocationTransfer" : "Retour au transférer de emplacement",
  "initiateBulkTransfer" : "trouver en masse",
  "initiateBulkTransferDescription" : "Veuillez fournir le sous-compte que vous souhaitez transmettre via coms.",
  "subAccountId" : "Idde sous-compte",
  "selectFromList" : "Réserver de la liste",
  "addManually" : "ajouter manuellement",
  "accountsSelected" : "{count} sélectionné",
  "selectAllSubAccounts" : "chaque",
  "removeAll" : "chaque",
  "addManualLocations" : "ajouter Id d'emplacement avec les détails de",
  "manualAllLocationIds" : "ID d'emplacement du sous-compte que vous souhaitez transmettre, transmettre via coms.",
  "enterLocationIds" : "Entrez les Id de l'emplacement",
  "selectSubAccounts" : "Réserver un sous-compte et transmettre à une agence en cours",
  "subAccountTransferWarning" : "chaque sous-compte des actifs et des utilisateurs obtenir des téléchargements.",
  "proceed" : "poursuivre",
  "bulkRequestSubmitted" : "dans le traitement en masse des demandes soumises",
  "bulkRequestSubmittedDescription" : "sous-compte sera téléchargé une fois que l'agence donnée accepte les demandes. Vous pouvez suivre le Statut des enregistrements{pagename} pagesur la . (même s'ils sont valides)",
  "bulkTransferRequestFailed" : "dans les demandes en échec",
  "errorLocations" : "Sous-compte avec une erreur",
  "viewDetails" : "Afficher les informations",
  "somethingWrong" : "Une erreur s'est produite. Veuillez réessayer.",
  "confirmBulkRequest" : "Confirmer en masse les demandes ?",
  "bulkTransferAccounts" : "en {count}",
  "confirmDetails" : "Veuillez confirmer des informations avant le transfert de temps total",
  "transferConsent" : "J'ai lire et comprendre le guide et le consentement ci-dessus pour chaque limite.",
  "transferInfo" : "Le sous-compte est sujet à l'agence refusée de l'accepter dans leur compte.",
  "receivingRelNo" : "Nombre de liens de l'agence",
  "enterRelNo" : "Entrez le nombre de liens",
  "transferConfirmation" : "Lors de la réalisation de cette Action, l'envoi en masse des demandes sera envoyé à l'agence refusée ({ relNo }). sous-compte de vous Veuillez noter que cette Action est irréversible pour chaque sous-compte inclus in le traitement.",
  "confirmPassword" : "Confirmez en entrant password sous",
  "enterPassword" : "Entrez le mot de passe",
  "requestTransfer" : "demandes",
  "confirmTransfer" : "Confirmer le transférer",
  "notNow" : "Pas maintenant",
  "invalidRelNo" : "Nombre de liens non valides",
  "invalidPassword" : "password non valide.",
  "searchSubAccounts" : "rechercher sous-compte",
  "searchSubAccountsId" : "trouver Idde sous-compte",
  "takeAction" : "Entrer Action",
  "sub_account_details" : "sous- profil",
  "no_of_contacts" : "Aucun amis",
  "transfer_status" : "transférer le Statut",
  "error_info" : "informations sur les erreur",
  "bulk_transfer_status" : "en masse sous-compte concernant les statuts",
  "pending" : "En attente",
  "processed" : "Traité",
  "reject_all" : "refuser",
  "accept_all" : "Accepter chaque",
  "cancel_all" : "Annuler chaque",
  "clearAll" : "effacer chaque",
  "refresh" : "actualiser",
  "rejected" : "refusé",
  "decline_transfer" : "refuser",
  "decline_bulk_transfer" : "Refuser en masse les sous-comptes concernant les demandes ?",
  "decline_bulk_transfer_description" : "Les demandes seront refusées et la collection du sous-compte de {length} ne sera pas téléchargée par votre agence.",
  "accept_bulk_transfer" : "Acceptez les demandes en masse sous-comptes ?",
  "accept_bulk_transfer_description" : "Les demandes seront acceptés et la collection du sous-compte de {length} sera téléchargé vers votre agence.",
  "cancel_bulk_transfer" : "Annuler le transférer en masse ?",
  "cancel_bulk_transfer_description" : "Voulez-vous vraiment annuler {length} en masse {name}",
  "consent_sub_account_transfer" : "Veuillez lire et Consentement à {link}",
  "sub_account_transfer_guidelines" : "directives de transférer de sous-compte",
  "valid_rel" : "Saisissez un nombre de lien valide",
  "something_went_wrong" : "Une erreur s'est produite. Veuillez réessayer.",
  "export" : "Exporter",
  "export_success" : "Les données que vous avez demandées sont en cours de traitement. Vous transmettrez un e-mail avec les données in une Date.",
  "export_failed" : "Les données que vous avez demandées n'ont pas pu être traitée. Veuillez réessayer plus tard.",
  "selectAllError" : "Vous ne pouvez sélectionner que 1 000 sous-comptes à une Date.",
  "max1000" : "(1 000 maximum )",
  "subAccountCount" : "Nombre de sous-comptes"
}
