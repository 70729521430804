export const setupSentry = (
  Sentry: any,
  Vue: any,
  router: any,
  environment: string
) => {
  if (environment !== 'production') {
    return
  }
  Sentry.init({
    Vue,
    dsn: 'https://67d1aa677812eafdc15b1a375ab7cb63@sentry.easywebsitehosting.com/3',
    environment,
    integrations: [
      Sentry.metrics.metricsAggregatorIntegration(),
      Sentry.browserTracingIntegration({
        router,
        enableInp: true,
        shouldCreateSpanForRequest: (url: string) => {
          return (
            url.includes('leadconnectorhq.com') || url.includes('msgsndr.com')
          )
        },
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.moduleMetadataIntegration(),
    ],
    allowUrls: [/https?:\/\/((static|appcdn)\.)?leadconnectorhq\.com/],
    sampleRate: 1, // samples are picked randomly
    tracesSampleRate: 0.1, // picked randomly, starting with bare minimum
    logErrors: true,
    trackComponents: true,
    tracePropagationTargets: [/https?:\/\/(.*)?leadconnectorhq\.com/],
  })
}

export interface SentryEvent {
  locationId: string
  locale: string
  email?: string
  userId?: string
  companyId?: string
}

export function setSentryMeta(Sentry: any, event: SentryEvent) {
  try {
    Sentry.setTags({
      locationId: event.locationId,
      locale: event.locale,
      companyId: event.companyId,
    })

    if (event?.email && event?.userId) {
      Sentry.setUser({ id: event.userId, email: event.email })
    } else {
      Sentry.setUser({})
    }
  } catch (err) {
    Sentry.captureMessage(
      `Issue while setting sentry meta '${err}', please change to use hyphen '-'.`
    )
  }
}
