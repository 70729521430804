<template>
  <div class="mt-11 px-3">
    <div
      id="location-transfer__btn-back-to-account"
      type="default"
      class="font-bold"
      @click="$emit('back')"
    >
      <UITextSmMedium
        class="inline-flex cursor-pointer content-center items-center font-bold text-blue-500"
      >
        <ArrowLeftIcon class="mr-3 h-5 w-5" />
        {{ $t('backToLocationTransfer') }}
      </UITextSmMedium>
    </div>
    <div v-if="tableDetails.loading" class="!mt-2">
      <UISkeleton height="90px" :sharp="false"></UISkeleton>
    </div>
    <div
      v-else
      class="!mt-2 flex space-x-2 rounded-lg border border-solid border-gray-200 bg-white p-4"
    >
      <!-- circular progress -->
      <div class="relative flex h-16 w-16 items-center justify-center">
        <svg class="h-full w-full -rotate-90 transform" viewBox="0 0 36 36">
          <!-- Outer gray circle (background) -->
          <circle
            cx="18"
            cy="18"
            r="14"
            stroke="currentColor"
            stroke-width="8"
            class="text-gray-200"
            fill="none"
          ></circle>
          <!--  progress circle -->
          <circle
            cx="18"
            cy="18"
            r="14"
            stroke="currentColor"
            stroke-width="8"
            stroke-dasharray="100"
            :stroke-dashoffset="
              ((tableDetails.totalCount - tableDetails.totalProcessed) /
                tableDetails.totalCount) *
              100
            "
            class="text-[#475467]"
            fill="none"
          ></circle>
        </svg>
      </div>
      <div class="px-2">
        <div class="text-md font-semibold">
          {{ $t('bulk_transfer_status') }}
        </div>
        <div class="!pt-2 text-2xl font-semibold">
          {{ tableDetails.totalProcessed }} / {{ tableDetails.totalCount }}
        </div>
      </div>
      <div
        v-if="status === BulkTransferStatus.PENDING"
        class="flex !h-[22px] space-x-2 rounded-3xl bg-warning-50 px-3"
      >
        <Hourglass01Icon class="!my-auto flex h-3 w-3 text-warning-500" />
        <div class="text-sm font-medium text-warning-500">
          {{ $t('pending') }}
        </div>
      </div>
      <div
        v-if="status === BulkTransferStatus.PROCESSED"
        class="flex !h-[22px] space-x-2 rounded-3xl bg-success-50 px-3"
      >
        <CheckIcon class="!my-auto flex h-3 w-3 text-success-500" />
        <div class="text-sm font-medium text-success-500">
          {{ $t('processed') }}
        </div>
      </div>
      <div
        v-if="
          status === BulkTransferStatus.REJECTED ||
          status === BulkTransferStatus.CANCELED
        "
        class="flex !h-[22px] space-x-2 rounded-3xl bg-error-50 px-3"
      >
        <SlashOctagonIcon
          v-if="status === BulkTransferStatus.REJECTED"
          class="!my-auto flex h-3 w-3 text-error-500"
        />
        <XCloseIcon
          v-if="status === BulkTransferStatus.CANCELED"
          class="!my-auto flex h-3 w-3 text-error-500"
        />
        <div class="text-sm font-medium text-error-500">
          {{
            status === BulkTransferStatus.REJECTED
              ? $t('rejected')
              : $t('cancelled')
          }}
        </div>
      </div>
      <div
        v-if="
          tab === 'received' &&
          status === BulkTransferStatus.PENDING &&
          !actionSuccess
        "
        class="!ml-auto flex space-x-2"
      >
        <UIButton
          id="reject-bulk"
          type="error"
          @click="showConfirmationModal('reject')"
        >
          {{ $t('reject_all') }}</UIButton
        >
        <UIButton
          id="accept-bulk"
          type="primary"
          @click="showConfirmationModal('accept')"
        >
          {{ $t('accept_all') }}</UIButton
        >
      </div>
      <div
        v-else-if="
          tab === 'sent' &&
          status === BulkTransferStatus.PENDING &&
          !actionSuccess
        "
        class="!ml-auto flex space-x-2"
      >
        <UIButton
          id="cancel-bulk"
          type="error"
          @click="showConfirmationModal('cancel')"
        >
          {{ $t('cancel_all') }}</UIButton
        >
      </div>
    </div>
    <div id="logs-table" class="mt-4">
      <UITable
        id="transfer-request-logs-table"
        v-model:search-text="tableDetails.searchText"
        class="mt-16 flex content-center items-center justify-center text-center"
        :columns="tableDetails.tableHeading"
        :data="tableDetails.data"
        :striped="true"
        :enable-filter="false"
        :loading="tableDetails.loading"
        :page-count="tableDetails.totalPages"
        :page="tableDetails.currentPage"
        :search-input-placeholder="$t('searchSubAccounts')"
        :enable-text-search="true"
        @update:page="pageChange"
        @update:search-text="searchInputChange"
      >
        <template #filter-right>
          <UIButton
            id="TransactionExportButton"
            :loading="buttonLoading"
            @click="exportData"
          >
            <DownloadCloud02Icon class="h-5 w-5"></DownloadCloud02Icon>
            <span class="!ml-2">{{ t('export') }}</span>
          </UIButton>
        </template>
      </UITable>
    </div>
  </div>

  <UIModal v-model:show="confirmationModal.show" :width="400">
    <template #header>
      <UIModalHeader
        id="modal-header"
        :type="confirmationModal.type"
        :icon="confirmationModal.icon"
        @close="confirmationModal.show = false"
      >
      </UIModalHeader>
    </template>
    <div class="text-lg font-semibold">
      {{ confirmationModal.title }}
    </div>
    <div class="font-regular pt-2 text-sm text-gray-600">
      {{ confirmationModal.message }}
    </div>
    <template #footer>
      <UIModalFooter
        v-if="confirmationModal.action === 'export'"
        id="modal-footer"
        :positive-text="$t('done')"
        type="default"
        @positive-click="confirmationModal.show = false"
      >
      </UIModalFooter>
      <UIModalFooter
        v-else
        id="modal-footer"
        :positive-text="confirmationModal.positiveButtonText"
        :type="confirmationModal.type"
        :negative-text="confirmationModal.negativeButtonText"
        :loading="confirmationLoading"
        @negative-click="confirmationModal.show = false"
        @positive-click="replyBulk"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>

<script setup>
import {
  BulkTransferStatus,
  TransferStatus,
} from '@/components/locationTransfer/Transfer.const';
import { useTable } from '@/composables/useTable';
import { InternalToolsService } from '@/services/InternalToolsService';
import { useAppStore } from '@/store/app';
import {
  AlertCircleIcon,
  ArrowLeftIcon,
  CheckCircleIcon,
  CheckIcon,
  DownloadCloud02Icon,
  Hourglass01Icon,
  SlashOctagonIcon,
  XCloseIcon,
} from '@gohighlevel/ghl-icons/24/outline';
import {
  UIButton,
  UIModal,
  UIModalFooter,
  UIModalHeader,
  UISkeleton,
  UITable,
  UITag,
  UITextSmMedium,
} from '@gohighlevel/ghl-ui';
import { debounce } from 'lodash';
import { h, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { renderNameAndEmail, renderNameEmailAndLogo, renderDate, renderEject } =
  useTable();
const { t } = useI18n();
const appStore = useAppStore();
const emit = defineEmits(['back']);
const actionSuccess = ref(false);
const props = defineProps({
  bulkDetails: { type: Object, required: true },
  tab: { type: String, required: true }, //received, sent, eject
  status: {
    type: BulkTransferStatus,
    required: true,
    default: BulkTransferStatus.PENDING,
  },

  bulkType: { type: String, required: true }, //transfer or eject
});
const companyId = ref('');
const buttonLoading = ref(false);
const confirmationLoading = ref(false);

const defaultConfirmationValues = {
  show: false,
  type: 'default',
  title: '',
  message: '',
  action: '',
  icon: SlashOctagonIcon,
  negativeButtonText: t('back'),
  positiveButtonText: t('confirmTransfer'),
  positiveButtonType: 'primary',
}
const confirmationModal = ref(defaultConfirmationValues);
const tableDetails = ref({
  tableHeading: [
    {
      title: t('sub_account_details'),
      key: 'subAccountDetails',
      render(row) {
        return renderNameAndEmail(row.subAccountDetails);
      },
    },
    { title: t('no_of_contacts'), key: 'contactsCount' },
    {
      title: t('error_info'),
      key: 'errorDetails',
      render(row) {
        return row.errorDetails ? row.errorDetails.message : '';
      },
    },
    {
      title: t('transfer_status'),
      key: 'status',
      render(row) {
        let type = 'default';
        switch (row.status) {
          case TransferStatus.COMPLETED:
            type = 'success';
            break;
          case TransferStatus.CANCELED:
            type = 'warning';
            break;
          case TransferStatus.FAILED:
            type = 'warning';
            break;
          case TransferStatus.REJECTED:
            type = 'error';
            break;
          default:
            type = 'default';
        }
        return h(
          UITag,
          { id: 'request-status', type: type, round: true, bordered: false },
          {
            default: () =>
              row.status.charAt(0).toUpperCase() + row.status.slice(1),
          }
        );
      },
    },
  ],
  data: [],
  pageSize: 10,
  totalCount: 0,
  currentPage: 1,
  totalPages: 0,
  loading: false,
  totalProcessed: 0,
  searchText: '',
});

onMounted(() => {
  updateCompanyId();
  fetchData();
});

function updateCompanyId() {
  if (appStore?.companyId) {
    companyId.value = appStore.companyId;
  } else {
    return setTimeout(() => {
      updateCompanyId();
    }, 500);
  }
}

const debounceFetch = debounce(() => {
  fetchData();
}, 500);

function searchInputChange(value) {
  tableDetails.value.searchText = value;
  debounceFetch();
}

async function fetchData() {
  try {
    tableDetails.value.loading = true;
    const params = {
      companyId: companyId.value,
      limit: tableDetails.value.pageSize,
      skip: (tableDetails.value.currentPage - 1) * tableDetails.value.pageSize,
    };
    if (tableDetails.value.searchText) {
      params.searchValue = tableDetails.value.searchText;
    }
    const { data } = await InternalToolsService.fetchBulkTransferById(
      props.bulkDetails._id,
      params
    );

    tableDetails.value.data = data.transferData;
    tableDetails.value.totalCount = data.count;
    tableDetails.value.totalPages = Math.ceil(
      data.count / tableDetails.value.pageSize
    );

    tableDetails.value.totalProcessed = data.processed
  } catch (e) {
    console.error(e);
  } finally {
    tableDetails.value.loading = false;
  }
}

async function showConfirmationModal(action) {
  if (action === 'reject') {
    confirmationModal.value.show = true;
    confirmationModal.value.type = 'error';
    confirmationModal.value.title = t('decline_bulk_transfer')
    confirmationModal.value.message = t('decline_bulk_transfer_description', {
      length: props.bulkDetails.subAccountIds.length,
    })
    confirmationModal.value.action = 'reject';
    confirmationModal.value.positiveButtonText = t('decline_transfer');
    confirmationModal.value.positiveButtonType = 'error';
    confirmationModal.value.icon = AlertCircleIcon
  }
  if (action === 'accept') {
    confirmationModal.value.show = true;
    confirmationModal.value.type = 'success';
    confirmationModal.value.title = t('accept_bulk_transfer');
    confirmationModal.value.message = t('accept_bulk_transfer_description', {
      length: props.bulkDetails.subAccountIds.length,
    })
    confirmationModal.value.positiveButtonText = t('confirmTransfer')
    confirmationModal.value.action = 'accept';
    confirmationModal.value.icon = CheckCircleIcon
  }
  if (action === 'cancel') {
    confirmationModal.value.show = true;
    confirmationModal.value.type = 'error';
    confirmationModal.value.title = t('cancel_bulk_transfer');
    confirmationModal.value.message = t('cancel_bulk_transfer_description', {
      length: props.bulkDetails.subAccountIds.length,
      name: props.bulkDetails.receivingCompanyDetails.name,
    })
    confirmationModal.value.action = 'cancel'
    confirmationModal.value.positiveButtonText = t('cancelTransfer')
    confirmationModal.value.positiveButtonType = 'error';
    confirmationModal.value.icon = XCloseIcon
  }
}

async function replyBulk() {
  try {
    confirmationLoading.value = true;
    const action = confirmationModal.value.action;
    await InternalToolsService.replyBulkTransferRequest(props.bulkDetails._id, {
      reply: action,
      companyId: companyId.value,
    })
    actionSuccess.value = true;
    emit('back', true)
  } catch (e) {
  } finally {
    confirmationLoading.value = false;
    fetchData();
  }
}

async function exportData() {
  try {
    buttonLoading.value = true;
    await InternalToolsService.exportBulkData(
      companyId.value,
      props.bulkDetails._id
    )
    confirmationModal.value.show = true;
    confirmationModal.value.type = 'success';
    confirmationModal.value.title = t('success');
    confirmationModal.value.message = t('export_success')
    confirmationModal.value.positiveButtonText = t('done')
    confirmationModal.value.action = 'export';
    confirmationModal.value.icon = CheckCircleIcon
  } catch (e) {
    console.error(e);
    confirmationModal.value.show = true;
    confirmationModal.value.type = 'error';
    confirmationModal.value.title = t('error')
    confirmationModal.value.message = t('export_error')
    confirmationModal.value.action = 'export';
    confirmationModal.value.icon = AlertCircleIcon
  } finally {
    buttonLoading.value = false
  }
}

async function refresh() {
  fetchData();
}
</script>

<style scoped></style>
