{
  "common" : {
    "thisWeek" : "Esta semana",
    "lastWeek" : "Semana passada",
    "lastNumberOfDays" : "últimos {numberOfDays} dias",
    "thisMonth" : "Este mês"
  },
  "subAccounts" : "Subconta",
  "submitFeedback" : "Enviar feedback",
  "createSubAccount" : "Criar subconta",
  "viewScheduleReports" : "Visualizar relatórios Programado",
  "searchSubAccount" : "Pesquisar Via subconta",
  "columns" : "Colunas",
  "sortBy" : "Ordenar por",
  "selectColumns" : "Selecionar colunas",
  "maxColumns" : "Máximo de 8 colunas em um dado",
  "applyColumns" : "Aplicar colunas",
  "activeUsers" : "Usuários ativos",
  "appointments" : "Compromissos",
  "contacts" : "Contatos",
  "emails" : "Gmail",
  "textMessages" : "Mensagens do corpo",
  "calls" : "Ligações",
  "submissions" : "Envios",
  "reviews" : "Avaliações",
  "sitesCreated" : "Sites Criado",
  "name" : "Nome",
  "formSubmissions" : "Envios de Formulário",
  "surveySubmissions" : "Envios de Pesquisa",
  "facebookFormSubmissions" : "Envios de Formulário do Facebook",
  "funnels" : "Funis",
  "websites" : "Sites",
  "forms" : "Formulários",
  "surveys" : "Pesquisas",
  "highestFirst" : "Máximo primeiro",
  "lowestFirst" : "Começar pelo menor",
  "orderBy" : "Ordenar Via",
  "noMoreLocations" : "Nenhum endereço mais encontrado",
  "preparingReport" : "Estamos carregando seu relatório",
  "preparingReportDesc" : "Não fechar ou atualizar esta janela enquanto a exportação estiver in andamento",
  "outOf" : "De",
  "subAccountsExported" : "Subconta exportada",
  "close" : "Fechar",
  "scheduledForDeletion" : "Programado para exclusão",
  "readyToSell" : "Pronto para vender",
  "closedWon" : "Fechado (Ganho)",
  "wpSaleSuccessful" : "A venda do WordPress foi bem-sucedido",
  "wpNotSold" : "O WordPress ainda não foi vendido",
  "yxtSaleSuccessful" : "A venda do Yext é sucesso",
  "yxtNotSold" : "Yext ainda não foi vendido",
  "saasSubInactive" : "SaaS Assinatura é inativo",
  "saasSubTrialing" : "SaaS Assinatura está em teste",
  "saasSubActive" : "SaaS Assinatura é Dinâmico",
  "emailRebillingActive" : "O refaturamento de e-mail é Dinâmico",
  "emailRebillingInactive" : "O refaturamento de e-mail é inativo",
  "twilioRebillingActive" : "O refaturamento Twilio é Dinâmico",
  "twilioRebillingInactive" : "O refaturamento Twilio é inativo",
  "wpRebillingActive" : "O refaturamento WhatsApp é Dinâmico",
  "wpRebillingInactive" : "O refaturamento WhatsApp é inativo",
  "saasModeActivated" : "O modo SaaS está Habilitado",
  "paymentPending" : "O pagamento está pendente",
  "saasModeNotActivated" : "O modo SaaS não é Habilitado",
  "managedByLCPhone" : "Gerenciar Via LC Telefone",
  "migrationToLCPhone" : "Em migração para o Telefone LC",
  "notManagedByLCPhone" : "Não gerenciar Via LC Telefone",
  "managedByLCEmail" : "Gerenciar o LeadConnector Email System",
  "notMangedByLCEmail" : "Não gerenciar Via LeadConnector Email System",
  "remainingWalletBalance" : "Carteira restante Saldo desta localização SaaS",
  "reports" : "Relatórios",
  "switchToSubAccount" : "Mudar para Subconta",
  "manageClient" : "Gerenciar cliente",
  "subAccountDeletedIfChangedMind" : "Esta subconta será Apagado Ativado {time}, Se Você've Dinâmico sua mente",
  "subAccountDeletedInMinute" : "Esta Subconta será Apagada no Próximo minuto",
  "changedMind" : "Se Você tem Dinâmico sua mente",
  "clickHere" : "clicar aqui",
  "subAccountPaused" : "Esta subconta está pausada. Seus clientes não podem Login , mas a Agência pode. Para continuar esta conta",
  "subAccountRestricted" : "Esta subconta está pausada devido a uma aprovação pendente.",
  "subAccountVerificationPending" : "Esta subconta está pausada devido a e-mail pendente e verificação de telefone De administrador da subconta.",
  "subAccountDeclined" : "Esta subconta está pausada devido à Aprovação Solicitações recusada. Você ainda pode continuar esta subconta de ",
  "here" : "aqui.",
  "reviewAndResume" : "para a Avaliação e continuar esta Sub-conta.",
  "incomingCalls" : "Ligações recebidas",
  "outgoingCalls" : "Ligações efetuadas",
  "totalTalktime" : "Tempo de conversa Total",
  "incomingEmails" : "Gmailrecebido",
  "outgoingEmails" : "Gmailenviado",
  "incomingSms" : "Mensagens recebidas do Corpo",
  "outgoingSms" : "Mensagens enviadas do Corpo",
  "contactsCreated" : "número de Amigos Criados",
  "appointmentsBooked" : "número de compromisso agendado",
  "totalFormSubmissions" : "Total de envios de formulários",
  "totalSurveySubmissions" : "Total de envios de pesquisas",
  "totalFacebookFormSubmissions" : "Total de envios de formulários do Facebook",
  "incomingGMBMessages" : "Mensagens GBP recebidas",
  "outgoingGMBMessages" : "Mensagens GBP enviadas",
  "gmbMessages" : "Mensagens GBP",
  "fbMessages" : "Mensagens do Facebook",
  "incomingFbMessages" : "Mensagens recebidas do Facebook",
  "outgoingFbMessages" : "Mensagens enviadas do Facebook",
  "igMessages" : "Mensagens do Instagram",
  "incomingIgMessages" : "Mensagens recebidas do Instagram",
  "outgoingIgMessages" : "Mensagens enviadas do Instagram",
  "totalReviews" : "Total de avaliações",
  "positiveReviews" : "Avaliações Positivas",
  "negativeReviews" : "Avaliações Negativas",
  "back" : "Voltar",
  "selectDashboards" : "Selecionar painéis",
  "next" : "Seguinte",
  "backToSubAccounts" : "Voltar para subconta",
  "pauseSchedule" : "Pausar programação",
  "resumeSchedule" : "Continuar programação",
  "editSchedule" : "Editar programação",
  "addSchedule" : "Adicionar programação",
  "selectReportToAdd" : "Selecionar relatórios para Incluir in e-mail",
  "selectAll" : "selecionar todos",
  "pauseScheduleConfirm" : "Tem certeza de que deseja pausar a programação do relatório?",
  "resumeScheduleConfirm" : "Tem certeza de que deseja continuar a programação do relatório?",
  "tasks" : "Tarefas",
  "conversations" : "Conversas",
  "users" : "Usuário",
  "dealOverview" : "Visão geral do Acordo",
  "confirmAction" : "Confirmar Ação",
  "yes" : "Sim",
  "no" : "Não",
  "reportDetails" : "Detalhes do relatório",
  "reportCreatedOn" : "Relatório criado ativado",
  "searchByEmail" : "Pesquisar por e-mail",
  "lastActivity" : "último Atividade",
  "noRecords" : "Nenhum Registros encontrado",
  "scheduleReport" : "Relatório de programação",
  "schedule" : "programação",
  "save" : "Salvar",
  "emailSubject" : "Linha de assunto",
  "frequency" : "Frequência",
  "daily" : "Diário",
  "weekly" : "Semanalmente",
  "monthly" : "Mensalmente",
  "nextScheduleDate" : "Próximo programação Dados",
  "startingOn" : "Começando ativado",
  "timeOfDay" : "Data de dia",
  "reportsToTimezone" : "O relatório será enviado in fuso horário da Subconta",
  "selectRecipients" : "Selecionar destinatários",
  "invalidEmail" : "Você adicionou um endereço de e-mail inválido",
  "testEmail" : "E-mail de Testar",
  "selectTestRecipients" : "selecionar destinatários Testar",
  "setupSubject" : "Configuração do assunto acima para Testar o relatório",
  "sendTest" : "Carregar Testar",
  "scheduleSimilarSchedules" : "programação de relatório semelhante para Outro Sub-Conta",
  "accountSummary" : "Resumo da Conta",
  "scheduleList" : "programação Lista",
  "subAccountName" : "Nome da subconta",
  "nextRunDate" : "Próximo Data de execução",
  "reportSectionsAdded" : "Segmentos de relatório adicionados",
  "status" : "Status",
  "scheduleAction" : "programação Ação",
  "cancel" : "Cancelar",
  "pleaseWait" : "Esperar",
  "confirm" : "Confirmar",
  "booked" : "Agendado",
  "confirmed" : "Confirmado",
  "showed" : "Comparecido",
  "noShow" : "Não comparecimento",
  "cancelled" : "Cancelado",
  "unread" : "Não lido",
  "engagement" : "Engajamento",
  "reaction" : "Reação",
  "deliverySummary" : "Resumo da Entrega",
  "successfulDelivery" : "Entrega com sucesso",
  "opened" : "Aberto",
  "clicked" : "Clicado",
  "replied" : "Respondidas",
  "unsubscribed" : "Inscrição cancelada",
  "complained" : "Reclamou",
  "failed" : "Falhou",
  "open" : "Abrir",
  "won" : "Ganho",
  "abandoned" : "Abandonado",
  "lost" : "Perdido",
  "sent" : "Enviado",
  "eject" : "Ejetar",
  "delivered" : "Entregue",
  "faceBookLeadForm" : "Formulário de leads do Facebook",
  "overdue" : "Atrasada",
  "reviewsReceived" : "Avaliações recebidas",
  "formBuilders" : "Construtor de Formulário",
  "switchToSaaS" : "Mudar para SaaS",
  "numberOfContacts" : "número de convidados",
  "numberOfAppointments" : "número de compromisso",
  "outreach" : "Contato",
  "notSubAccountUser" : "Não é um usuário de subconta",
  "searchSubAccName" : "Pesquisar Via nome da subconta",
  "recipients" : "Destinatários",
  "scheduleCreated" : "Programação de relatórios por e-mail Criado para ",
  "scheduleUpdated" : "Programação de relatórios por e-mail Atualizado para ",
  "scheduleNotCreated" : "A programação do relatório por e-mail não pôde ser criada para",
  "scheduleNotUpdated" : "A programação do relatório por e-mail não pôde ser Atualizado para",
  "total" : "Total",
  "inbound" : "Recebidas",
  "outbound" : "Enviadas",
  "totalEmails" : "Total Gmail",
  "inboundEmails" : "Gmailde entrada",
  "outboundEmails" : "Enviadas Gmail",
  "totalTextMessages" : "Total de mensagens do Corpo",
  "inboundTextMessages" : "Mensagens recebidas do Corpo",
  "outboundTextMessages" : "Mensagens do Enviadas Corpo",
  "totalCalls" : "Total de ligações",
  "inboundCalls" : "Chamadas recebidas",
  "outboundCalls" : "Ligações Enviadas",
  "faceBookLeadFormSubmissions" : "Envios de Formulário de leads do Facebook",
  "inboundGMBMessages" : "Mensagens GBP de entrada",
  "outboundGMBMessages" : "Mensagens GBP Enviadas",
  "totalIgMessages" : "Total de mensagens do Instagram",
  "inboundIgMessages" : "Mensagens recebidas do Instagram",
  "outboundIgMessages" : "Mensagens Enviadas do Instagram",
  "totalfbMessages" : "Total de mensagens do Facebook",
  "inboundfbMessages" : "Mensagens recebidas do Facebook",
  "outboundfbMessages" : "Mensagens Enviadas do Facebook",
  "sitesCreatedFunnels" : "Sites Criado - Funis",
  "sitesCreatedActiveFunnels" : "Sites criados - Dinâmico Funis",
  "sitesCreatedWebsites" : "Sites Criado - Sites",
  "sitesCreatedActiveWebsites" : "Sites criados - Sites Dinâmico",
  "sitesCreatedForms" : "Sites Criado - Formulários",
  "sitesCreatedActiveForms" : "Sites criados - Formulários Dinâmicos",
  "sitesCreatedSurveys" : "Sites Criado - Pesquisas",
  "sitesCreatedActiveSurveys" : "Sites criados - Pesquisas dinâmicas",
  "googleReviews" : "Google Avaliações",
  "facebookReviews" : "Avaliações do Facebook",
  "inboundCallDuration" : "Duração da ligação de entrada (hora)",
  "outboundCallDuration" : "Duração da ligação de Enviadas (hora)",
  "pleaseSelect" : "Selecionar",
  "subAccountIsDeleted" : "Esta subconta foi apagada",
  "subAccountIsPaused" : "Esta subconta foi pausada",
  "success" : "Sucesso",
  "error" : "Erro",
  "testEmailSent" : "E-mail de Testar enviado",
  "testEmailNotSent" : "O e-mail do Testar não pôde ser enviado",
  "reviewRequest" : "Solicitações de Avaliação",
  "pendingTransferDescription" : "A outra exibição da agência solicitará a transferência da subconta para Você.",
  "pendingTransferHeading" : "Você tem solicitações de transferência de subconta pendente.",
  "subAccountTransfer" : "Subconta baixar",
  "manageSubAccTransfer" : "Gerenciar suas solicitações de transferência de subcontas",
  "subAccRequest" : "Subconta {tabName} Solicitações",
  "receive" : "Herdar",
  "send" : "Enviar",
  "release" : "Versão",
  "requests" : "{count} Solicitações",
  "subAccLimit" : "Usar subcontas Limite de intervalos atingido!",
  "subAccPlanLimit" : "Você usado 3/3 Subcontas permitidas no seu Plano Atual.",
  "upgradeTime" : "São dados para uma atualização da Fazer!",
  "upgradeHigher" : "Faça upgrade para um plano maior para aceitar a transferência de subconta.",
  "browse" : "Pesquisar planos",
  "pendingTransferTitle" : "Uma transferência de solicitações para esta subconta já está pendente.",
  "unableTransfer" : "Não foi possível baixar a subconta para esta agência.",
  "unableEjectCancel" : "Não é possível cancelar as solicitações de ejeção da subconta",
  "EjectErrorDetails" : "As Solicitações ejetadas da Subconta que Você está tentando cancelar não podem ser canceladas.",
  "hippaErrorDescription" : "A subconta que Você está tentando Transferir é HIPAA Habilitado e não pode ser Baixar para uma agência não HIPAA.",
  "pendingTransferErrorDescription" : "Cancele as solicitações de transferência já atuais para esta subconta antes de prosseguir para Elevar um novo.",
  "receivingAgency" : "Agência recebida",
  "releasingAgency" : "Emmitido Agência",
  "noOfContacts" : "Nº de Amigos",
  "requestedDate" : "Data da solicitação",
  "action" : "Ação",
  "cancelTransfer" : "Cancelar Transferir",
  "decline" : "Rejeitar",
  "cancelTransferRequest" : "Cancelar solicitaçãos de transferência de subconta?",
  "cancelEjectRequest" : "Cancelar Sub-conta e ejetar solicitações?",
  "cancelRequestDescription" : "A agência recebida não verá mais esta Transferir Solicitações, e a Subconta {name} não será Baixar.",
  "cancelEjectRequestDescription" : "O usuário selecionado não poderá mais criar nova Agência De a Sub-Conta {name}.",
  "declineRequest" : "Rejeitar solicitaçãos de transferência de subcontas?",
  "declineRequestDescription" : "As Solicitações serão recusadas e a Subconta {name} não será Baixar para sua agência.",
  "approveTransfer" : "Aprovar transferência de subconta para sua agência?",
  "approveTransferDescription" : "{name} A Subconta será menor para sua agência.",
  "transferNow" : "Transferir agora",
  "cancelNow" : "Cancelar agora",
  "transferErrorDetails" : "A Subconta que Você está tentando Transferir não pode ser Baixar",
  "cancelRequest" : "Cancelar solicitações",
  "received" : "Recebido",
  "approve" : "Aprovar",
  "goBack" : "Desfazer",
  "done" : "Concluído",
  "subAccountTransferred" : "A Sub-Conta foi baixar com sucesso!",
  "subAccountTransferDescription" : "Observe que o Uniforme, mesmo que a Sub-Conta tenha sido Baixar, alguns ativos Curtir Usuário, Número de telefone, Mailgun Conta, Listagens Yext etc. ainda podem levar de 10 a 15 minutos para refletir in destino.",
  "activeSubscriptionsAlert" : "A localização tem Dinâmico Assinatura. Cancele todos Assinatura antes da transferência fria a localização. Lista de dinâmica Assinatura: {subscriptions}",
  "isSaasEnabled" : "SaaS Habilitado",
  "typeToSearch" : "Digite para pesquisar",
  "dateAdded" : "Data da adição",
  "emailEngagement" : "Engajamento E-mail",
  "emailReaction" : "Reação E-mail",
  "emailDeliverySummary" : "Resumo da Entrega E-mail",
  "facebook" : "Facebook",
  "sms" : "SMS",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "Resumo da Conta - {subAccountName}",
  "test" : "Testar",
  "scheduleExists" : "a programação existe",
  "aToZ" : "A - Z",
  "zToA" : "Z - A",
  "onlyAdminUsersCanAddNewSubAccounts" : "Somente o administrador Usuário pode incluir novas subcontas.",
  "locationName" : "Nome da Localização",
  "transferCompleted" : "A transferência de solicitações para esta localização já está processada. Atualize-o.",
  "unauthorizedTransfer" : "Você não está autorizado a executar esta ação. Somente proprietários de agência podem executar esta Ação.",
  "bulkLocationTransfer" : "Em massa Transferir subconta",
  "singleRequest" : "Indivíduo Solicitações",
  "bulkRequest" : "Em massa de solicitações",
  "backToLocationTransfer" : "Voltar para Transferir de Localização",
  "initiateBulkTransfer" : "gatilho Em massa Sub-conta Transferir",
  "initiateBulkTransferDescription" : "Selecione a subconta que você deseja Transferir ou insira os IDs da subconta Você deseja baixar, Separado Via vírgula.",
  "subAccountId" : "Idda subconta",
  "selectFromList" : "selecionar da lista",
  "addManually" : "Adicionar manualmente",
  "accountsSelected" : "{count} Selecionado",
  "selectAllSubAccounts" : "selecionar todos",
  "removeAll" : "Extrair todos",
  "addManualLocations" : "Adicionar localização Id 's com Separado Via vírgula's",
  "manualAllLocationIds" : "IDs de localização da subconta Você deseja transferência, Separado Via vírgulas.",
  "enterLocationIds" : "Insira Id de localização",
  "selectSubAccounts" : "selecionar sub-conta e transferência para uma agência atual",
  "subAccountTransferWarning" : "todos os ativos subcontas e o usuário obterão o Baixar.",
  "proceed" : "Prosseguir",
  "bulkRequestSubmitted" : "Em massa de transferência de solicitações enviadas",
  "bulkRequestSubmittedDescription" : "todos os subcontas serão baixar assim que a agência recebida aceitar as Transferir Solicitações. Você pode acompanhar o Status das Solicitações Ativadas na tivomento{pagename}⌘ Página. (Emitido eles são qualificadas)",
  "bulkTransferRequestFailed" : "Em massa na transferência de solicitações",
  "errorLocations" : "Subconta com erro",
  "viewDetails" : "Ver detalhes",
  "somethingWrong" : "Algo deu errado. Tente de novo.",
  "confirmBulkRequest" : "Confirmar em massa de transferência de solicitações?",
  "bulkTransferAccounts" : "Em massa Transferir '{count} ' Selecionado Subconta?",
  "confirmDetails" : "Confirme os detalhes antes da transferência fria",
  "transferConsent" : "Eu tenho a Ler e entender o guia acima e o consentimento para todas as limitações.",
  "transferInfo" : "A transferência da subconta é assunto para a agência recebida que a aceita em sua conta.",
  "receivingRelNo" : "Número de contato da agência recebida",
  "enterRelNo" : "Digite o número de ligação",
  "transferConfirmation" : "Após a finalização desta Ação, as Em massa Transferir Solicitações serão enviadas para a Agência Recebida ({ relNo }). todos a subconta Você've selecionado será Baixar assim que a agência recebida o aceitar. Observe que esta ação é irreversível para todas as subcontas incluídos in Transferir.",
  "confirmPassword" : "Confirme Via password abaixo",
  "enterPassword" : "Digite a senha",
  "requestTransfer" : "Transferência de solicitações",
  "confirmTransfer" : "Confirmar Transferir",
  "notNow" : "Não agora",
  "invalidRelNo" : "Número de proporção inválido",
  "invalidPassword" : "password inválida .",
  "searchSubAccounts" : "Pesquisar subconta",
  "searchSubAccountsId" : "Pesquisar Idda subconta",
  "takeAction" : "Entrar em Ação",
  "sub_account_details" : "Sub perfil",
  "no_of_contacts" : "Não de Amigos",
  "transfer_status" : "Status da Transferir",
  "error_info" : "Informações do erro",
  "bulk_transfer_status" : "Em massa Sub-conta da transferência Status",
  "pending" : "Pendente",
  "processed" : "Processado",
  "reject_all" : "Rejeitar todos",
  "accept_all" : "Aceitar todos",
  "cancel_all" : "Cancelar todos",
  "clearAll" : "Limpar todos",
  "refresh" : "Atualizar",
  "rejected" : "Negado",
  "decline_transfer" : "Rejeitar transferência",
  "decline_bulk_transfer" : "Rejeitar Em massa sub-conta Transferir Solicitações ?",
  "decline_bulk_transfer_description" : "As Solicitações serão recusadas e a Coleção de {length} subconta não será Baixar para sua agência.",
  "accept_bulk_transfer" : "Aceitar Em massa sub-conta Transferir Solicitações ?",
  "accept_bulk_transfer_description" : "As Solicitações serão Aceito e a Coleção de {length} subconta será Baixar para sua agência.",
  "cancel_bulk_transfer" : "Cancelar Transferir Em massa ?",
  "cancel_bulk_transfer_description" : "Tem certeza de que deseja cancelar esta massa Em massa Transferir de {length} Sub-conta para “{name}”",
  "consent_sub_account_transfer" : "Ler e consentimento para {link}",
  "sub_account_transfer_guidelines" : "Diretrizes de transferência de subcontas",
  "valid_rel" : "Insira um número de proporção válido",
  "something_went_wrong" : "Algo deu errado. Tente de novo.",
  "export" : "Exportar",
  "export_success" : "Os dados que Você solicitado estão sendo processados. Você enviará um e-mail com os dados in alguns dados.",
  "export_failed" : "Os dados Você solicitado não podem ser processados. Tente de novo mais tarde.",
  "selectAllError" : "Você só pode selecionar 1000 subcontas em um dado.",
  "max1000" : "(Máximo 1000)",
  "subAccountCount" : "Contagem de subconta"
}
