<template>
  <UIToolbar v-if="selectInsightsMode && !schedule" class="mb-16 mt-5">
    <UIToolbarGroup>
      <UIButton id="btn-1" text @click="selectInsightToggle">
        <ArrowLeftIcon class="mr-3 h-5 w-5 text-gray-100" />
        <span class="text-sm text-gray-100"> {{ $t('back') }} </span>
      </UIButton>
    </UIToolbarGroup>
    <UIToolbarGroup>
      <UITextLgMedium class="text-white">
        {{ $t('selectDashboards') }}
      </UITextLgMedium>
    </UIToolbarGroup>
    <UIToolbarGroup>
      <UIButton
        id="schedule-btn"
        type="primary"
        :disabled="selectedInsights.length === 0"
        @click="scheduleFlowToggle"
      >
        {{ $t('next') }}
      </UIButton>
    </UIToolbarGroup>
  </UIToolbar>
  <div
    v-if="!isLoading && !schedule"
    id="pg-agency-location-reporting"
    class="pl-8 pr-8"
  >
    <div v-if="!selectInsightsMode" class="mt-11 px-3">
      <UIButton
        id="pg-agency-location-reporting__btn-back-to-account"
        type="default"
        class="font-bold"
        @click.prevent="backToAccount"
      >
        <UITextSmMedium
          class="inline-flex cursor-pointer content-center items-center font-bold text-blue-500"
        >
          <ArrowLeftIcon class="mr-3 h-5 w-5" />
          {{ $t('backToSubAccounts') }}
        </UITextSmMedium>
      </UIButton>

      <div class="mt-6 flex justify-between">
        <UIDisplaySmMedium v-if="locationName">
          {{ locationName }}
        </UIDisplaySmMedium>

        <span class="align-right flex flex-row justify-center">
          <UIDatepicker
            :id="'filters-date-picker'"
            v-model:value="dateRange"
            :shortcuts="rangeShortcuts"
            :is-date-disabled="isDateDisabled"
            type="daterange"
            class="d-ib w-80"
          ></UIDatepicker>
          <UITooltip v-if="scheduleData?.data && isAdmin">
            <template #trigger>
              <UIButton
                id="schedule-pause-btn"
                type="primary"
                :aria-label="
                  schedulePublished ? $t('pauseSchedule') : $t('resumeSchedule')
                "
                :loading="pauseScheduleSpinner"
                :disabled="pauseScheduleSpinner"
                @click="toggleModal"
              >
                <span v-if="!pauseScheduleSpinner">
                  <PauseCircleIcon
                    v-if="schedulePublished"
                    class="h-5 w-5"
                  ></PauseCircleIcon>
                  <PlayCircleIcon v-else class="h-5 w-5"></PlayCircleIcon>
                </span>
              </UIButton>
            </template>
            {{ schedulePublished ? $t('pauseSchedule') : $t('resumeSchedule') }}
          </UITooltip>

          <UIButton
            v-if="editScheduleMode && isAdmin"
            id="schedule-btn"
            type="primary"
            class="ml-2"
            @click="selectInsightToggle"
          >
            {{ $t('editSchedule') }}
          </UIButton>
          <UIButton
            v-if="!editScheduleMode && isAdmin"
            id="pg-agency-locations__btn--add-schedule"
            type="primary"
            class="ml-2"
            @click="selectInsightToggle"
          >
            {{ $t('addSchedule') }}
          </UIButton>
        </span>
      </div>
    </div>
    <div v-if="selectInsightsMode" class="mt-11 px-3">
      <div class="mt-6 flex justify-between">
        <UIDisplaySmMedium> {{ $t('selectReportToAdd') }} </UIDisplaySmMedium>

        <span class="align-right flex flex-row justify-center">
          <UICheckbox
            id="select-all-checkbox"
            v-model:checked="selectAllInsights"
            @update:checked="selectAllInsightsFunc"
            >{{ $t('selectAll') }}</UICheckbox
          >
        </span>
      </div>
    </div>

    <div class="my-10">
      <draggable
        :list="graphs"
        :disabled="!enableExperimentalDragging"
        class="dragArea list-group grid w-full grid-cols-4 gap-4"
        handle=".chart-draggble-handler"
        @change="onReorderGraph"
      >
        <div
          v-for="chart in graphs"
          :key="chart.type"
          class="list-group-item my-3"
          :class="chart.class"
        >
          <component
            :is="chartComponents[chart.type] || chartComponents.default"
            :type="chart.type"
            :chart-title="chart.chartTitle"
            :title="chart.title"
            :select-mode="selectInsightsMode"
            :checked-insights="selectedInsights"
            :is-draggble="enableExperimentalDragging"
            @insight-select-event="insightsArrayModify"
          />
        </div>
      </draggable>
    </div>

    <ScheduleTable
      v-if="!selectInsightsMode && editScheduleMode && isAdmin"
      :location="location"
      :schedule-body="scheduleData"
    ></ScheduleTable>
  </div>
  <ScheduleForm
    v-if="!isLoading && schedule && isAdmin"
    :location-data="location"
    :schedule-body="scheduleData"
    :insights-array="selectedInsights"
    @close-schedule="scheduleFlowToggle"
  ></ScheduleForm>
  <Modal
    :show-modal="modalVisible"
    :data="modalData"
    @close-modal="toggleModal"
    @submit="confirmModal"
  >
    <template #content>
      <div class="flex flex-row items-center gap-2">
        <p class="inline-block">
          {{
            schedulePublished
              ? $t('pauseScheduleConfirm')
              : $t('resumeScheduleConfirm')
          }}
        </p>
      </div>
    </template>
  </Modal>
</template>

<script>
import { getDateRangeShortcuts, isDateDisabled } from '@/helpers'
import { router } from '@/router'
import { useAppStore } from '@/store/app'
import {
  ArrowLeftIcon,
  ClockIcon,
  PauseCircleIcon,
  PlayCircleIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import {
  UIButton,
  UIButtonGroup,
  UICheckbox,
  UIDatepicker,
  UIDisplaySmMedium,
  UITextLgMedium,
  UITextSmMedium,
  UIToolbar,
  UIToolbarGroup,
  UITooltip,
} from '@gohighlevel/ghl-ui'
import { BarChart, LineChart } from 'echarts/charts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import moment from 'moment'
import { VueDraggableNext } from 'vue-draggable-next'
import EmailReportChart from './charts/EmailReportChart.vue'
import LocationReportChart from './charts/LocationReportChart.vue'
import LoginsReportChart from './charts/LoginsReportChart.vue'
import MessagesReportChart from './charts/MessagesReportChart.vue'
import OpportunitiesReportChart from './charts/OpportunitiesReportChart.vue'
import SMSReportChart from './charts/SMSReportChart.vue'
import SubmissionsReportChart from './charts/SubmissionsReportChart.vue'

import { LocationService } from '@/services/LocationService'
import { ReportingService } from '@/services/ReportingService'
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'
import AppointmentsReportChart from './charts/AppointmentsReportChart.vue'
import ConversationsReport from './charts/ConversationsReport.vue'
import TasksReport from './charts/TasksReport.vue'
import Modal from './schedule/helpers/modal/Modal.vue'

use([
  CanvasRenderer,
  LineChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
])

const LOCAL_STORAGE_KEY = 'rollup_reporting:graphs:sequence'

const labelOption = {
  rotate: 90,
  align: 'left',
  verticalAlign: 'middle',
  position: 'insideBottom',
  distance: 15,
}

import ScheduleForm from './schedule/ScheduleForm.vue'
import ScheduleTable from './schedule/ScheduleTable.vue'

export default {
  components: {
    UIButton,
    UIButtonGroup,
    UIDatepicker,
    UITextSmMedium,
    LocationReportChart,
    SubmissionsReportChart,
    ArrowLeftIcon,
    MessagesReportChart,
    UIDisplaySmMedium,
    LoginsReportChart,
    AppointmentsReportChart,
    SMSReportChart,
    EmailReportChart,
    OpportunitiesReportChart,
    draggable: VueDraggableNext,
    ScheduleForm,
    ClockIcon,
    ScheduleTable,
    PauseCircleIcon,
    PlayCircleIcon,
    UITooltip,
    Modal,
    UIToolbar,
    UIToolbarGroup,
    UICheckbox,
    UITextLgMedium,
    TasksReport,
    ConversationsReport,
  },
  data() {
    return {
      isDateDisabled: isDateDisabled,
      dateRange: [],
      isLoading: true,
      rangeShortcuts: getDateRangeShortcuts,
      locationName: null,
      // graphs: [
      //   {
      //     type: 'tasks',
      //     chartTitle: this.$t('tasks'),
      //     title: 'Tasks',
      //     class: 'col-start-1 col-span-1',
      //   },
      //   {
      //     type: 'conversations',
      //     chartTitle: this.$t('conversations'),
      //     title: 'Conversations',
      //     class: 'col-start-2 col-span-1',
      //   },
      //   {
      //     type: 'users',
      //     chartTitle: this.$t('users'),
      //     title: this.$t('activeUsers'),
      //     class: 'row-start-2 col-span-2',
      //   },
      //   {
      //     type: 'contact',
      //     chartTitle: this.$t('contacts'),
      //     title: this.$t('contacts'),
      //     class: 'row-start-2 col-span-2',
      //   },
      //   {
      //     type: 'outreach',
      //     chartTitle: this.$t('outreach'),
      //     title: this.$t('outreach'),
      //     class: 'col-span-4',
      //   },
      //   {
      //     type: 'submissions',
      //     chartTitle: this.$t('submissions'),
      //     title: 'Submissions',
      //     class: 'col-span-2',
      //   },
      //   { type: 'sms', chartTitle: 'SMS', title: 'SMS', class: 'col-span-2' },
      //   {
      //     type: 'appointments',
      //     chartTitle: this.$t('appointments'),
      //     title: 'Appointments',
      //     class: 'col-span-2',
      //   },
      //   {
      //     type: 'opportunities',
      //     chartTitle: this.$t('dealOverview'),
      //     title: 'Deal overview',
      //     class: 'col-span-2',
      //   },
      //   {
      //     type: 'email',
      //     chartTitle: this.$t('emails'),
      //     title: 'Email',
      //     class: 'col-span-4',
      //   },
      // ],
      enableExperimentalDragging: false,
      chartComponents: {
        tasks: TasksReport,
        conversations: ConversationsReport,
        users: LoginsReportChart,
        submissions: SubmissionsReportChart,
        outreach: MessagesReportChart,
        default: LocationReportChart,
        sms: SMSReportChart,
        email: EmailReportChart,
        appointments: AppointmentsReportChart,
        opportunities: OpportunitiesReportChart,
      },
      schedule: false,
      location: null,
      scheduleData: null,
      editScheduleMode: false,
      scheduleSpinner: false,
      selectInsightsMode: false,
      selectedInsights: [],
      selectAllInsights: false,
      pauseScheduleSpinner: false,
      modalVisible: false,
      modalData: {
        propTitle: this.$t('confirmAction'),
        buttons: {
          secondary: this.$t('no'),
          primary: this.$t('yes'),
        },
      },
    }
  },
  computed: {
    schedulePublished() {
      return this.scheduleData?.data?.status === 'published'
    },
    isAdmin() {
      const appStore = useAppStore()
      if (
        appStore &&
        appStore.user?.role === 'admin' &&
        appStore.user?.type === 'agency'
      ) {
        return true
      }
      return false
    },
    graphs() {
      return [
        {
          type: 'tasks',
          chartTitle: this.$t('tasks'),
          title: 'Tasks',
          class: 'col-start-1 col-span-1',
        },
        {
          type: 'conversations',
          chartTitle: this.$t('conversations'),
          title: 'Conversations',
          class: 'col-start-2 col-span-1',
        },
        {
          type: 'users',
          chartTitle: this.$t('users'),
          title: this.$t('activeUsers'),
          class: 'row-start-2 col-span-2',
        },
        {
          type: 'contact',
          chartTitle: this.$t('contacts'),
          title: this.$t('contacts'),
          class: 'row-start-2 col-span-2',
        },
        {
          type: 'outreach',
          chartTitle: this.$t('outreach'),
          title: this.$t('outreach'),
          class: 'col-span-4',
        },
        {
          type: 'submissions',
          chartTitle: this.$t('submissions'),
          title: 'Submissions',
          class: 'col-span-2',
        },
        { type: 'sms', chartTitle: 'SMS', title: 'SMS', class: 'col-span-2' },
        {
          type: 'appointments',
          chartTitle: this.$t('appointments'),
          title: 'Appointments',
          class: 'col-span-2',
        },
        {
          type: 'opportunities',
          chartTitle: this.$t('dealOverview'),
          title: 'Deal overview',
          class: 'col-span-2',
        },
        {
          type: 'email',
          chartTitle: this.$t('emails'),
          title: 'Email',
          class: 'col-span-4',
        },
      ]
    },
    // scheduleEnable() { // kept for future release reference
    //   if (
    //     this.location.companyId === "YuTUZlUtrwBtvmgByZDW" ||
    //     config.mode === "staging" ||
    //     config.mode === "dev"
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },
  },
  watch: {
    dateRange(newVal) {
      const start = moment(newVal[0]).format('YYYY-MM-DD')
      const end = moment(newVal[1]).format('YYYY-MM-DD')
      const path = `/sub-accounts/dashboard/${this.$route.params.locationId}?start=${start}&end=${end}`
      router.push({
        path,
        query: {
          start,
          end,
        },
      })
      const appStore = useAppStore()
      if (appStore.appHandshake) {
        appStore.appHandshake.then(parent => {
          parent.emit('route-change', {
            path,
          })
        })
      }
    },
    selectedInsights: {
      deep: true,
      handler(newVal) {
        if (newVal.length === 12) {
          this.selectAllInsights = true
        } else {
          this.selectAllInsights = false
        }
      },
    },
  },
  mounted() {
    this.setBaseData()
  },
  methods: {
    onReorderGraph() {
      const sequences = this.graphs.map(x => x.type)
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(sequences))
    },
    async setBaseData() {
      const appStore = useAppStore()
      if (!appStore.companyId) {
        return setTimeout(this.setBaseData, 1000)
      }

      const {
        data: { location },
      } = await LocationService.getById(
        appStore.companyId,
        this.$route.params.locationId
      )
      this.location = location
      this.locationName = location?.name
      const startDateRange = this.$route.query.start
        ? moment(this.$route.query.start).startOf('d').format()
        : moment().subtract(1, 'w').startOf('d').format()
      const endDateRange = this.$route.query.end
        ? moment(this.$route.query.end).startOf('d').format()
        : moment().subtract(1, 'd').startOf('d').format()

      this.dateRange = [
        new Date(startDateRange).getTime(),
        new Date(endDateRange).getTime(),
      ]
      if (this.isAdmin) {
        await this.fetchSchedule()
      }
      this.isLoading = false

      if (appStore.mode !== 'production' || this.$route.query.dragging) {
        this.enableExperimentalDragging = true
        this.setGraphOrderIfExists()
      }
    },
    async fetchSchedule() {
      const schData = await this.getSchedules()
      if (schData.count > 0) {
        this.editScheduleMode = true
        this.scheduleData = {
          editScheduleMode: this.editScheduleMode,
          data: schData.data[0],
        }
      } else {
        this.editScheduleMode = false
        this.scheduleData = null
      }
    },
    async getSchedules() {
      this.scheduleSpinner = true
      // http req to get schedules for this location
      const { data } = await ReportingService.getSchedulesByLocation({
        locationId: this.location.id,
      })
      this.scheduleSpinner = false
      return data
    },
    setGraphOrderIfExists() {
      const graphOrder = localStorage.getItem(LOCAL_STORAGE_KEY)
      if (graphOrder) {
        const graphOrderParsed = JSON.parse(graphOrder)
        for (let index = 0; index < graphOrderParsed.length; index++) {
          const graphType = graphOrderParsed[index]
          const existingIndex = this.graphs.findIndex(x => x.type === graphType)
          if (existingIndex !== -1) {
            ;[this.graphs[index], this.graphs[existingIndex]] = [
              this.graphs[existingIndex],
              this.graphs[index],
            ]
          }
        }
      }
    },
    backToAccount() {
      const appStore = useAppStore()
      const path = `/sub-accounts`
      if (appStore.appHandshake) {
        appStore.appHandshake.then(parent => {
          parent.emit('route-change', {
            path,
          })
        })
      }

      router.push(path)
    },
    async scheduleFlowToggle() {
      this.schedule = !this.schedule
      if (this.schedule === false) {
        await this.fetchSchedule()
      }
    },
    selectInsightToggle() {
      this.selectInsightsMode = !this.selectInsightsMode
      if (this.editScheduleMode) {
        this.selectedInsights = JSON.parse(
          JSON.stringify(this.scheduleData.data.insights)
        )
      } else {
        this.selectedInsights = [
          'users',
          'contacts',
          'appointments',
          'submissions',
          'outreach',
          'email_engagement',
          'email_reactions',
          'email_delivery',
          'sms',
          'opportunities',
          'conversations',
          'tasks',
        ]
      }
    },
    insightsArrayModify(eventData) {
      let { chart, value } = eventData
      if (chart === 'contact') {
        chart = 'contacts'
      }
      if (value) {
        this.selectedInsights.push(chart)
      } else {
        this.selectedInsights = this.selectedInsights.filter(
          chartElem => chartElem !== chart
        )
      }
    },
    selectAllInsightsFunc(val) {
      if (val) {
        this.selectedInsights = [
          'users',
          'contacts',
          'appointments',
          'submissions',
          'outreach',
          'email_engagement',
          'email_reactions',
          'email_delivery',
          'sms',
          'opportunities',
          'conversations',
          'tasks',
        ]
      } else {
        this.selectedInsights = []
      }
    },
    async pauseResumeSchedule() {
      this.pauseScheduleSpinner = true
      const schedule = this.scheduleData?.data
      const id = schedule._id
      await ReportingService.updateScheduleStatus(
        id,
        {
          status: this.schedulePublished ? 'draft' : 'published',
        },
        { locationId: this.location.id }
      )

      await this.fetchSchedule()
      this.pauseScheduleSpinner = false
    },
    toggleModal() {
      // to toggle modal visibility
      this.modalVisible = !this.modalVisible
    },
    async confirmModal() {
      this.toggleModal()
      await this.pauseResumeSchedule()
    },
  },
}
</script>

<style>
/* .rollup__charts-card {
  height: 470px;
}
.rollup__charts-card .hl-card-content {
  height: 300px;
} */
.rollup__charts-card .chart {
  height: 300px;
}

.primary-btn {
  background-color: #155eef;
  /* color: #155EEF; */
}

.align-right {
  justify-content: flex-end;
}

.d-ib {
  display: inline-block;
  margin-right: 8px;
}
</style>
