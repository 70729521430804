{
  "common" : {
    "thisWeek" : "Den här veckan",
    "lastWeek" : "Senaste veckan",
    "lastNumberOfDays" : "senaste {numberOfDays} dagarna",
    "thisMonth" : "Den här månaden"
  },
  "subAccounts" : "Underkonto",
  "submitFeedback" : "Skicka feedback",
  "createSubAccount" : "Skapa underkonto",
  "viewScheduleReports" : "Visa Schemalagd rapporter",
  "searchSubAccount" : "Sök Via underkonto",
  "columns" : "Kolumner",
  "sortBy" : "Sortera efter",
  "selectColumns" : "Välj kolumner",
  "maxColumns" : "Max 8 kolumner vid ett datum",
  "applyColumns" : "Tillämpa kolumner",
  "activeUsers" : "Aktiva användare",
  "appointments" : "Möten",
  "contacts" : "Kontakter",
  "emails" : "Gmail",
  "textMessages" : "Brödtextmeddelanden",
  "calls" : "Samtal",
  "submissions" : "Inlämningar",
  "reviews" : "Recensioner",
  "sitesCreated" : "Skapad webbplatser",
  "name" : "Namn",
  "formSubmissions" : "Formulär",
  "surveySubmissions" : "Undersökning",
  "facebookFormSubmissions" : "Inlämningar av Facebook-formulär",
  "funnels" : "Trattar",
  "websites" : "Webbplatser",
  "forms" : "Formulär",
  "surveys" : "Undersökningar",
  "highestFirst" : "Max först",
  "lowestFirst" : "Lägst först",
  "orderBy" : "Sortera Via",
  "noMoreLocations" : "Inget mer Adress hittades",
  "preparingReport" : "Vi är Läser i din rapport",
  "preparingReportDesc" : "Ladda inte om den här Fönster från Stäng Eller medan Exportera in",
  "outOf" : "Från",
  "subAccountsExported" : "Underkonto exporterad",
  "close" : "Stäng",
  "scheduledForDeletion" : "Schemalagd för radering",
  "readyToSell" : "Redo att sälja",
  "closedWon" : "Stängd (Vann)",
  "wpSaleSuccessful" : "WordPress-försäljningen har genomförts",
  "wpNotSold" : "WordPress är inte såld än",
  "yxtSaleSuccessful" : "Nästa försäljning är framgångsrik",
  "yxtNotSold" : "Ännu inte såld",
  "saasSubInactive" : "SaaS Abonnemang är Inaktiv",
  "saasSubTrialing" : "SaaS Abonnemang testar",
  "saasSubActive" : "SaaS Abonnemang är Dynamisk",
  "emailRebillingActive" : "E-post Återfakturering är Dynamisk",
  "emailRebillingInactive" : "E-post Återfakturering är Inaktiv",
  "twilioRebillingActive" : "Twilio Återfakturering är Dynamisk",
  "twilioRebillingInactive" : "Twilio Återfakturering är Inaktiv",
  "wpRebillingActive" : "WhatsApp Återfakturering är Dynamisk",
  "wpRebillingInactive" : "WhatsApp Återfakturering är Inaktiv",
  "saasModeActivated" : "SaaS läget är Aktiverad",
  "paymentPending" : "Betalning väntar",
  "saasModeNotActivated" : "SaaS läget är inte Aktiverad",
  "managedByLCPhone" : "Hantera Via LC Telefon",
  "migrationToLCPhone" : "Under migrering till LC Telefon",
  "notManagedByLCPhone" : "Inte Hantera Via LC Telefon",
  "managedByLCEmail" : "Hantera Via LeadConnector Email System",
  "notMangedByLCEmail" : "Inte Hantera Via LeadConnector Email System",
  "remainingWalletBalance" : "Återstående Plånbok Saldo av denna SaaS Plats",
  "reports" : "Rapporter",
  "switchToSubAccount" : "Byt till Underkonto",
  "manageClient" : "Hantera Kund",
  "subAccountDeletedIfChangedMind" : "Den här underkontoen blir Raderad På {time}, Om Du've Dynamisk your mind",
  "subAccountDeletedInMinute" : "Denna underkonto kommer att raderas inom Nästa minut",
  "changedMind" : "Om Du've Dynamisk your mind",
  "clickHere" : "klicka här",
  "subAccountPaused" : "Den här underkontoen är Pausad. Din Kunder kan inte logga in men Byrå kan. Till Återuppta detta Konto",
  "subAccountRestricted" : "Denna underkonto är Pausad Utestående till väntande Godkännande.",
  "subAccountVerificationPending" : "Denna underkonto är Pausad Utestående till pågående e-post- och telefonverifiering Från Underkonto Admin.",
  "subAccountDeclined" : "Denna Underkonto är Pausad Utestående till avböjda Godkännande Förfrågningar. Du kan fortfarande Återuppta denna Underkonto Från ",
  "here" : "här.",
  "reviewAndResume" : "till Recension och Återuppta denna Underkonto.",
  "incomingCalls" : "Inkommande samtal",
  "outgoingCalls" : "Utgående samtal",
  "totalTalktime" : "Totalt taltid",
  "incomingEmails" : "Inkommande Gmail",
  "outgoingEmails" : "Utgående Gmail",
  "incomingSms" : "Inkommande Brödtextmeddelanden",
  "outgoingSms" : "Utgående Brödtextmeddelanden",
  "contactsCreated" : "siffra av Vänner Skapad",
  "appointmentsBooked" : "siffra av Möte bokad",
  "totalFormSubmissions" : "Totalt Formulär-inlämningar",
  "totalSurveySubmissions" : "Totalt Undersöknings inlämningar",
  "totalFacebookFormSubmissions" : "Totalt inlämningar av Facebook-formulär",
  "incomingGMBMessages" : "Inkommande GBP-meddelanden",
  "outgoingGMBMessages" : "Utgående meddelanden i GBP",
  "gmbMessages" : "Meddelanden i GBP",
  "fbMessages" : "Facebook-meddelanden",
  "incomingFbMessages" : "Inkommande Facebook-meddelanden",
  "outgoingFbMessages" : "Utgående Facebook-meddelanden",
  "igMessages" : "Instagram-meddelanden",
  "incomingIgMessages" : "Inkommande Instagram-meddelanden",
  "outgoingIgMessages" : "Utgående Instagram-meddelanden",
  "totalReviews" : "Totalt recensioner",
  "positiveReviews" : "Positiva recensioner",
  "negativeReviews" : "Negativa recensioner",
  "back" : "Tillbaka",
  "selectDashboards" : "Välj instrumentpaneler",
  "next" : "Nästa",
  "backToSubAccounts" : "Tillbaka till Sub-Konto",
  "pauseSchedule" : "Pausa schema",
  "resumeSchedule" : "Återuppta schema",
  "editSchedule" : "Redigera schema",
  "addSchedule" : "Lägg till schema",
  "selectReportToAdd" : "Välj rapporterar till Inkludera in E-posten",
  "selectAll" : "Välj varje",
  "pauseScheduleConfirm" : "Är du säker på att du vill pausa rapportschemat?",
  "resumeScheduleConfirm" : "Är du säker på att du vill Återuppta rapportschemat?",
  "tasks" : "Uppgifter",
  "conversations" : "Konversationer",
  "users" : "Användare",
  "dealOverview" : "Avtal Översikt",
  "confirmAction" : "Bekräfta Åtgärd",
  "yes" : "Ja",
  "no" : "Nej",
  "reportDetails" : "Detaljer",
  "reportCreatedOn" : "Rapport Skapad På",
  "searchByEmail" : "Sök Via e-post",
  "lastActivity" : "senaste aktiviteten",
  "noRecords" : "Inga Register hittades",
  "scheduleReport" : "schemarapport",
  "schedule" : "schema",
  "save" : "Spara",
  "emailSubject" : "Ämnesrad",
  "frequency" : "Frekvens",
  "daily" : "Dagligen",
  "weekly" : "Varje vecka",
  "monthly" : "Varje månad",
  "nextScheduleDate" : "Nästa schema Datum",
  "startingOn" : "Startar På",
  "timeOfDay" : "Datum av Dag",
  "reportsToTimezone" : "Rapporten skickas in underkontons tidszon",
  "selectRecipients" : "Välj mottagare",
  "invalidEmail" : "Du har angett en Ogiltig e-postadress",
  "testEmail" : "Test e-post",
  "selectTestRecipients" : "Välj Test",
  "setupSubject" : "Konfigurera Ämne ovan för att Test rapporten",
  "sendTest" : "Ladda upp Test",
  "scheduleSimilarSchedules" : "schemaliknande rapport för Annan Sub-Konto",
  "accountSummary" : "Konto Sammanfattning",
  "scheduleList" : "schema Lista",
  "subAccountName" : "Namn på underkonto",
  "nextRunDate" : "Datum för Nästa körning",
  "reportSectionsAdded" : "Segment har lagts till",
  "status" : "Status",
  "scheduleAction" : "schema Åtgärd",
  "cancel" : "Avbryt",
  "pleaseWait" : "Var god Vänta",
  "confirm" : "Bekräfta",
  "booked" : "Bokad",
  "confirmed" : "Bekräftad",
  "showed" : "Deltog",
  "noShow" : "Uteblev",
  "cancelled" : "Avbokad",
  "unread" : "Oläst",
  "engagement" : "Engagemang",
  "reaction" : "Reaktion",
  "deliverySummary" : "Leverans Sammanfattning",
  "successfulDelivery" : "Lyckad Leverans",
  "opened" : "Öppnad",
  "clicked" : "Klickad",
  "replied" : "Svarade",
  "unsubscribed" : "Avprenumererade",
  "complained" : "Klagade",
  "failed" : "Misslyckad",
  "open" : "Öppen",
  "won" : "Vunnen",
  "abandoned" : "Övergiven",
  "lost" : "Förlorad",
  "sent" : "Skickat",
  "eject" : "Mata ut",
  "delivered" : "Levererad",
  "faceBookLeadForm" : "Formulär för Potentiell kund på Facebook",
  "overdue" : "Förfallna",
  "reviewsReceived" : "Recensioner Mottagen",
  "formBuilders" : "Formulär",
  "switchToSaaS" : "Byt till SaaS",
  "numberOfContacts" : "siffra av Vänner",
  "numberOfAppointments" : "siffra av Möte",
  "outreach" : "Uppsökande verksamhet",
  "notSubAccountUser" : "Inte en underkonto Användare",
  "searchSubAccName" : "Sök Via Underkontos namn",
  "recipients" : "Mottagare",
  "scheduleCreated" : "Rapportschema via e-post skapat för ",
  "scheduleUpdated" : "E-post Rapportschema Uppdaterad för ",
  "scheduleNotCreated" : "Rapportschema för e-post kunde inte skapas för",
  "scheduleNotUpdated" : "Rapportschema för e-post kunde inte uppdateras för",
  "total" : "Totalt",
  "inbound" : "Inkommande",
  "outbound" : "Utgående",
  "totalEmails" : "Totalt Gmail",
  "inboundEmails" : "Inkommande Gmail",
  "outboundEmails" : "Utgående Gmail",
  "totalTextMessages" : "Totalt Brödtextmeddelanden",
  "inboundTextMessages" : "Inkommande Brödtextmeddelanden",
  "outboundTextMessages" : "Utgående Brödtextmeddelanden",
  "totalCalls" : "Totalt antal samtal",
  "inboundCalls" : "Inkommande samtal",
  "outboundCalls" : "Utgående samtal",
  "faceBookLeadFormSubmissions" : "Inlämningar av Facebook Formulär för Potentiell kund",
  "inboundGMBMessages" : "Inkommande meddelanden i GBP",
  "outboundGMBMessages" : "Utgående meddelanden i GBP",
  "totalIgMessages" : "Totalt Instagram-meddelanden",
  "inboundIgMessages" : "Inkommande Instagram-meddelanden",
  "outboundIgMessages" : "Utgående Instagram-meddelanden",
  "totalfbMessages" : "Totalt Facebook-meddelanden",
  "inboundfbMessages" : "Inkommande Facebook-meddelanden",
  "outboundfbMessages" : "Utgående Facebook-meddelanden",
  "sitesCreatedFunnels" : "Skapad webbplatser – Trattar",
  "sitesCreatedActiveFunnels" : "Webbplatser skapade - Dynamisk Trattar",
  "sitesCreatedWebsites" : "Skapad webbplatser – webbplatser",
  "sitesCreatedActiveWebsites" : "Webbplatser skapade - Dynamisk webbplatser",
  "sitesCreatedForms" : "Skapad webbplatser – formulär",
  "sitesCreatedActiveForms" : "Webbplatser skapade - Dynamisk Forms",
  "sitesCreatedSurveys" : "Skapad webbplatser – Undersökningar",
  "sitesCreatedActiveSurveys" : "Webbplatser skapade - Dynamisk Surveys",
  "googleReviews" : "Google Recensioner",
  "facebookReviews" : "Facebook-recensioner",
  "inboundCallDuration" : "Inkommande Ring Varaktighet (tid)",
  "outboundCallDuration" : "Utgående Ring Varaktighet (timme)",
  "pleaseSelect" : "Vänligen Välj",
  "subAccountIsDeleted" : "Denna underkonto har varit Raderad",
  "subAccountIsPaused" : "Den här underkontoen har varit Pausad",
  "success" : "Klart",
  "error" : "Fel",
  "testEmailSent" : "Test E-post skickade",
  "testEmailNotSent" : "Test E-post kunde inte skickas",
  "reviewRequest" : "Recension Förfrågningar",
  "pendingTransferDescription" : "Annan Byråvy har begärt att Överför Sub-Konto till Du.",
  "pendingTransferHeading" : "Du've got oning Sub-Konto Överför Förfrågningar.",
  "subAccountTransfer" : "Underkonto Ladda ner",
  "manageSubAccTransfer" : "Hantera din Underkonto Överför Förfrågningar",
  "subAccRequest" : "Underkonto {tabName} Förfrågningar",
  "receive" : "Ärv",
  "send" : "Skicka",
  "release" : "Version",
  "requests" : "{count} Förfrågningar",
  "subAccLimit" : "Underkontoanvändning GRÄNS har uppnåtts!",
  "subAccPlanLimit" : "Du har använt 3/3 underkonto som tillåts enligt din Nuvarande Abonnemang.",
  "upgradeTime" : "Det är Datum för en Uppgradera!",
  "upgradeHigher" : "Uppgradera till en högre Abonnemang för att godkänna Underkonto Överför.",
  "browse" : "Bläddra bland abonnemang",
  "pendingTransferTitle" : "En Överför Förfrågningar för denna underkonto väntar redan.",
  "unableTransfer" : "Det går inte att Överföra underkonto till detta Byrå.",
  "unableEjectCancel" : "Det gick inte att avbryta underkonto Eject Förfrågningar",
  "EjectErrorDetails" : "Underkontots utmatningsförfrågningar som Du försöker Avbryta kan inte avbrytas.",
  "hippaErrorDescription" : "Underkontoen som Du försöker Överför är HIPAA Aktiverad och kan inte Blind överföring till ett icke-HIPAA Byrå.",
  "pendingTransferErrorDescription" : "Vänligen Avbryt the already Nuvarande Överför Förfrågningar for this Sub-Konto Före proceeding to Förhöj a Ny one.",
  "receivingAgency" : "Mottagen Byrå",
  "releasingAgency" : "Utfärdade Byrå",
  "noOfContacts" : "No. av Vänner",
  "requestedDate" : "Önskat Datum",
  "action" : "Åtgärd",
  "cancelTransfer" : "Avbryt Överför",
  "decline" : "Neka",
  "cancelTransferRequest" : "Avbryt Sub-Konto Överför Förfrågningar?",
  "cancelEjectRequest" : "Avbryt Sub-Konto eject Förfrågningar?",
  "cancelRequestDescription" : "Mottagen Byrå kommer inte längre att se denna Överför Förfrågningar, och underkontot {name} kommer inte att vara Blind överföring.",
  "cancelEjectRequestDescription" : "Markerad Användare kommer inte längre att kunna Skapa Ny Byrå från underkontot {name}.",
  "declineRequest" : "Neka Sub-Konto Överför Förfrågningar?",
  "declineRequestDescription" : "Förfrågningarna kommer att avvisas och underkontot {name} blir inte Blind överföring till ditt Byrå.",
  "approveTransfer" : "Godkänn Underkonto Överför till ditt Byrå?",
  "approveTransferDescription" : "{name} Underkonto blir Blind överföring till ditt Byrå.",
  "transferNow" : "Överför nu",
  "cancelNow" : "Avbryt nu",
  "transferErrorDetails" : "Underkontoen som Du försöker Överför kan inte vara Blind överföring",
  "cancelRequest" : "Avbryt Förfrågningar",
  "received" : "Mottagen",
  "approve" : "Godkänn",
  "goBack" : "Ångra",
  "done" : "Klar",
  "subAccountTransferred" : "Underkontot har framgångsrikt gjorts Blind överföring!",
  "subAccountTransferDescription" : "Vänligen Anteckning att Jämn även om Underkontot har varit Blind överföring, vissa tillgångar Gilla Användare, Telefonnummer, Mailgun Konto, Yext-listor etc. kan fortfarande ta 10-15 minuter att återspegla in destinationen.",
  "activeSubscriptionsAlert" : "Platsen har Dynamisk Abonnemang. Vänligen Avbryt varje Abonnemang Före Blind överföring av plattformarna. Lista på Dynamisk Abonnemang: {subscriptions}",
  "isSaasEnabled" : "SaaS Aktiverad",
  "typeToSearch" : "Skriv för att söka",
  "dateAdded" : "Datum tillagt",
  "emailEngagement" : "E-post",
  "emailReaction" : "E-post",
  "emailDeliverySummary" : "Sammanfattning av E-post Leverans",
  "facebook" : "Facebook",
  "sms" : "SMS",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "Konto {subAccountName} {Sammanfattning}",
  "test" : "Test",
  "scheduleExists" : "schema finns",
  "aToZ" : "A–Ö",
  "zToA" : "Ö – A",
  "onlyAdminUsersCanAddNewSubAccounts" : "Endast Admin Användare kan Inkludera Ny underkonto:s.",
  "locationName" : "Plats",
  "transferCompleted" : "Överför Förfrågningar för denna Plats behandlas redan. Vänligen uppdatera.",
  "unauthorizedTransfer" : "Du är inte behörig att utföra denna Åtgärd. Endast Byråägare kan utföra denna Åtgärd.",
  "bulkLocationTransfer" : "Bulk Överför Sub Konto",
  "singleRequest" : "Enskilda Förfrågningar",
  "bulkRequest" : "Bulk Förfrågningar",
  "backToLocationTransfer" : "Tillbaka till Plats Överför",
  "initiateBulkTransfer" : "utlösare Bulk Underkonto Överför",
  "initiateBulkTransferDescription" : "Vänligen Välj underKonto Du önskar till Överför Eller ange ID:n på underkonto Du önskar till Överför, Separerad Med kommatecken.",
  "subAccountId" : "Underkonto- Id",
  "selectFromList" : "Välj från Lista",
  "addManually" : "Lägg till manuellt",
  "accountsSelected" : "{count} Markerad",
  "selectAllSubAccounts" : "Välj varje",
  "removeAll" : "Extrahera varje",
  "addManualLocations" : "Lägg till plattforms- Id:n med Separerad Via komma",
  "manualAllLocationIds" : "Plats IDs av the sub-Konto Du wish to Överför, Separerad Via kommatecken.",
  "enterLocationIds" : "Ange Plats- Id:n",
  "selectSubAccounts" : "Välj Sub-Konto And Överför to an Nuvarande Byrå",
  "subAccountTransferWarning" : "Varje underkonto-tillgångar och Användare kommer att få Blind överföring.",
  "proceed" : "Fortsätt",
  "bulkRequestSubmitted" : "Bulk Överför Förfrågningar Inlämnad",
  "bulkRequestSubmittedDescription" : "Varje underkonto kommer att bli Blind överföring när Mottagen Byrå godkänner Överför Förfrågningar. Du kan spåra Förfrågningarnas Status på ❝{pagename}❞ Sidan. (Utfärdade är de behöriga)",
  "bulkTransferRequestFailed" : "Bulk Överför Förfrågningar Misslyckades",
  "errorLocations" : "Underkonto med fel",
  "viewDetails" : "Visa detaljer",
  "somethingWrong" : "Något gick fel. Försök igen.",
  "confirmBulkRequest" : "Bekräfta Bulk Överför Förfrågningar?",
  "bulkTransferAccounts" : "Bulk Överför '{count}' Markerad Sub Konto ?",
  "confirmDetails" : "Vänligen bekräfta Detaljer Före Blind överföring",
  "transferConsent" : "Jag har Läs och förstått ovanstående guide och Samtycke till varje begränsningar.",
  "transferInfo" : "Under Konto Överför är Ämne till Mottagen Byrå som tar emot det till sitt konto.",
  "receivingRelNo" : "Mottagen Byrås Förhållande siffra",
  "enterRelNo" : "Ange Relation siffra",
  "transferConfirmation" : "När du har slutfört denna Åtgärd skickas Bulk Överför Förfrågningar till Mottagen Byrå ({ relNo }). Varje sub-Konto Du've Markerad kommer att bli Blind överföring när Mottagen Byrå godkänner den. Vänligen Anteckning att denna Åtgärd är oåterkallelig för varje underkonto som ingår in Överför.",
  "confirmPassword" : "Bekräfta Via att skriva password under",
  "enterPassword" : "Ange lösenord",
  "requestTransfer" : "Förfrågningar Överför",
  "confirmTransfer" : "Bekräfta Överför",
  "notNow" : "Inte nu",
  "invalidRelNo" : "Ogiltig Förhållande siffra",
  "invalidPassword" : "Ogiltigt password.",
  "searchSubAccounts" : "Sök underkonto",
  "searchSubAccountsId" : "Sök efter underkonto- Id",
  "takeAction" : "Vidta Åtgärd",
  "sub_account_details" : "Underprofil",
  "no_of_contacts" : "No av Vänner",
  "transfer_status" : "Överför Status",
  "error_info" : "Fel Info",
  "bulk_transfer_status" : "Bulk Sub-Konto Överför Status",
  "pending" : "Väntar",
  "processed" : "Bearbetad",
  "reject_all" : "Neka varje",
  "accept_all" : "Acceptera varje",
  "cancel_all" : "Avbryt varje",
  "clearAll" : "Rensa varje",
  "refresh" : "Uppdatera",
  "rejected" : "Avböjde",
  "decline_transfer" : "Neka Överför",
  "decline_bulk_transfer" : "Neka Bulk sub-Konto Överför Förfrågningar ?",
  "decline_bulk_transfer_description" : "Förfrågningarna kommer att avvisas och underkontot Samling av {length} kommer inte att bli Blind överföring till ditt Byrå.",
  "accept_bulk_transfer" : "Acceptera Bulk -underKonto Överför Förfrågningar ?",
  "accept_bulk_transfer_description" : "Förfrågningarna kommer att vara Godkänd och underkonton Samling av {length} blir Blind överföring till ditt Byrå.",
  "cancel_bulk_transfer" : "Avbryt Bulk Överför ?",
  "cancel_bulk_transfer_description" : "Är du verkligen säker på att du vill Avbryt denna Bulk Överför av {length} Underkonto till ”{name}”",
  "consent_sub_account_transfer" : "Vänligen Läs och Samtycke till {link}",
  "sub_account_transfer_guidelines" : "Underkonto Överför riktlinjer",
  "valid_rel" : "Ange ett giltigt siffra",
  "something_went_wrong" : "Något gick fel. Försök igen.",
  "export" : "Exportera",
  "export_success" : "Uppgifterna som Du begärde behandlas. Du kommer att Ärv ett E-posta med uppgifterna in något Datum.",
  "export_failed" : "Uppgifterna som Du begärde kunde inte behandlas. Försök igen senare.",
  "selectAllError" : "Du kan endast Välj 1000 Sub Konto vid ett datum.",
  "max1000" : "(Max 1 000)",
  "subAccountCount" : "Underkonto Antal"
}
