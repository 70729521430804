{
  "common" : {
    "thisWeek" : "Denne uken",
    "lastWeek" : "Sist uke",
    "lastNumberOfDays" : "siste {numberOfDays} dager",
    "thisMonth" : "Denne måneden"
  },
  "subAccounts" : "Underkonto",
  "submitFeedback" : "Send inn tilbakemelding",
  "createSubAccount" : "Skape underkonto",
  "viewScheduleReports" : "Se Planlagt rapporter",
  "searchSubAccount" : "Søke Via Underkonto",
  "columns" : "Kolonner",
  "sortBy" : "Sorter etter",
  "selectColumns" : "Velge kolonner",
  "maxColumns" : "Gir åtte kolonner på en Dato",
  "applyColumns" : "Iverksette kolonner",
  "activeUsers" : "Aktive brukere",
  "appointments" : "Avtaler",
  "contacts" : "Kontakter",
  "emails" : "Gmail",
  "textMessages" : "Hoveddel-meldinger",
  "calls" : "Anrop",
  "submissions" : "Innsendinger",
  "reviews" : "Vurderinger",
  "sitesCreated" : "Nettsteder Opprettet",
  "name" : "Navn",
  "formSubmissions" : "Skjema",
  "surveySubmissions" : "Innsendinger av Spørreundersøkelse",
  "facebookFormSubmissions" : "Innsendinger av Facebook-skjema",
  "funnels" : "Trakter",
  "websites" : "Nettsteder",
  "forms" : "Skjemaer",
  "surveys" : "Undersøkelser",
  "highestFirst" : "Maks først",
  "lowestFirst" : "Laveste først",
  "orderBy" : "Sortere Via",
  "noMoreLocations" : "Ikke flere adresse funnet",
  "preparingReport" : "Vi er Laster din rapport",
  "preparingReportDesc" : "Ikke Lukke Eller last inn denne Vindu på nytt mens Eksport in",
  "outOf" : "Fra",
  "subAccountsExported" : "Underkonto eksportert",
  "close" : "Lukk",
  "scheduledForDeletion" : "Planlagt sletting",
  "readyToSell" : "Klar til å selges",
  "closedWon" : "Lukket (vunnet)",
  "wpSaleSuccessful" : "WordPress-salget er vellykket",
  "wpNotSold" : "WordPress ikke solgt ennå",
  "yxtSaleSuccessful" : "Yext-salget er vellykket",
  "yxtNotSold" : "Den neste er ikke solgt ennå",
  "saasSubInactive" : "SaaS avslutningen er inaktiv",
  "saasSubTrialing" : "SaaS Abonnement er på prøveperiode",
  "saasSubActive" : "SaaS avslutningen er dynamisk",
  "emailRebillingActive" : "E-post Omfakturering er dynamisk",
  "emailRebillingInactive" : "E-post Omfakturering er Inaktiv",
  "twilioRebillingActive" : "Twilio Omfakturering er Dynamisk",
  "twilioRebillingInactive" : "Twilio Omfakturering er Inaktiv",
  "wpRebillingActive" : "WhatsApp Omfakturering er dynamisk",
  "wpRebillingInactive" : "WhatsApp Omfakturering er inaktiv",
  "saasModeActivated" : "SaaS -modus er Aktivert",
  "paymentPending" : "Betaling er under behandling",
  "saasModeNotActivated" : "SaaS modus er ikke Aktivert",
  "managedByLCPhone" : "Administrer Via LC Telefon",
  "migrationToLCPhone" : "Under migrering til LC Telefon",
  "notManagedByLCPhone" : "Ikke administrator Via LC Telefon",
  "managedByLCEmail" : "Administrer Via LeadConnector Email System",
  "notMangedByLCEmail" : "Ikke administrator Via LeadConnector Email System",
  "remainingWalletBalance" : "Resterende Lommebok Saldo av denne SaaS Sted",
  "reports" : "Rapporter",
  "switchToSubAccount" : "Bytt til underkonto",
  "manageClient" : "Administrere klient",
  "subAccountDeletedIfChangedMind" : "Denne underkontoen vil være Slettet På {time}, Hvis Du've Dynamisk your mind",
  "subAccountDeletedInMinute" : "Denne underkontoen vil være Slettet i løpet av neste minutt",
  "changedMind" : "Hvis Du've Dynamisk your mind",
  "clickHere" : "klikk henne",
  "subAccountPaused" : "Denne underkontoen er Satt på pause. Kunden din kan ikke logge inn, men Byrå kan. Til Gjenoppta denne Konto",
  "subAccountRestricted" : "Denne underkontoen er Satt på pause Skyldig til pågående Godkjenning.",
  "subAccountVerificationPending" : "Denne underkontoen er Satt på pause Skyldig til ventende e-post- og telefonverifisering fra Admin.",
  "subAccountDeclined" : "Denne underkontoen er Satt på pause Skyldig til avslått Godkjenning Forespørsler. Du kan fortsatt Gjenoppta denne Under-Konto Fra ",
  "here" : "her.",
  "reviewAndResume" : "til Vurdering og Gjenoppta denne Underkonto.",
  "incomingCalls" : "Innkommende anrop",
  "outgoingCalls" : "Utgående anrop",
  "totalTalktime" : "Total taletid",
  "incomingEmails" : "Innkommende Gmail",
  "outgoingEmails" : "Utgående Gmail",
  "incomingSms" : "Innkommende Hoveddel-meldinger",
  "outgoingSms" : "Utgående Hoveddel-meldinger",
  "contactsCreated" : "tall av Venner Opprettet",
  "appointmentsBooked" : "tall av Avtale booket",
  "totalFormSubmissions" : "Total Skjema-innleveringer",
  "totalSurveySubmissions" : "Total Spørreundersøkelsesinnsendinger",
  "totalFacebookFormSubmissions" : "Total Facebook-skjema-innsendinger",
  "incomingGMBMessages" : "Innkommende GBP-meldinger",
  "outgoingGMBMessages" : "Utgående meldinger i GBP",
  "gmbMessages" : "GBP-meldinger",
  "fbMessages" : "Facebook-meldinger",
  "incomingFbMessages" : "Innkommende Facebook-meldinger",
  "outgoingFbMessages" : "Utgående Facebook-meldinger",
  "igMessages" : "Instagram-meldinger",
  "incomingIgMessages" : "Innkommende Instagram-meldinger",
  "outgoingIgMessages" : "Utgående Instagram-meldinger",
  "totalReviews" : "Total antall anmeldelser",
  "positiveReviews" : "Positive anmeldelser",
  "negativeReviews" : "Negative anmeldelser",
  "back" : "Tilbake",
  "selectDashboards" : "Velge dashbord",
  "next" : "Neste",
  "backToSubAccounts" : "Tilbake til underkonto",
  "pauseSchedule" : "Sett plan på Pause",
  "resumeSchedule" : "Gjenoppta plan",
  "editSchedule" : "Redigere-plan",
  "addSchedule" : "Legge til plan",
  "selectReportToAdd" : "Velge rapporterer til Inkludere in e-posten",
  "selectAll" : "Velge hver eneste",
  "pauseScheduleConfirm" : "Er du sikker på at du vil sette rapportplanen på Pause ?",
  "resumeScheduleConfirm" : "Er du sikker på at du vil Gjenoppta rapportere planen?",
  "tasks" : "Oppgaver",
  "conversations" : "Samtaler",
  "users" : "Bruker",
  "dealOverview" : "Oversikt over Avtale",
  "confirmAction" : "Bekreft Handling",
  "yes" : "Ja",
  "no" : "Nei",
  "reportDetails" : "Detaljer",
  "reportCreatedOn" : "Rapporter Opprettet På",
  "searchByEmail" : "Søke Via E-post",
  "lastActivity" : "siste Aktivitet",
  "noRecords" : "Ingen Oppføringer funnet",
  "scheduleReport" : "planrapport",
  "schedule" : "plan",
  "save" : "Lagre",
  "emailSubject" : "Emnelinje",
  "frequency" : "Hyppighet",
  "daily" : "Daglig",
  "weekly" : "Ukentlig",
  "monthly" : "Månedlig",
  "nextScheduleDate" : "Neste plan Dato",
  "startingOn" : "Starter på",
  "timeOfDay" : "Dato av Dag",
  "reportsToTimezone" : "Rapport vil bli sendt in underkontoens tidssone",
  "selectRecipients" : "Velge mottakere",
  "invalidEmail" : "Du har angitt en ugyldig e-postadresse",
  "testEmail" : "Test e-post",
  "selectTestRecipients" : "Velge Test",
  "setupSubject" : "Konfigurer temaet ovenfor for å Test rapporten",
  "sendTest" : "Siste opp- Test",
  "scheduleSimilarSchedules" : "planlegge tilsvarende rapport for Annen/annet Underkonto",
  "accountSummary" : "Konto Sammendrag",
  "scheduleList" : "plan Liste",
  "subAccountName" : "Navn på underkonto",
  "nextRunDate" : "Neste Dato",
  "reportSectionsAdded" : "Rapportsegmenter lagt til",
  "status" : "Status",
  "scheduleAction" : "planhåndtering",
  "cancel" : "Kanseller",
  "pleaseWait" : "Vente vent",
  "confirm" : "Bekreft",
  "booked" : "Bestilt",
  "confirmed" : "Bekreftet",
  "showed" : "Møtte opp",
  "noShow" : "Ikke møtt",
  "cancelled" : "Kansellert",
  "unread" : "Ulest",
  "engagement" : "Engasjement",
  "reaction" : "Reaksjon",
  "deliverySummary" : "Sammendrag av Leveranse",
  "successfulDelivery" : "Vellykket Leveranse",
  "opened" : "Åpnet",
  "clicked" : "Klikket på",
  "replied" : "Besvarte",
  "unsubscribed" : "Avmeldt",
  "complained" : "Klaget",
  "failed" : "Mislyktes",
  "open" : "Åpne",
  "won" : "Vunnet",
  "abandoned" : "Forlatt",
  "lost" : "Tapt",
  "sent" : "Sendt",
  "eject" : "Løs ut",
  "delivered" : "Sendt",
  "faceBookLeadForm" : "Facebook- Skjema",
  "overdue" : "Forsinket",
  "reviewsReceived" : "Vurderinger Mottatt",
  "formBuilders" : "Skjema",
  "switchToSaaS" : "Bytt til SaaS",
  "numberOfContacts" : "høy av Venner",
  "numberOfAppointments" : "høy av Avtale",
  "outreach" : "Oppsøkende virksomhet",
  "notSubAccountUser" : "Ikke en underkontobruker",
  "searchSubAccName" : "Søke Via Sub-Kontos navn",
  "recipients" : "Mottakere",
  "scheduleCreated" : "E-post Rapporter plan Opprettet for ",
  "scheduleUpdated" : "E-post Rapporter plan Oppdatert for ",
  "scheduleNotCreated" : "E-post Rapportplan kunne ikke opprettes for",
  "scheduleNotUpdated" : "E-post Rapportplan kunne ikke Oppdatert for",
  "total" : "Total",
  "inbound" : "Innkommende",
  "outbound" : "Utgående",
  "totalEmails" : "Total Gmail",
  "inboundEmails" : "Innkommende Gmail",
  "outboundEmails" : "Utgående Gmail",
  "totalTextMessages" : "Total Hoveddel-meldinger",
  "inboundTextMessages" : "Innkommende Hoveddel-meldinger",
  "outboundTextMessages" : "Utgående Hoveddel-meldinger",
  "totalCalls" : "Total antall anrop",
  "inboundCalls" : "Innkommende anrop",
  "outboundCalls" : "Utgående anrop",
  "faceBookLeadFormSubmissions" : "Innsendinger av Facebook- Skjema",
  "inboundGMBMessages" : "Innkommende GBP-meldinger",
  "outboundGMBMessages" : "Utgående meldinger i GBP",
  "totalIgMessages" : "Total Instagram-meldinger",
  "inboundIgMessages" : "Innkommende Instagram-meldinger",
  "outboundIgMessages" : "Utgående Instagram-meldinger",
  "totalfbMessages" : "Total Facebook-meldinger",
  "inboundfbMessages" : "Innkommende Facebook-meldinger",
  "outboundfbMessages" : "Utgående Facebook-meldinger",
  "sitesCreatedFunnels" : "Opprettet nettsteder – Trakter",
  "sitesCreatedActiveFunnels" : "Nettsteder Opprettet - Dynamisk Trakter",
  "sitesCreatedWebsites" : "Opprettet nettsteder – nettsteder",
  "sitesCreatedActiveWebsites" : "Nettsteder Opprettet - Dynamisk nettsteder",
  "sitesCreatedForms" : "Opprettet nettsteder – skjemaer",
  "sitesCreatedActiveForms" : "Nettsteder Opprettet - Dynamisk Skjemaer",
  "sitesCreatedSurveys" : "Opprettet nettsteder – undersøkelser",
  "sitesCreatedActiveSurveys" : "Nettsteder Opprettet - Dynamisk Surveys",
  "googleReviews" : "Google-anmeldelser",
  "facebookReviews" : "Facebook-anmeldelser",
  "inboundCallDuration" : "Varighet for innkommende anrop (tid)",
  "outboundCallDuration" : "Utgående samtale Varighet (tid)",
  "pleaseSelect" : "Velge",
  "subAccountIsDeleted" : "Denne underkontoen har blitt Slettet",
  "subAccountIsPaused" : "Denne underkontoen har vært satt på pause",
  "success" : "Vellykket",
  "error" : "Feil",
  "testEmailSent" : "Test e-post sendt",
  "testEmailNotSent" : "Test e-posten kunne ikke sendes",
  "reviewRequest" : "Vurdering Forespørsler",
  "pendingTransferDescription" : "Annen/annet Byråvisning har bedt om å overføre Underkonto til Du.",
  "pendingTransferHeading" : "Du har pågående Under-Konto Overføre Forespørsler.",
  "subAccountTransfer" : "Underkonto Last ned",
  "manageSubAccTransfer" : "Administrer din Underkonto Overføre Forespørsler",
  "subAccRequest" : "Underkonto {tabName} Forespørsler",
  "receive" : "Arve",
  "send" : "Send",
  "release" : "Versjon",
  "requests" : "{count} Forespørsler",
  "subAccLimit" : "SLOTMAX for underkontobruk er nådd.",
  "subAccPlanLimit" : "Du har brukt 3/3 tillatt underkonto under Nåværende Plan din .",
  "upgradeTime" : "Det er Dato for en oppgradere!",
  "upgradeHigher" : "Vennligst Oppgradere til en høyere Plan for å godta Sub-Konto Overføre.",
  "browse" : "Bla gjennom abonnementer",
  "pendingTransferTitle" : "En Overføre Forespørsler for denne underkontoen er allerede under behandling.",
  "unableTransfer" : "Kan ikke overføre underkonto til denne Byrå.",
  "unableEjectCancel" : "Kan ikke Kansellere underkonto sende ut Forespørsler",
  "EjectErrorDetails" : "Under-Konto-utkastet Forespørsler som Du prøver å Kansellere kan ikke kanselleres.",
  "hippaErrorDescription" : "Underkontoen du prøver å overføre er HIPAA Aktivert, og kan ikke lastes ned til et ikke-HIPAA Byrå.",
  "pendingTransferErrorDescription" : "Vennligst Kansellere den allerede Nåværende Overføre Forespørsler for denne UnderKonto Før fortsetter til Heve en ny.",
  "receivingAgency" : "Mottatt Byrå",
  "releasingAgency" : "Utstedt Byrå",
  "noOfContacts" : "Nr av Venner",
  "requestedDate" : "Ønsket Dato",
  "action" : "Handling",
  "cancelTransfer" : "Kansellere Overføre",
  "decline" : "Avvise",
  "cancelTransferRequest" : "Kansellere Under-Konto Overføre Forespørsler?",
  "cancelEjectRequest" : "Kansellere Sub-Konto eject Forespørsler?",
  "cancelRequestDescription" : "Mottatt Byrå vil ikke lenger se denne Overføre Forespørsler, og underkontoen {name} vil ikke bli lastet ned.",
  "cancelEjectRequestDescription" : "Valgt Bruker vil ikke lenger kunne Skape ny Byrå Fra underkontoen {name}.",
  "declineRequest" : "Avvise Sub-Konto Overføre Forespørsler?",
  "declineRequestDescription" : "Forespørsleren vil bli avvist, og underkontoen {name} blir ikke lastet ned til Byråen din.",
  "approveTransfer" : "Godkjenne Sub-Konto Overføre til ditt Byrå?",
  "approveTransferDescription" : "{name} Underkonto vil bli lastet ned til Byrå.",
  "transferNow" : "Overføre nå",
  "cancelNow" : "Kansellere nå",
  "transferErrorDetails" : "Underkontoen som Du prøver å overføre kan ikke lastes ned",
  "cancelRequest" : "Kansellere Forespørsler",
  "received" : "Mottatt",
  "approve" : "Godkjenne",
  "goBack" : "Angre",
  "done" : "Ferdig",
  "subAccountTransferred" : "Underkontoen har blitt lastet ned.",
  "subAccountTransferDescription" : "Vær oppmerksom på at Jevn selv om underkontoen er lastet ned, Mailgun kan det fortsatt ta 10–15 minutter å gjenspeile in seg destinasjonen for noen eiendeler som Bruker, Telefonnummer, Konto, Yext-oppføringer osv.",
  "activeSubscriptionsAlert" : "Stedet har Dynamisk Abonnement. Please Kansellere hver eneste Abonnement Før Blind overføring the Sted. Liste av Dynamisk Abonnement: {subscriptions}",
  "isSaasEnabled" : "SaaS Aktivert",
  "typeToSearch" : "Skriv inn for å søke",
  "dateAdded" : "Dato lagt til",
  "emailEngagement" : "E-post postengasjement",
  "emailReaction" : "E-post",
  "emailDeliverySummary" : "Sammendrag av Leveranse av E-post",
  "facebook" : "Facebook",
  "sms" : "SMS",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "Konto Sammendrag – {subAccountName}",
  "test" : "Test",
  "scheduleExists" : "planen finnes",
  "aToZ" : "A–Å",
  "zToA" : "Å – A",
  "onlyAdminUsersCanAddNewSubAccounts" : "Kun Admin Bruker kan inkludere nye underkontoer.",
  "locationName" : "Sted",
  "transferCompleted" : "Overføre Forespørsler for dette Stedet er allerede behandlet. Oppdater.",
  "unauthorizedTransfer" : "Du er ikke autorisert til å utføre denne håndteringen. Kun Byrå-eiere kan utføre denne håndteringen.",
  "bulkLocationTransfer" : "Bulk Overføre Sub Konto",
  "singleRequest" : "Individuelle Forespørsler",
  "bulkRequest" : "Bulk Forespørsler",
  "backToLocationTransfer" : "Tilbake til Sted Overføre",
  "initiateBulkTransfer" : "trigger Bulk Under-Konto Overføre",
  "initiateBulkTransferDescription" : "Vennligst velg underkontoen du ønsker å overføre, eller skriv inn ID-ene til underkontoen du ønsker å overføre, separert Via komma.",
  "subAccountId" : "Underkonto- Id",
  "selectFromList" : "Velge Fra Liste",
  "addManually" : "Legge til manuelt",
  "accountsSelected" : "{count} Valgt",
  "selectAllSubAccounts" : "Velge hver eneste",
  "removeAll" : "Ekstrahere hver eneste",
  "addManualLocations" : "Legge til Sted Id 's med Separert Via komma",
  "manualAllLocationIds" : "Sted ID-er av under-Konto Du ønsker å overføre, separert Via komma.",
  "enterLocationIds" : "Skriv inn Sted- Id -er",
  "selectSubAccounts" : "Velge Under-Konto Og Overføre til et Nåværende Byrå",
  "subAccountTransferWarning" : "hver eneste underKonto-aktiva og Bruker vil få Laste ned.",
  "proceed" : "Fortsette",
  "bulkRequestSubmitted" : "Bulk Overføre Forespørsler Innsendt",
  "bulkRequestSubmittedDescription" : "hver eneste underkonto vil bli lastet ned når Mottatt Byrå godtar Overføre Forespørsler. Du kan spore Status til Forespørslerne på ❝{pagename}❞-siden. (Utstedt er de kvalifisert)",
  "bulkTransferRequestFailed" : "Bulk Overføre Forespørsler mislyktes",
  "errorLocations" : "Underkonto med feil",
  "viewDetails" : "Vis detaljer",
  "somethingWrong" : "Noe gikk galt. Vennligst prøv igjen.",
  "confirmBulkRequest" : "Bekreft Bulk Overføre Forespørsler?",
  "bulkTransferAccounts" : "Bulk Overføre «{count}» Valgt underkonto ?",
  "confirmDetails" : "Vennligst bekreft Detaljer Før Blind overføring",
  "transferConsent" : "Jeg har Lese og forstått veiledningen ovenfor og Tillatelse til hver eneste begrensningene.",
  "transferInfo" : "Sub Konto Overføre er tema for at Mottatt Byrå tar det inn på kontoen sin.",
  "receivingRelNo" : "Mottatt Byrås Forhold høy",
  "enterRelNo" : "Skriv inn forhold høy",
  "transferConfirmation" : "Etter at denne håndteringen er fullført, vil Bulk Overføre Forespørsler bli sendt til Mottatt Byrå ({ relNo }). hver eneste underKonto Du've Valgt vil bli lastet ned når Mottatt Byrå godtar den. Vær oppmerksom på at denne håndteringen er irreversibel for hver eneste underkonto som er inkludert in Overføret.",
  "confirmPassword" : "Bekreft Ved å skrive password under",
  "enterPassword" : "Skriv inn passord",
  "requestTransfer" : "Forespørsler Overføre",
  "confirmTransfer" : "Bekreft Overføre",
  "notNow" : "Ikke nå",
  "invalidRelNo" : "Ugyldig Forhold høy",
  "invalidPassword" : "Ugyldig password.",
  "searchSubAccounts" : "Søke Underkonto",
  "searchSubAccountsId" : "Søke Underkonto- Id",
  "takeAction" : "Handling",
  "sub_account_details" : "Underprofil",
  "no_of_contacts" : "Nei av Venner",
  "transfer_status" : "Overføre Status",
  "error_info" : "Info",
  "bulk_transfer_status" : "Bulk underkonto overføre- Status",
  "pending" : "I påvente av",
  "processed" : "Behandlet",
  "reject_all" : "Avvise hver eneste",
  "accept_all" : "Godta hver eneste",
  "cancel_all" : "Kansellere hver eneste",
  "clearAll" : "Fjern hver eneste",
  "refresh" : "Oppdater",
  "rejected" : "Nektet",
  "decline_transfer" : "Avvise Overføre",
  "decline_bulk_transfer" : "Avvise Bulk underKonto Overføre Forespørsler ?",
  "decline_bulk_transfer_description" : "Forespørsleren vil bli avvist, og Samling av {length} underkonto vil ikke bli lastet ned til Byråen din.",
  "accept_bulk_transfer" : "Godta Bulk underKonto Overføre Forespørsler ?",
  "accept_bulk_transfer_description" : "Forespørsleren vil være Godkjent og Samling av {length} underkonto vil bli lastet ned til din Byrå.",
  "cancel_bulk_transfer" : "Kansellere Bulk Overføre ?",
  "cancel_bulk_transfer_description" : "Er du virkelig sikker på at du vil kansellere denne Bulk -overføre av {length} underkonto til « {name} »",
  "consent_sub_account_transfer" : "Vennligst Lese og Tillatelse til {link}",
  "sub_account_transfer_guidelines" : "Retningslinjer for underkonto overføre",
  "valid_rel" : "Skriv inn gyldig tall",
  "something_went_wrong" : "Noe gikk galt. Vennligst prøv igjen.",
  "export" : "Eksporter",
  "export_success" : "Opplysningene som Du ba om, er under behandling. Du vil sende en e-post med dataene in noen Dato.",
  "export_failed" : "Dataene Du ba om kunne ikke behandles. Vennligst prøv igjen senere.",
  "selectAllError" : "Du kan bare velge 1000 Sub Konto på en Dato.",
  "max1000" : "(Maks 1000)",
  "subAccountCount" : "Underkonto Antall"
}
