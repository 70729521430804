{
  "common" : {
    "thisWeek" : "Esta semana",
    "lastWeek" : "Semana passada",
    "lastNumberOfDays" : "últimos {numberOfDays} dias",
    "thisMonth" : "Este mês"
  },
  "subAccounts" : "Subconta",
  "submitFeedback" : "Enviar comentários",
  "createSubAccount" : "Criar Subconta",
  "viewScheduleReports" : "Ver relatórios Agendado",
  "searchSubAccount" : "Pesquisar Via subconta",
  "columns" : "Colunas",
  "sortBy" : "Ordenar por",
  "selectColumns" : "Selecionar colunas",
  "maxColumns" : "Máximo de 8 colunas num Data",
  "applyColumns" : "Aplicar colunas",
  "activeUsers" : "Utilizadores ativos",
  "appointments" : "Compromissos",
  "contacts" : "Contactos",
  "emails" : "Gmail",
  "textMessages" : "Mensagens do corpo",
  "calls" : "Chamadas",
  "submissions" : "Envios",
  "reviews" : "Avaliações",
  "sitesCreated" : "Sítios Criado",
  "name" : "Nome",
  "formSubmissions" : "Submissões de Formulário",
  "surveySubmissions" : "Submissões do Inquérito",
  "facebookFormSubmissions" : "Submissões de Formulário do Facebook",
  "funnels" : "Funis",
  "websites" : "Sítios Web",
  "forms" : "Formulários",
  "surveys" : "Inquéritos",
  "highestFirst" : "Máximo primeiro",
  "lowestFirst" : "O mais baixo primeiro",
  "orderBy" : "Ordenar Via",
  "noMoreLocations" : "Não mais endereço encontrado",
  "preparingReport" : "Estamos A carrregar... o seu relatório",
  "preparingReportDesc" : "Não fechar ou recarregar esta janela enquanto a exportação estiver in curso",
  "outOf" : "De",
  "subAccountsExported" : "Subconta exportada",
  "close" : "Fechar",
  "scheduledForDeletion" : "Agendado para eliminação",
  "readyToSell" : "Pronto a vender",
  "closedWon" : "Fechado (Ganho)",
  "wpSaleSuccessful" : "A venda do WordPress foi bem-sucedido",
  "wpNotSold" : "WordPress ainda não vendido",
  "yxtSaleSuccessful" : "A venda do Yext foi bem-sucedido",
  "yxtNotSold" : "Yext ainda não vendido",
  "saasSubInactive" : "A assinatura SaaS é inativa",
  "saasSubTrialing" : "SaaS Assinatura está a avaliar",
  "saasSubActive" : "SaaS Assinatura é Dinâmico",
  "emailRebillingActive" : "E-mail Faturação recorrente é Dinâmico",
  "emailRebillingInactive" : "E-mail A Faturação recorrente é Inativo",
  "twilioRebillingActive" : "Twilio Faturação recorrente é Dinâmico",
  "twilioRebillingInactive" : "Twilio Faturação recorrente é Inativo",
  "wpRebillingActive" : "WhatsApp Faturação recorrente é Dinâmico",
  "wpRebillingInactive" : "A Faturação recorrente WhatsApp é Inativo",
  "saasModeActivated" : "O modo SaaS é Ativado",
  "paymentPending" : "O pagamento está pendente",
  "saasModeNotActivated" : "O modo SaaS não é Ativado",
  "managedByLCPhone" : "Gerir Via LC Telefone",
  "migrationToLCPhone" : "Em migração para o Telefone LC",
  "notManagedByLCPhone" : "Não Gerir Via LC Telefone",
  "managedByLCEmail" : "Gerir Via LeadConnector Email System",
  "notMangedByLCEmail" : "Não Gerir Via LeadConnector Email System",
  "remainingWalletBalance" : "Restante Carteira Saldo desta localização SaaS",
  "reports" : "Relatórios",
  "switchToSubAccount" : "Mudar para Subconta",
  "manageClient" : "Gerir cliente",
  "subAccountDeletedIfChangedMind" : "Esta Subconta será Eliminado Ativado {time}, Se utilizador've Dinâmico a sua mente",
  "subAccountDeletedInMinute" : "Esta Subconta será Eliminada no Seguinte minuto",
  "changedMind" : "Se utilizador've o Dinâmico a sua mente",
  "clickHere" : "clicar aqui",
  "subAccountPaused" : "Esta Subconta está Em pausa. Os seus Clientes não podem Iniciar sessão, mas a Agência pode. Para Retomar esta Conta",
  "subAccountRestricted" : "Esta Subconta está em pausa devido a Aprovação pendente.",
  "subAccountVerificationPending" : "Esta Sub-conta está em pausa devido a uma verificação pendente de e-mail e telefone De Sub-conta Administrador.",
  "subAccountDeclined" : "Esta Subconta está Em pausa devido a Aprovação Pedidos recusados. o utilizador ainda pode Retomar esta Subconta De ",
  "here" : "aqui.",
  "reviewAndResume" : "para Revisão e Retomar esta Subconta.",
  "incomingCalls" : "Chamadas recebidas",
  "outgoingCalls" : "Chamadas efetuadas",
  "totalTalktime" : "Tempo Total de conversa",
  "incomingEmails" : "Recebidas Gmail",
  "outgoingEmails" : "Gmailde saída",
  "incomingSms" : "Mensagens Corpo recebidas",
  "outgoingSms" : "Mensagens de saída do Corpo",
  "contactsCreated" : "número de Amigos Criado",
  "appointmentsBooked" : "número de compromisso reservado",
  "totalFormSubmissions" : "Total submissões de Formulário",
  "totalSurveySubmissions" : "Total de envios de Inquérito",
  "totalFacebookFormSubmissions" : "Total de submissões do Formulário do Facebook",
  "incomingGMBMessages" : "Mensagens GBP recebidas",
  "outgoingGMBMessages" : "Mensagens GBP de saída",
  "gmbMessages" : "Mensagens GBP",
  "fbMessages" : "Mensagens do Facebook",
  "incomingFbMessages" : "Mensagens recebidas do Facebook",
  "outgoingFbMessages" : "Mensagens de saída do Facebook",
  "igMessages" : "Mensagens do Instagram",
  "incomingIgMessages" : "Mensagens recebidas do Instagram",
  "outgoingIgMessages" : "Mensagens de saída do Instagram",
  "totalReviews" : "Total de revisões",
  "positiveReviews" : "Avaliações Positivas",
  "negativeReviews" : "Avaliações Negativas",
  "back" : "Voltar",
  "selectDashboards" : "Selecionar painéis de controlo",
  "next" : "Seguinte",
  "backToSubAccounts" : "Voltar a Sub-conta",
  "pauseSchedule" : "Pausa agenda",
  "resumeSchedule" : "Retomar agenda",
  "editSchedule" : "Editar agenda",
  "addSchedule" : "Adicionar agenda",
  "selectReportToAdd" : "Selecionar relatórios para Incluir in E-mail",
  "selectAll" : "selecionar todos",
  "pauseScheduleConfirm" : "O utilizador tem a certeza de que o utilizador pretende Pausar a agenda do relatório?",
  "resumeScheduleConfirm" : "O utilizador tem a certeza de que o utilizador pretende Retomar a agenda do relatório?",
  "tasks" : "Tarefas",
  "conversations" : "Conversas",
  "users" : "Utilizador",
  "dealOverview" : "Visão geral do Negócio",
  "confirmAction" : "Confirmar Ação",
  "yes" : "Sim",
  "no" : "Não",
  "reportDetails" : "Detalhes do Relatório",
  "reportCreatedOn" : "Relatório Criado Ativado",
  "searchByEmail" : "Pesquisar por e-mail",
  "lastActivity" : "última Atividade",
  "noRecords" : "Nenhum Registos encontrado",
  "scheduleReport" : "agenda Relatório",
  "schedule" : "agenda",
  "save" : "Guardar",
  "emailSubject" : "Linha de assunto",
  "frequency" : "Frequência",
  "daily" : "diário",
  "weekly" : "Semanal",
  "monthly" : "Mensal",
  "nextScheduleDate" : "Seguinte agenda Dados",
  "startingOn" : "Iniciar o Ativado",
  "timeOfDay" : "Data de Dia",
  "reportsToTimezone" : "O relatório será enviado in fuso horário da Sub-conta",
  "selectRecipients" : "Selecionar destinatários",
  "invalidEmail" : "utilizador introduziu um endereço de e-mail inválido",
  "testEmail" : "E-mail de Testar",
  "selectTestRecipients" : "Selecionar destinatários do Testar",
  "setupSubject" : "Configure o Assunto acima para Testar o relatório",
  "sendTest" : "Carregar Testar",
  "scheduleSimilarSchedules" : "agenda relatório semelhante para Outros Sub-conta",
  "accountSummary" : "Resumo da Conta",
  "scheduleList" : "agenda Lista",
  "subAccountName" : "Nome da subconta",
  "nextRunDate" : "Seguinte Data de execução",
  "reportSectionsAdded" : "Segmentos de relatório adicionados",
  "status" : "Estado",
  "scheduleAction" : "agenda Ação",
  "cancel" : "Cancelar",
  "pleaseWait" : "Esperar",
  "confirm" : "Confirmar",
  "booked" : "Marcado(a)",
  "confirmed" : "Confirmado(a)",
  "showed" : "Mostrado",
  "noShow" : "Não comparência",
  "cancelled" : "Cancelado",
  "unread" : "Não lido",
  "engagement" : "Compromisso",
  "reaction" : "Reação",
  "deliverySummary" : "Resumo da Entrega",
  "successfulDelivery" : "Entrega bem-sucedido",
  "opened" : "Aberto",
  "clicked" : "Clicado",
  "replied" : "Respondidas",
  "unsubscribed" : "com subscrição anulada",
  "complained" : "reclamou",
  "failed" : "Falhado",
  "open" : "Abrir",
  "won" : "Ganhas",
  "abandoned" : "Abandonado(a)",
  "lost" : "Perdido(a)",
  "sent" : "Enviado",
  "eject" : "Ejetar",
  "delivered" : "Enviado",
  "faceBookLeadForm" : "Formulário de Lead do Facebook",
  "overdue" : "Vencido",
  "reviewsReceived" : "Avaliações recebidas",
  "formBuilders" : "Criadores de Formulário",
  "switchToSaaS" : "Mudar para SaaS",
  "numberOfContacts" : "número de Amigos",
  "numberOfAppointments" : "número de compromisso",
  "outreach" : "Alcance",
  "notSubAccountUser" : "Não é um utilizador de subconta",
  "searchSubAccName" : "Pesquisar Via nome da sub-conta",
  "recipients" : "Destinatários",
  "scheduleCreated" : "Agenda de relatórios por e-mail Criado para ",
  "scheduleUpdated" : "Agenda de relatórios por e-mail Atualizado para ",
  "scheduleNotCreated" : "Não foi possível criar a agenda do relatório de e-mail para",
  "scheduleNotUpdated" : "A agenda do Relatório de e-mail não pode ser Atualizada para",
  "total" : "Total",
  "inbound" : "Recebidas",
  "outbound" : "de saída",
  "totalEmails" : "Total Gmail",
  "inboundEmails" : "Gmailde entrada",
  "outboundEmails" : "de saída Gmail",
  "totalTextMessages" : "Total de mensagens do Corpo",
  "inboundTextMessages" : "Mensagens do Corpo de entrada",
  "outboundTextMessages" : "de saída Mensagens do corpo",
  "totalCalls" : "Total de Chamadas",
  "inboundCalls" : "Chamadas recebidas",
  "outboundCalls" : "Chamadas de saída",
  "faceBookLeadFormSubmissions" : "Submissões do Formulário de Lead do Facebook",
  "inboundGMBMessages" : "Mensagens GBP de entrada",
  "outboundGMBMessages" : "Mensagens GBP de saída",
  "totalIgMessages" : "Total de mensagens do Instagram",
  "inboundIgMessages" : "Mensagens do Instagram de entrada",
  "outboundIgMessages" : "Mensagens do Instagram de saída",
  "totalfbMessages" : "Total de mensagens do Facebook",
  "inboundfbMessages" : "Mensagens de entrada do Facebook",
  "outboundfbMessages" : "Mensagens de saída do Facebook",
  "sitesCreatedFunnels" : "Sites Criado – Funis",
  "sitesCreatedActiveFunnels" : "Sites criados - Dinâmico Funis",
  "sitesCreatedWebsites" : "Sítios Criado – Sítios Web",
  "sitesCreatedActiveWebsites" : "Sites criados - Websites Dinâmico",
  "sitesCreatedForms" : "Sites Criado – Formulários",
  "sitesCreatedActiveForms" : "Sites criados - Formulários Dinâmicos",
  "sitesCreatedSurveys" : "Sites Criado – Inquéritos",
  "sitesCreatedActiveSurveys" : "Sites criados - Dinâmico Inquéritos",
  "googleReviews" : "Google Avaliações",
  "facebookReviews" : "Avaliações do Facebook",
  "inboundCallDuration" : "Duração da chamada recebida (hora)",
  "outboundCallDuration" : "de saída Duração da chamada (hora)",
  "pleaseSelect" : "Selecionar",
  "subAccountIsDeleted" : "Esta Subconta foi Eliminada",
  "subAccountIsPaused" : "Esta Subconta está Em pausa",
  "success" : "Sucesso",
  "error" : "Erro",
  "testEmailSent" : "E-mail de Testar enviado",
  "testEmailNotSent" : "Não foi possível enviar o e-mail do Testar",
  "reviewRequest" : "Pedidos de Revisão",
  "pendingTransferDescription" : "Outros Vista de agência solicitaram para Transferir Sub-conta para utilizador.",
  "pendingTransferHeading" : "utilizador've tem pedidos pendentes de transferência de subconta.",
  "subAccountTransfer" : "Subconta Descarregar",
  "manageSubAccTransfer" : "Gerir os seus Pedidos de Transferência Sub-conta",
  "subAccRequest" : "Sub-conta {tabName} Pedidos",
  "receive" : "Herdar",
  "send" : "Enviar",
  "release" : "Versão",
  "requests" : "{count} Pedidos",
  "subAccLimit" : "Utilização de Subconta SLOTMAX atingido!",
  "subAccPlanLimit" : "o utilizador tiver utilizado 3/3 da Subconta permitida no seu Plano Atual.",
  "upgradeTime" : "São os dados para um upgrade da Fazer!",
  "upgradeHigher" : "Faça upgrade para um Plano superior para aceitar a Sub-conta Transferir.",
  "browse" : "Procurar Planos",
  "pendingTransferTitle" : "Um pedido de transferência para este subconta já está pendente.",
  "unableTransfer" : "Não é possível transferir a subconta para esta agência.",
  "unableEjectCancel" : "Não é possível cancelar a subconta e ejetar pedidos",
  "EjectErrorDetails" : "Os Pedidos de ejeção de Subconta que o utilizador está a tentar Cancelar não podem ser cancelados.",
  "hippaErrorDescription" : "O Subconta que o utilizador está a tentar transferir é o HIPAA Ativado, e não pode ser Descarregado para uma Agência não HIPAA.",
  "pendingTransferErrorDescription" : "Por favor, cancele os pedidos de transferência já atuais para esta subconta antes de prosseguir para elevar um novo.",
  "receivingAgency" : "Recebido Agência",
  "releasingAgency" : "Agência Emido",
  "noOfContacts" : "N.º de Amigos",
  "requestedDate" : "Data solicitada",
  "action" : "Ação",
  "cancelTransfer" : "Cancelar Transferir",
  "decline" : "Rejeitar",
  "cancelTransferRequest" : "Cancelar Sub-conta Transferir pedidos?",
  "cancelEjectRequest" : "Cancelar Sub-conta e ejetar pedidos?",
  "cancelRequestDescription" : "A Agência Recebida não irá mais ver este Transferir Pedidos, e a Sub-conta {name} não será Descarregar.",
  "cancelEjectRequestDescription" : "O Utilizador Selecionado não poderá mais Criar nova Agência De subconta {name}.",
  "declineRequest" : "Rejeitar Sub-conta transferir pedidos?",
  "declineRequestDescription" : "Os Pedidos serão recusados e a Sub-conta {name} não será Descarregada na sua Agência.",
  "approveTransfer" : "Aprovar Sub-conta Transferir para a sua agência?",
  "approveTransferDescription" : "{name} A Sub-conta será descarregada na sua agência.",
  "transferNow" : "Transferir agora",
  "cancelNow" : "Cancelar agora",
  "transferErrorDetails" : "A Sub-conta que o utilizador está a tentar transferir não pode ser Descarregar",
  "cancelRequest" : "Cancelar Pedidos",
  "received" : "Recebido",
  "approve" : "Aprovar",
  "goBack" : "Anular",
  "done" : "Concluído",
  "subAccountTransferred" : "A Sub-conta foi descarregada com sucesso!",
  "subAccountTransferDescription" : "Tenha em atenção que o Uniforme, embora a Sub-conta tenha sido descarregada, alguns ativos Gosto Utilizador, Número de telefoneMailgun Conta, Yext Listings etc.in",
  "activeSubscriptionsAlert" : "A Localização tem Dinâmico Assinatura. Cancele todas as Assinatura Antes de Transferência cega a Localização. Lista de Dinâmico Assinatura: {subscriptions}",
  "isSaasEnabled" : "SaaS Ativado",
  "typeToSearch" : "Escreva para pesquisar",
  "dateAdded" : "Data adicionada",
  "emailEngagement" : "Compromisso E-mail",
  "emailReaction" : "Reação E-mail",
  "emailDeliverySummary" : "Resumo da Entrega E-mail",
  "facebook" : "Facebook",
  "sms" : "SMS",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "Resumo da Conta – {subAccountName}",
  "test" : "Testar",
  "scheduleExists" : "agenda Existe",
  "aToZ" : "A - Z",
  "zToA" : "Z - A",
  "onlyAdminUsersCanAddNewSubAccounts" : "Apenas o Administrador Utilizador pode Incluir novas subcontas.",
  "locationName" : "Nome da Localização",
  "transferCompleted" : "A transferência de pedidos para esta localização já está processada. Faça a atualização.",
  "unauthorizedTransfer" : "o utilizador não está autorizado a executar esta Ação. Apenas proprietários de Agências podem executar esta Ação.",
  "bulkLocationTransfer" : "em massa Transferir Sub Conta",
  "singleRequest" : "Pedidos Individual",
  "bulkRequest" : "em massa Pedidos",
  "backToLocationTransfer" : "Voltar para Transferir de Localização",
  "initiateBulkTransfer" : "acionador em massa Sub-conta Transferir",
  "initiateBulkTransferDescription" : "Selecione o utilizador da subconta que deseja transferir Ou introduza os IDs do utilizador da subconta que deseja transferir, separado Via vírgulas.",
  "subAccountId" : "Idda subconta",
  "selectFromList" : "Selecionar De Lista",
  "addManually" : "Adicionar manualmente",
  "accountsSelected" : "{count} Selecionado",
  "selectAllSubAccounts" : "selecionar todos",
  "removeAll" : "Extrair todos",
  "addManualLocations" : "Adicionar localização Id 's com Separado Via vírgulas",
  "manualAllLocationIds" : "IDs de Localização do utilizador subcontata que deseja Transferir, Separado Via vírgulas.",
  "enterLocationIds" : "Insira Id de Localização",
  "selectSubAccounts" : "Selecionar Sub-conta e transferir para uma agência Atual",
  "subAccountTransferWarning" : "todos os ativos da subconta e o Utilizador obterão o Descarregar.",
  "proceed" : "Prosseguir",
  "bulkRequestSubmitted" : "em massa Transferir Pedidos enviados",
  "bulkRequestSubmittedDescription" : "todos sub-conta serão descarregados assim que a agência recebida aceitar os pedidos de transferência. o utilizador pode rastrear o Estado dos Pedidos Ativado a ⌘{pagename}⌘ Página. (Emitido, eles são qualificadas)",
  "bulkTransferRequestFailed" : "em massa Transferir Pedidos Falhadas",
  "errorLocations" : "Subconta com erro",
  "viewDetails" : "Ver detalhes",
  "somethingWrong" : "Algo correu mal. Por favor, tentar novamente.",
  "confirmBulkRequest" : "Confirmar em massa Transferir Pedidos?",
  "bulkTransferAccounts" : "em massa Transferir '{count} ' Selecionado Sub Conta ?",
  "confirmDetails" : "Confirme Detalhes Antes de Transferência cega",
  "transferConsent" : "Tenho o Ler e comentei o guia acima e o Consentimento a todas as limitações.",
  "transferInfo" : "Sub Conta Transferir é assunto para a Agência Recebido que o aceite na sua Conta.",
  "receivingRelNo" : "Recebido Número de relação da agência",
  "enterRelNo" : "Introduzir número de relação",
  "transferConfirmation" : "Ao concluir esta ação, os pedidos de transferência em massa serão enviados para a agência recebida ({ relNo }). todos sub-conta o utilizador've selecionado será descarregado assim que a agência recebida o aceitar. Tenha em atenção que esta ação é irreversível para todas as subcontas incluídas in Transferir.",
  "confirmPassword" : "Confirme Via ao escrever password abaixo",
  "enterPassword" : "Introduzir palavra-passe",
  "requestTransfer" : "Pedidos Transferir",
  "confirmTransfer" : "Confirmar Transferir",
  "notNow" : "Não é agora",
  "invalidRelNo" : "Número de relação inválido",
  "invalidPassword" : "password inválida .",
  "searchSubAccounts" : "Pesquisar subconta",
  "searchSubAccountsId" : "Pesquisar Idda subconta",
  "takeAction" : "Tomar Ação",
  "sub_account_details" : "Sub Perfil",
  "no_of_contacts" : "No de Amigos",
  "transfer_status" : "Estado da Transferir",
  "error_info" : "Informações do erro",
  "bulk_transfer_status" : "em massa Sub-conta Transferir Estado",
  "pending" : "Pendente",
  "processed" : "Processado",
  "reject_all" : "Rejeitar todos",
  "accept_all" : "Aceite todos",
  "cancel_all" : "Cancelar todos",
  "clearAll" : "Limpar todos",
  "refresh" : "Atualizar",
  "rejected" : "Negado",
  "decline_transfer" : "Rejeitar Transferir",
  "decline_bulk_transfer" : "Rejeitar em massa sub-conta Transferir Pedidos ?",
  "decline_bulk_transfer_description" : "Os Pedidos serão recusados e a Coleção de {length} subconta não será Descarregada na sua Agência.",
  "accept_bulk_transfer" : "Aceite em massa sub-conta Transferir pedidos ?",
  "accept_bulk_transfer_description" : "Os Pedidos serão Aceite e a Coleção de {length} subconta será Descarregada na sua Agência.",
  "cancel_bulk_transfer" : "Cancelar Transferir em massa ?",
  "cancel_bulk_transfer_description" : "O utilizador tem mesmo a certeza de que o utilizador deseja Cancelar isto em massa Transferir de {length} Sub-conta para \"{name} \"",
  "consent_sub_account_transfer" : "Por favor, Leia e Consentimento para {link}",
  "sub_account_transfer_guidelines" : "Diretivas de transferência da Sub-conta",
  "valid_rel" : "Introduza um número de relação válido",
  "something_went_wrong" : "Algo correu mal. Por favor, tentar novamente.",
  "export" : "Exportar",
  "export_success" : "Os dados que o utilizador solicitaram estão a ser processados. o utilizador irá Herdar um e-mail com os dados in alguns Dados.",
  "export_failed" : "Não foi possível processar os dados do utilizador pedidos. Por favor, tentar novamente mais tarde.",
  "selectAllError" : "o utilizador só pode selecionar 1000 subcontas num dado.",
  "max1000" : "(Máximo . 1000)",
  "subAccountCount" : "Subconta Contagem"
}
