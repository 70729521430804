{
  "common" : {
    "thisWeek" : "Denne uge",
    "lastWeek" : "Sidste uge",
    "lastNumberOfDays" : "sidste {numberOfDays} dage",
    "thisMonth" : "Denne måned"
  },
  "subAccounts" : "Underkonto",
  "submitFeedback" : "Indsend feedback",
  "createSubAccount" : "Opret Underkonto",
  "viewScheduleReports" : "Vis Planlagt rapporter",
  "searchSubAccount" : "Søge Via Underkonto",
  "columns" : "Kolonner",
  "sortBy" : "Sortér efter",
  "selectColumns" : "Vælge kolonner",
  "maxColumns" : "Maks. 8 kolonner ved en Dato",
  "applyColumns" : "Anvend kolonner",
  "activeUsers" : "Aktive brugere",
  "appointments" : "Aftaler",
  "contacts" : "Kontakter",
  "emails" : "Gmail",
  "textMessages" : "Indhold beskeder",
  "calls" : "Opkald",
  "submissions" : "Indsendelser",
  "reviews" : "Anmeldelser",
  "sitesCreated" : "Websites Oprettet",
  "name" : "Navn",
  "formSubmissions" : "Indsendelser af Formular",
  "surveySubmissions" : "Indsendelser af Undersøgelse",
  "facebookFormSubmissions" : "Indsendelser af Facebook-formular",
  "funnels" : "Tragter",
  "websites" : "Websites",
  "forms" : "Formularer",
  "surveys" : "Undersøgelser",
  "highestFirst" : "Maks. først",
  "lowestFirst" : "Laveste først",
  "orderBy" : "Sortere Via",
  "noMoreLocations" : "Der er ikke fundet flere Adresse",
  "preparingReport" : "Vi er Indlæser din meddelelse",
  "preparingReportDesc" : "Opdater ikke Luk Eller dette vindue, mens Eksportere er in gang",
  "outOf" : "Fra",
  "subAccountsExported" : "Underkonto eksporteret",
  "close" : "Luk",
  "scheduledForDeletion" : "Planlagt til sletning",
  "readyToSell" : "Parat til at sælge",
  "closedWon" : "Lukket (vundet)",
  "wpSaleSuccessful" : "WordPress-salget er lykkedes",
  "wpNotSold" : "WordPress ikke solgt endnu",
  "yxtSaleSuccessful" : "Yext salg er lykkedes",
  "yxtNotSold" : "Den næste ikke solgt endnu",
  "saasSubInactive" : "SaaS abonnement er inaktiv",
  "saasSubTrialing" : "SaaS Abonnement er på prøve",
  "saasSubActive" : "SaaS abonnement er dynamisk",
  "emailRebillingActive" : "E-mail Genfakturering er Dynamisk",
  "emailRebillingInactive" : "E-mail Genfakturering er Inaktiv",
  "twilioRebillingActive" : "Twilio Genfakturering er Dynamisk",
  "twilioRebillingInactive" : "Twilio Genfakturering er Inaktiv",
  "wpRebillingActive" : "WhatsApp Genfakturering er Dynamisk",
  "wpRebillingInactive" : "WhatsApp Genfakturering er inaktiv",
  "saasModeActivated" : "SaaS -tilstand er Aktiveret",
  "paymentPending" : "Betalingen afventer",
  "saasModeNotActivated" : "SaaS -tilstand er ikke Aktiveret",
  "managedByLCPhone" : "Administrator Via LC Telefon",
  "migrationToLCPhone" : "Under migrering til LC Telefon",
  "notManagedByLCPhone" : "Ikke administrator Via LC Telefon",
  "managedByLCEmail" : "Administrator Via LeadConnector Email System",
  "notMangedByLCEmail" : "Ikke administrator Via LeadConnector Email System",
  "remainingWalletBalance" : "Resterende Tegnebog Saldo af denne SaaS Placering",
  "reports" : "Rapporter",
  "switchToSubAccount" : "Skift til Underkonto",
  "manageClient" : "Administrer klient",
  "subAccountDeletedIfChangedMind" : "Denne underkonto vil være Slettet Til {time}, Hvis Du've Dynamisk dit sind",
  "subAccountDeletedInMinute" : "Denne Underkonto vil være Slettet inden for det næste minut",
  "changedMind" : "Hvis Du've Dynamisk dit sind",
  "clickHere" : "klik på hende",
  "subAccountPaused" : "Denne underkonto er lørdag på pause. Dine kunder kan ikke logge ind, men Agentur kan. Til Genoptage denne Konto",
  "subAccountRestricted" : "Denne underkonto er Sat på pause Skyldte til afventende Godkendelse.",
  "subAccountVerificationPending" : "Denne underkonto er sat på pause Skyldte til afventende e-mail- og telefonbekræftelse fra underkonto Admin.",
  "subAccountDeclined" : "Denne underkonto er Sat på pause Skyldte til afviste Godkendelse Forespørgsler. Du kan stadig genoptage denne Underkonto Fra ",
  "here" : "her.",
  "reviewAndResume" : "til Anmeldelse og Genoptage denne Underkonto.",
  "incomingCalls" : "Indgående opkald",
  "outgoingCalls" : "Udgående opkald",
  "totalTalktime" : "Sum taletid",
  "incomingEmails" : "Indgående Gmail",
  "outgoingEmails" : "Udgående Gmail",
  "incomingSms" : "Indgående Indhold-beskeder",
  "outgoingSms" : "Udgående Indhold-beskeder",
  "contactsCreated" : "nummer af Venner Oprettet",
  "appointmentsBooked" : "nummer af Aftale booket",
  "totalFormSubmissions" : "Sum formularindsendelser",
  "totalSurveySubmissions" : "Sum Undersøgelse indsendelser",
  "totalFacebookFormSubmissions" : "Sum Facebook-formular indsendelser",
  "incomingGMBMessages" : "Indgående GBP-meddelelser",
  "outgoingGMBMessages" : "Udgående GBP-meddelelser",
  "gmbMessages" : "GBP Beskeder",
  "fbMessages" : "Facebook-beskeder",
  "incomingFbMessages" : "Indgående Facebook-beskeder",
  "outgoingFbMessages" : "Udgående Facebook-beskeder",
  "igMessages" : "Instagram-beskeder",
  "incomingIgMessages" : "Indgående Instagram-beskeder",
  "outgoingIgMessages" : "Udgående Instagram-beskeder",
  "totalReviews" : "Anmeldelser i Sum",
  "positiveReviews" : "Positive anmeldelser",
  "negativeReviews" : "Negative anmeldelser",
  "back" : "Tilbage",
  "selectDashboards" : "Vælge kontrolpaneler",
  "next" : "Næste",
  "backToSubAccounts" : "Tilbage til Underkonto",
  "pauseSchedule" : "Sæt skema på Pause",
  "resumeSchedule" : "Genoptage skema",
  "editSchedule" : "Rediger skema",
  "addSchedule" : "Tilføj skema",
  "selectReportToAdd" : "Vælge rapporterer til Inkludere in e-mailen",
  "selectAll" : "Vælge hver",
  "pauseScheduleConfirm" : "Er du sikker på, at du vil sætte rapportskemaet på Pause ?",
  "resumeScheduleConfirm" : "Er du sikker på, at du vil genoptage rapportskemaet?",
  "tasks" : "Opgaver",
  "conversations" : "Samtaler",
  "users" : "Bruger",
  "dealOverview" : "Oversigt over Aftale",
  "confirmAction" : "Bekræft Handling",
  "yes" : "Ja",
  "no" : "Nej",
  "reportDetails" : "Oplysninger",
  "reportCreatedOn" : "Rapport Oprettet Til",
  "searchByEmail" : "Søge Via E-mail",
  "lastActivity" : "sidste Aktivitet",
  "noRecords" : "Der blev ikke fundet Poster",
  "scheduleReport" : "skema Beretning",
  "schedule" : "skema",
  "save" : "Gem",
  "emailSubject" : "Emnelinje",
  "frequency" : "Frekvens",
  "daily" : "Daglig",
  "weekly" : "Ugentlig",
  "monthly" : "Månedlig",
  "nextScheduleDate" : "Næste skema Dato",
  "startingOn" : "Starter til",
  "timeOfDay" : "Dato af Dag",
  "reportsToTimezone" : "Rapport vil blive sendt in Underkontoens tidszone",
  "selectRecipients" : "Vælge modtagere",
  "invalidEmail" : "Du har indtastet en ugyldig e-mailadresse",
  "testEmail" : "Test",
  "selectTestRecipients" : "Vælg Test",
  "setupSubject" : "Konfigurer Emne ovenfor for at Test rapporten",
  "sendTest" : "Upload",
  "scheduleSimilarSchedules" : "skema tilsvarende rapport for Andet Sub-Konto",
  "accountSummary" : "Konto Oversigt",
  "scheduleList" : "skema Liste",
  "subAccountName" : "Navn på underkonto",
  "nextRunDate" : "Dato for Næste kørsel",
  "reportSectionsAdded" : "Rapport Segmenter tilføjet",
  "status" : "Status",
  "scheduleAction" : "skema-håndtering",
  "cancel" : "Annuller",
  "pleaseWait" : "Vente",
  "confirm" : "Bekræft",
  "booked" : "Booket",
  "confirmed" : "Bekræftet",
  "showed" : "Fremmødte",
  "noShow" : "Udeblevet",
  "cancelled" : "Aflyst",
  "unread" : "Ulæst",
  "engagement" : "Engagement",
  "reaction" : "Reaktion",
  "deliverySummary" : "Oversigt Levering",
  "successfulDelivery" : "Vellykket Levering",
  "opened" : "Åbnet",
  "clicked" : "Klikket",
  "replied" : "Svarede",
  "unsubscribed" : "Afmeldt",
  "complained" : "Beklagede",
  "failed" : "Mislykkedes",
  "open" : "Åben",
  "won" : "Vundet",
  "abandoned" : "Forladt",
  "lost" : "Tabt",
  "sent" : "Sendt",
  "eject" : "Skub ud",
  "delivered" : "Sendt",
  "faceBookLeadForm" : "Facebook- Formular",
  "overdue" : "Forfalden",
  "reviewsReceived" : "Anmeldelser Modtaget",
  "formBuilders" : "Formular",
  "switchToSaaS" : "Skift til SaaS",
  "numberOfContacts" : "nummer af Venner",
  "numberOfAppointments" : "nummer af Aftale",
  "outreach" : "Opsøgende",
  "notSubAccountUser" : "Ikke en underkontobruger",
  "searchSubAccName" : "Søge Via Underkontos navn",
  "recipients" : "Modtagere",
  "scheduleCreated" : "E-mail Rapport skema Oprettet for ",
  "scheduleUpdated" : "E-mail Rapport skema Opdateret for ",
  "scheduleNotCreated" : "E-mailrapport skema kunne ikke oprettes for",
  "scheduleNotUpdated" : "E-mailrapport skema kunne ikke Opdateret for",
  "total" : "I alt",
  "inbound" : "Indgående",
  "outbound" : "Udgående",
  "totalEmails" : "Sum Gmail",
  "inboundEmails" : "Indgående Gmail",
  "outboundEmails" : "Udgående Gmail",
  "totalTextMessages" : "Sum Indhold-meddelelser",
  "inboundTextMessages" : "Indgående Indhold-beskeder",
  "outboundTextMessages" : "Udgående Indhold-beskeder",
  "totalCalls" : "Sum i alt",
  "inboundCalls" : "Indgående opkald",
  "outboundCalls" : "Udgående opkald",
  "faceBookLeadFormSubmissions" : "Indsendelser af Facebook- Formular",
  "inboundGMBMessages" : "Indgående meddelelser i GBP",
  "outboundGMBMessages" : "Udgående GBP-meddelelser",
  "totalIgMessages" : "Sum Instagram-beskeder",
  "inboundIgMessages" : "Indgående Instagram-beskeder",
  "outboundIgMessages" : "Udgående Instagram-beskeder",
  "totalfbMessages" : "Sum Facebook-beskeder",
  "inboundfbMessages" : "Indgående Facebook-beskeder",
  "outboundfbMessages" : "Udgående Facebook-beskeder",
  "sitesCreatedFunnels" : "Oprettet websites – Tragter",
  "sitesCreatedActiveFunnels" : "Sites Oprettet - Dynamisk Tragter",
  "sitesCreatedWebsites" : "Oprettet websites - websites",
  "sitesCreatedActiveWebsites" : "Sites Oprettet - Dynamisk Websites",
  "sitesCreatedForms" : "Oprettet websites - formularer",
  "sitesCreatedActiveForms" : "Sites Oprettet - Dynamisk Formularer",
  "sitesCreatedSurveys" : "Oprettet websites - undersøgelser",
  "sitesCreatedActiveSurveys" : "Sites Oprettet - Dynamisk Undersøgelser",
  "googleReviews" : "Google Anmeldelser",
  "facebookReviews" : "Facebook-anmeldelser",
  "inboundCallDuration" : "Varighed af indgående opkald (tid)",
  "outboundCallDuration" : "Udgående opkald varighed (tid)",
  "pleaseSelect" : "Vælge",
  "subAccountIsDeleted" : "Denne underkonto har været Slettet",
  "subAccountIsPaused" : "Denne underkonto har været sat på pause",
  "success" : "Gennemført",
  "error" : "Fejl",
  "testEmailSent" : "Test sendt",
  "testEmailNotSent" : "Test kunne ikke sendes",
  "reviewRequest" : "Forespørgsler om Anmeldelse",
  "pendingTransferDescription" : "Andet Agenturvisning har anmodet om at overføre Underkonto til Du.",
  "pendingTransferHeading" : "Du har fået afventende Under-Konto Overfør Forespørgsler.",
  "subAccountTransfer" : "Download underkonto",
  "manageSubAccTransfer" : "Administrer din Underkonto Overfør Forespørgsler",
  "subAccRequest" : "Underkonto {tabName} Forespørgsler",
  "receive" : "Arve",
  "send" : "Send",
  "release" : "Udgive",
  "requests" : "{count} Forespørgsler",
  "subAccLimit" : "Underkonto-brug SLOTMAX nået!",
  "subAccPlanLimit" : "Du har brugt 3/3 underkonto tilladt i henhold til din Aktuel Plan.",
  "upgradeTime" : "Det er Dato for en Opgrader!",
  "upgradeHigher" : "Opgrader til en højere Plan at acceptere underkontooverførslen.",
  "browse" : "Gennemse planer",
  "pendingTransferTitle" : "En Overfør Forespørgsler for denne Underkonto afventer allerede.",
  "unableTransfer" : "Det er ikke muligt at overføre underkonto til denne agentur.",
  "unableEjectCancel" : "Kan ikke annullere underkonto eject Forespørgsler",
  "EjectErrorDetails" : "Underkonto-ejektet Forespørgsler, som Du forsøger at annullere, kan ikke annulleres.",
  "hippaErrorDescription" : "Den underkonto, som du forsøger at overføre, er HIPAA Aktiveret og kan ikke Download til en ikke-HIPAA-agent.",
  "pendingTransferErrorDescription" : "Annuller venligst den allerede Aktuel Overfør Forespørgsler for denne UnderKonto Før, der fortsætter til Løft en ny.",
  "receivingAgency" : "Modtaget Agentur",
  "releasingAgency" : "Udstedte Agentur",
  "noOfContacts" : "Nr af Venner",
  "requestedDate" : "Ønsket Dato",
  "action" : "Handling",
  "cancelTransfer" : "Annuller Overfør",
  "decline" : "Afvise",
  "cancelTransferRequest" : "Annuller Under-Konto Overfør Forespørgsler?",
  "cancelEjectRequest" : "Annuller Under-Konto eject Forespørgsler?",
  "cancelRequestDescription" : "Modtaget Agentur vil ikke længere se denne Overfør Forespørgsler, og Underkontoen {name} vil ikke være Download.",
  "cancelEjectRequestDescription" : "Valgt Bruger vil ikke længere være i stand til at Opret ny Agentur Fra underkontoen {name}.",
  "declineRequest" : "Afvise Under-Konto Overfør Forespørgsler?",
  "declineRequestDescription" : "Forespørgsler vil blive afvist, og underkonto {name} vil ikke være Download til din Agentur.",
  "approveTransfer" : "Godkende Sub-Konto Overfør til din Agentur?",
  "approveTransferDescription" : "{name} Underkonto Download til din agent.",
  "transferNow" : "Overfør nu",
  "cancelNow" : "Annuller nu",
  "transferErrorDetails" : "Den underkonto, som du forsøger at overføre, kan ikke Download",
  "cancelRequest" : "Annuller Forespørgsler",
  "received" : "Modtaget",
  "approve" : "Godkende",
  "goBack" : "Fortryd",
  "done" : "Færdig",
  "subAccountTransferred" : "Underkontoen er blevet Download!",
  "subAccountTransferDescription" : "Note , at Lige selvom underkontoen er blevet Download, kan nogle aktiver Synes godt om Bruger, Telefonnummer, Mailgun Konto, Yext-fortegnelser osv. stadig tage 10-15 minutter at afspejle in destinationen.",
  "activeSubscriptionsAlert" : "Placeringen har Dynamisk Abonnement. Annuller hver Abonnement Før Blind overførsel af Placeringen. Liste over Dynamisk Abonnement: {subscriptions}",
  "isSaasEnabled" : "SaaS Aktiveret",
  "typeToSearch" : "Skriv for at søge",
  "dateAdded" : "Dato tilføjet",
  "emailEngagement" : "E-mail",
  "emailReaction" : "E-mail",
  "emailDeliverySummary" : "Oversigt Levering af E-mail",
  "facebook" : "Facebook",
  "sms" : "SMS",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "Konto {subAccountName} {Oversigt}",
  "test" : "Test",
  "scheduleExists" : "skema findes",
  "aToZ" : "A - Å",
  "zToA" : "Z - A",
  "onlyAdminUsersCanAddNewSubAccounts" : "Kun Admin Bruger kan Inkludere nye underkontoer.",
  "locationName" : "Navn på Placering",
  "transferCompleted" : "Overfør Forespørgsler for denne Placering er allerede behandlet. Opdater.",
  "unauthorizedTransfer" : "Du er ikke godkendt til at udføre denne håndtering. Kun Agentur-ejere kan udføre denne håndtering.",
  "bulkLocationTransfer" : "Større mængder Overfør Sub Konto",
  "singleRequest" : "Individuel Forespørgsler",
  "bulkRequest" : "Større mængder Forespørgsler",
  "backToLocationTransfer" : "Tilbage til Placering Overfør",
  "initiateBulkTransfer" : "udløser Større mængder Under-Konto Overfør",
  "initiateBulkTransferDescription" : "Vælg underkonto Du ønsker at overfør, eller indtast ID'erne på underkonto Du ønsker til Overfør, Adskilt Via komma.",
  "subAccountId" : "Underkonto- Id",
  "selectFromList" : "Vælge Fra Liste",
  "addManually" : "Tilføj manuelt",
  "accountsSelected" : "{count} Valgt",
  "selectAllSubAccounts" : "Vælge hver",
  "removeAll" : "Uddrage hver",
  "addManualLocations" : "Tilføj Placering Id 's med Adskilt Via komma's",
  "manualAllLocationIds" : "Placering ID'er af underKonto Du ønsker at Overfør, Adskilt Via komma.",
  "enterLocationIds" : "Indtast placerings- Id 's",
  "selectSubAccounts" : "Vælge Under-konto Og Overfør til an Aktuel Agentur",
  "subAccountTransferWarning" : "hver under-Konto-aktiver og Bruger vil få Download.",
  "proceed" : "Fortsæt",
  "bulkRequestSubmitted" : "Større mængder Overfør Forespørgsler Indsendt",
  "bulkRequestSubmittedDescription" : "hver underkonto vil blive Download , når Modtaget Agentur accepterer Overfør Forespørgsler. Du kan spore Status af Forespørgsleren til ❝{pagename}❞-siden. (Udstedte er de kvalificerede)",
  "bulkTransferRequestFailed" : "Større mængder Overfør Forespørgsler Mislykkedes",
  "errorLocations" : "Underkonto med fejl",
  "viewDetails" : "Vis oplysninger",
  "somethingWrong" : "Der opstod en fejl. Prøv igen.",
  "confirmBulkRequest" : "Bekræft Større mængder Overfør Forespørgsler?",
  "bulkTransferAccounts" : "Større mængder Overfør '{count} ' Valgt Sub Konto ?",
  "confirmDetails" : "Bekræft Oplysninger Før Blind overførsel",
  "transferConsent" : "Jeg har Læse og forstod ovenstående vejledning og Samtykke hver begrænsningerne.",
  "transferInfo" : "Sub Konto Overfør er Emne til Modtaget Agentur, der accepterer det på deres Konto.",
  "receivingRelNo" : "Modtaget Agentur's Relationsnummer",
  "enterRelNo" : "Indtast relationsnummer",
  "transferConfirmation" : "Efter afslutning af denne håndtering vil Større mængder Overfør Forespørgsler blive sendt til Modtaget Agentur ({ relNo }). hver under-Konto Du've Valgt vil blive Download, når Modtaget Agentur accepterer det. Note , at denne håndtering er uigenkaldelig for hver underkonto, der er inkluderet in overførslen.",
  "confirmPassword" : "Bekræft Ved at skrive password under",
  "enterPassword" : "Indtast adgangskode",
  "requestTransfer" : "Forespørgsler Overfør",
  "confirmTransfer" : "Bekræft Overfør",
  "notNow" : "Ikke nu",
  "invalidRelNo" : "Ugyldig Relationsnummer",
  "invalidPassword" : "Ugyldig password.",
  "searchSubAccounts" : "Søge Underkonto",
  "searchSubAccountsId" : "Søge Underkonto Id",
  "takeAction" : "Tag Handling",
  "sub_account_details" : "Underprofil",
  "no_of_contacts" : "No af Venner",
  "transfer_status" : "Overfør Status",
  "error_info" : "Info",
  "bulk_transfer_status" : "Større mængder Underkonto Overfør Status",
  "pending" : "Afventer",
  "processed" : "Behandlet",
  "reject_all" : "Afvise hver",
  "accept_all" : "Acceptér hver",
  "cancel_all" : "Annuller hver",
  "clearAll" : "Fjern hver",
  "refresh" : "Opdater",
  "rejected" : "Afvist",
  "decline_transfer" : "Afvise Overfør",
  "decline_bulk_transfer" : "Afvise Større mængder sub-Konto Overfør Forespørgsler ?",
  "decline_bulk_transfer_description" : "Forespørgsler vil blive afvist, og Samling af {length} underkonto vil ikke blive Download til din Agentur.",
  "accept_bulk_transfer" : "Acceptér Større mængder sub-Konto Overfør Forespørgsler ?",
  "accept_bulk_transfer_description" : "Forespørgsleren vil være Accepteret og Samling af {length} underkonto vil være Download til din Agentur.",
  "cancel_bulk_transfer" : "Annuller Større mængder Overfør ?",
  "cancel_bulk_transfer_description" : "Er du virkelig sikker på, at du vil annullere denne Større mængder Overfør af {length} Underkonto til \"{name} \"",
  "consent_sub_account_transfer" : "Læse og Samtykke til {link}",
  "sub_account_transfer_guidelines" : "Retningslinjer for underkonto overfør",
  "valid_rel" : "Indtast et gyldigt nummer",
  "something_went_wrong" : "Der opstod en fejl. Prøv igen.",
  "export" : "Eksportér",
  "export_success" : "De data, Du anmodede om, behandles. Du vil give en e-mail med dataene in en eller anden Dato.",
  "export_failed" : "De data, Du anmodede om, kunne ikke behandles. Prøv igen senere.",
  "selectAllError" : "Du kan kun vælge 1000 Sub Konto ved en Dato.",
  "max1000" : "(Maks. 1000)",
  "subAccountCount" : "Underkonto Optælling"
}
