import { createI18n } from 'vue-i18n'
import da from './lan/da.json'
import de from './lan/de.json'
import en from './lan/en-US.json'
import es from './lan/es.json'
import fi from './lan/fi.json'
import frCa from './lan/fr-CA.json'
import frFr from './lan/fr-FR.json'
import it from './lan/it.json'
import nl from './lan/nl.json'
import no from './lan/no.json'
import ptBr from './lan/pt-BR.json'
import ptPt from './lan/pt-PT.json'
import sv from './lan/sv.json'

export const i18n = createI18n({
  locale: localStorage.getItem('locale') || 'en_US',
  globalInjection: true,
  legacy: false,
  runtimeOnly: true,
  fallbackLocale: 'en_US',
  messages: {
    en_US: en,
    es,
    da,
    de_DE: de,
    fi,
    fr_CA: frCa,
    fr: frFr,
    it,
    nl,
    nb: no,
    pt_BR: ptBr,
    pt: ptPt,
    sv,
  },
})

export function translate(key: string, vars?: any): string {
  return i18n.global.t(key, vars) as string
}
