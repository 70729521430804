{
  "common" : {
    "thisWeek" : "Cette semaine",
    "lastWeek" : "La semaine dernière",
    "lastNumberOfDays" : "Les {numberOfDays} jours",
    "thisMonth" : "Ce mois-ci"
  },
  "subAccounts" : "Sous-compte",
  "submitFeedback" : "Soumettre une rétroaction",
  "createSubAccount" : "Créer une sous-compte",
  "viewScheduleReports" : "Voir les rapport Prévu",
  "searchSubAccount" : "Rechercher via sous-compte",
  "columns" : "Colonnes",
  "sortBy" : "Trier par",
  "selectColumns" : "Sélectionner des colonnes",
  "maxColumns" : "Max de 8 colonnes à une Date",
  "applyColumns" : "Applique des colonnes",
  "activeUsers" : "Utilisateurs actifs",
  "appointments" : "Rendez-vous",
  "contacts" : "Contacts",
  "emails" : "Gmail",
  "textMessages" : "Messages du corps",
  "calls" : "Appels",
  "submissions" : "Soumissions",
  "reviews" : "avis",
  "sitesCreated" : "Sites Web Créé",
  "name" : "Nom",
  "formSubmissions" : "Soumissions de Formulaire",
  "surveySubmissions" : "Soumissions Sondage",
  "facebookFormSubmissions" : "Soumissions de Formulaire Facebook",
  "funnels" : "Entonnoirs",
  "websites" : "Sites Web",
  "forms" : "Formulaires",
  "surveys" : "Sondages",
  "highestFirst" : "Max d’abord",
  "lowestFirst" : "Le plus de base à bord",
  "orderBy" : "Essai via",
  "noMoreLocations" : "Aucune adresse n'a été trouvée",
  "preparingReport" : "Nous préparons votre rapport",
  "preparingReportDesc" : "Ne fermez pas de rafraîchir cette fenêtre pendant que l'exportation est in cours",
  "outOf" : "Dé",
  "subAccountsExported" : "Sous-compte exporté",
  "close" : "Fermer",
  "scheduledForDeletion" : "Prévu pour la suppression",
  "readyToSell" : "Prêt à vendre",
  "closedWon" : "Fermé (Gagné)",
  "wpSaleSuccessful" : "La vente WordPress est réussie",
  "wpNotSold" : "WordPress non encore exporté",
  "yxtSaleSuccessful" : "La vente actuelle est réussie",
  "yxtNotSold" : "Pas encore de ventes",
  "saasSubInactive" : "L'abonnement SaaS est inactif",
  "saasSubTrialing" : "L'abonnement SaaS est en cours d'essai",
  "saasSubActive" : "L'abonnement SaaS est dynamique",
  "emailRebillingActive" : "La réorganisation du courriel est dynamique",
  "emailRebillingInactive" : "La réorganisation du courriel est inactif",
  "twilioRebillingActive" : "Twilio Refacturation est dynamique",
  "twilioRebillingInactive" : "Twilio refacturation est inactif",
  "wpRebillingActive" : "La refacturation WhatsApp est dynamique",
  "wpRebillingInactive" : "La refacturation WhatsApp est inactif",
  "saasModeActivated" : "Le mode SaaS est activé",
  "paymentPending" : "Le Paiement est en attente",
  "saasModeNotActivated" : "Le mode SaaS n'est pas activé",
  "managedByLCPhone" : "Personnaliser via UC Téléphone",
  "migrationToLCPhone" : "En cours de conversion vers le Téléphone UC",
  "notManagedByLCPhone" : "Ne pas gérer via UC Téléphone",
  "managedByLCEmail" : "Gérer via LeadConnector Email System",
  "notMangedByLCEmail" : "Ne pas gérer le système via LeadConnector Email System",
  "remainingWalletBalance" : "Portefeuille restante de cet emplacement SaaS",
  "reports" : "Rapports",
  "switchToSubAccount" : "Passer à sous-compte",
  "manageClient" : "Gérer le Client",
  "subAccountDeletedIfChangedMind" : "Cette sous-compte sera prise en charge {time} si vous avez dynamique votre tête",
  "subAccountDeletedInMinute" : "Cette sous-compte sera prise en charge dans la minutesuivante",
  "changedMind" : "Si vous avez dynamique votre tête",
  "clickHere" : "Voir ici",
  "subAccountPaused" : "Cette sous-compte est en pause. Vos clients ne peuvent pas créer une session, mais l'agence peut. Pour éviter ce compte",
  "subAccountRestricted" : "Cette sous-compte est en pause d'attente pour approbation.",
  "subAccountVerificationPending" : "Cette sous-compte est en pause d'attente pour la vérification de courriel et de téléphone par l'administrateur.",
  "subAccountDeclined" : "Cette sous-compte est en pause pour éviter des demandes d'approbation. De ",
  "here" : "ici.",
  "reviewAndResume" : "pour .",
  "incomingCalls" : "Appels entrants",
  "outgoingCalls" : "Appels sortants",
  "totalTalktime" : "Total des conversations",
  "incomingEmails" : "Gmailentrant",
  "outgoingEmails" : "Gmailsortant",
  "incomingSms" : "Messages entrants du corps",
  "outgoingSms" : "Messages sortants",
  "contactsCreated" : "nombre de Amis",
  "appointmentsBooked" : "Nombre de rendez-vous réservés",
  "totalFormSubmissions" : "Total des soumissions de formulaires",
  "totalSurveySubmissions" : "Total des soumissions de l'enquête",
  "totalFacebookFormSubmissions" : "Total des soumissions de formulaires Facebook",
  "incomingGMBMessages" : "GBP entrants",
  "outgoingGMBMessages" : "Messages GBP",
  "gmbMessages" : "Messages GBP",
  "fbMessages" : "Messages Facebook",
  "incomingFbMessages" : "Messages entrants Facebook",
  "outgoingFbMessages" : "Messages sortants Facebook",
  "igMessages" : "Messages Instagram",
  "incomingIgMessages" : "Instagram entrants",
  "outgoingIgMessages" : "Messages Instagram",
  "totalReviews" : "Total des commentaires",
  "positiveReviews" : "Commentaires Positif",
  "negativeReviews" : "Évaluation négative",
  "back" : "Retour",
  "selectDashboards" : "Sélectionner des tableaux de bord",
  "next" : "Suivant",
  "backToSubAccounts" : "Retour au sous-compte",
  "pauseSchedule" : "Pause du calendrier",
  "resumeSchedule" : "calendrier de Curriculum vitae",
  "editSchedule" : "Modifier le calendrier",
  "addSchedule" : "Ajouter",
  "selectReportToAdd" : "Sélectionner des rapport à inclure in le courriel",
  "selectAll" : "Sélectionner tout",
  "pauseScheduleConfirm" : "Êtes-vous sûr de vouloir Pause le rapport?",
  "resumeScheduleConfirm" : "Êtes-vous sûr de vouloir dupliquer le rapport?",
  "tasks" : "Tâches",
  "conversations" : "Conversations",
  "users" : "Utilisateur",
  "dealOverview" : "Aperçu de la Accord",
  "confirmAction" : "Confirmer Action",
  "yes" : "Oui",
  "no" : "Non",
  "reportDetails" : "Détails du rapport",
  "reportCreatedOn" : "Rapport sur l'ensemble du rapport",
  "searchByEmail" : "Recherche via Courriel",
  "lastActivity" : "Dernière activité",
  "noRecords" : "Aucun Dossiers trouvé",
  "scheduleReport" : "Rapport sur le calendrier",
  "schedule" : "Calendrier",
  "save" : "Enregistrer",
  "emailSubject" : "Ligne d'objet",
  "frequency" : "Fréquence",
  "daily" : "Quotidiennement",
  "weekly" : "Hebdomadaire",
  "monthly" : "Mensuel",
  "nextScheduleDate" : "Date",
  "startingOn" : "Allumé de début",
  "timeOfDay" : "Date de jour",
  "reportsToTimezone" : "Le rapport sera envoyé in le fuseau horaire du sous-compte",
  "selectRecipients" : "Sélectionner des destinataires",
  "invalidEmail" : "Vous avez saisi une adresse électronique non valide",
  "testEmail" : "Courriel de Essai",
  "selectTestRecipients" : "Sélectionner des destinataires d'essai",
  "setupSubject" : "Configurer les Sujet ci-dessus pour essayer le rapport",
  "sendTest" : "Téléverser des essais",
  "scheduleSimilarSchedules" : "Rapport similaire pour d'autres sous-comptes",
  "accountSummary" : "Résumé du Compte",
  "scheduleList" : "Liste déroulante",
  "subAccountName" : "Nom de la sous-entreprise",
  "nextRunDate" : "Exécuter la Date Suivant",
  "reportSectionsAdded" : "Segments ajoutés",
  "status" : "Statut",
  "scheduleAction" : "Calendrier Action",
  "cancel" : "Annuler",
  "pleaseWait" : "Veuillez Attendre",
  "confirm" : "Confirmer",
  "booked" : "Réservé",
  "confirmed" : "Confirmé",
  "showed" : "Montré",
  "noShow" : "Absent",
  "cancelled" : "Annulé",
  "unread" : "Non lus",
  "engagement" : "Affectation",
  "reaction" : "Réaction",
  "deliverySummary" : "Résumé de la Livraison",
  "successfulDelivery" : "Livraison réussie",
  "opened" : "Ouvert",
  "clicked" : "Cliqué",
  "replied" : "Répondu",
  "unsubscribed" : "Désabonné",
  "complained" : "Plainte",
  "failed" : "Échec",
  "open" : "Ouvert",
  "won" : "Gagné",
  "abandoned" : "Abandonné",
  "lost" : "Perdu",
  "sent" : "Envoyé",
  "eject" : "Éjeter",
  "delivered" : "Livré",
  "faceBookLeadForm" : "Formulaire de Prospect Facebook",
  "overdue" : "En retard",
  "reviewsReceived" : "D'avis",
  "formBuilders" : "Constructeurs de Formulaire",
  "switchToSaaS" : "Passer au SaaS",
  "numberOfContacts" : "Nombre de Amis",
  "numberOfAppointments" : "Nombre de Rendez-vous",
  "outreach" : "Extension",
  "notSubAccountUser" : "Pas de sous-comptes",
  "searchSubAccName" : "Recherche via le nom du sous-compte",
  "recipients" : "Reçus",
  "scheduleCreated" : "Rapport sur le courriel ",
  "scheduleUpdated" : "Rapport sur le courriel ",
  "scheduleNotCreated" : "Le rapport de courriel ne peut pas être modifié",
  "scheduleNotUpdated" : "Le rapport de courriel ne peut pas être mis à jour pour",
  "total" : "Total",
  "inbound" : "entrant",
  "outbound" : "Sortie",
  "totalEmails" : "Total Gmail",
  "inboundEmails" : "Gmailentrant",
  "outboundEmails" : "Gmail",
  "totalTextMessages" : "Nombre Total de messages",
  "inboundTextMessages" : "Messages entrants du corps",
  "outboundTextMessages" : "Messages de sortie",
  "totalCalls" : "Total d'appels",
  "inboundCalls" : "Appels entrants",
  "outboundCalls" : "Sortie sortants",
  "faceBookLeadFormSubmissions" : "Soumissions de Formulaire de Prospect Facebook",
  "inboundGMBMessages" : "GBP entrants",
  "outboundGMBMessages" : "Messages GBP",
  "totalIgMessages" : "Total des messages Instagram",
  "inboundIgMessages" : "Instagram entrants",
  "outboundIgMessages" : "Messages Instagram",
  "totalfbMessages" : "Total de messages Facebook",
  "inboundfbMessages" : "Messages entrants Facebook",
  "outboundfbMessages" : "Messages Sortie Facebook",
  "sitesCreatedFunnels" : "Sites Web Créé - Entonnoirs",
  "sitesCreatedActiveFunnels" : "Sites Web - Entonnoirs dynamiques",
  "sitesCreatedWebsites" : "Sites Web Créé - Sites Web",
  "sitesCreatedActiveWebsites" : "Sites Web - Créez des sites Web dynamiques",
  "sitesCreatedForms" : "Sites Web Créé - formulaires",
  "sitesCreatedActiveForms" : "Sites Web - Créez des formulaires dynamiques",
  "sitesCreatedSurveys" : "Sites Web Créé - Sondages",
  "sitesCreatedActiveSurveys" : "Sites Web - Créez des enquêtes dynamiques",
  "googleReviews" : "Avis Google",
  "facebookReviews" : "Commentaires Facebook",
  "inboundCallDuration" : "Durée Appeler entrant (heure)",
  "outboundCallDuration" : "Durée de Appeler de sortie (heure)",
  "pleaseSelect" : "Veuillez Sélectionner",
  "subAccountIsDeleted" : "Cette sous-compte a été pris en charge",
  "subAccountIsPaused" : "Cette sous-compte a été en pause",
  "success" : "Succès",
  "error" : "Erreur",
  "testEmailSent" : "Courriel de Essai envoyé",
  "testEmailNotSent" : "Courriel d’essai n’a pas pu être envoyé",
  "reviewRequest" : "Demandes de Avis",
  "pendingTransferDescription" : "Les autres vues de l'agence ont demandé de déplacer un sous-compte à vous.",
  "pendingTransferHeading" : "Vous avez des demandes de sous-comptes en attente.",
  "subAccountTransfer" : "Sous-compter",
  "manageSubAccTransfer" : "Gérer vos demandes de transfert",
  "subAccRequest" : "Sous-compter des {tabName}",
  "receive" : "Hériter",
  "send" : "Envoyer",
  "release" : "Version",
  "requests" : "{count} Demandes",
  "subAccLimit" : "Nombre maximal d'emplacements atteint!",
  "subAccPlanLimit" : "Vous avez utilisé les 3/3 sous-comptes autorisés dans votre forfait actuel.",
  "upgradeTime" : "Il Date d'un .",
  "upgradeHigher" : "Veuillez mettre en attente pour accepter le sous-compte.",
  "browse" : "Parcourez les forfaits",
  "pendingTransferTitle" : "Une demande de transfert pour cette sous-compte est déjà en attente.",
  "unableTransfer" : "Impossible de transfert d'un sous-compte à cette agence.",
  "unableEjectCancel" : "Impossible d'annuler les sous-comptes",
  "EjectErrorDetails" : "Les demandes de sous-comptes que vous essayez d'annuler ne peuvent pas être annulées.",
  "hippaErrorDescription" : "Le sous-compte que vous essayez de téléverser est activé pour la .",
  "pendingTransferErrorDescription" : "Veuillez annuler les demandes de transfert de l'action pour ce sous-compte avant de continuer.",
  "receivingAgency" : "Reçu l'agence",
  "releasingAgency" : "Développeur d'agence",
  "noOfContacts" : "Non.",
  "requestedDate" : "Date demandée",
  "action" : "Action",
  "cancelTransfer" : "Annuler le Transfert",
  "decline" : "Rejeter",
  "cancelTransferRequest" : "Annuler des demandes de transfert?",
  "cancelEjectRequest" : "D'accord des sous-comptes?",
  "cancelRequestDescription" : "L'agence Reçu ne verra plus cette demande de transfert et le sous- {name} ne sera plus à froid.",
  "cancelEjectRequestDescription" : "L'utilisateur sélectionné ne sera plus en mesure de créer une nouvelle agence pour le {name} -compte.",
  "declineRequest" : "Rejeter les demandes de transfert?",
  "declineRequestDescription" : "Les demandes seront refusées et le sous-compte ne sera pas transfert à {name} par votre agence.",
  "approveTransfer" : "Sous-compte d'app à votre agence?",
  "approveTransferDescription" : "ID de la {name} -compte pour votre agence.",
  "transferNow" : "Transfert maintenant",
  "cancelNow" : "Annuler maintenant",
  "transferErrorDetails" : "Le sous-compte que vous essayez de déplacer ne peut pas être transfert à froid",
  "cancelRequest" : "Annuler des demandes",
  "received" : "Reçu",
  "approve" : "Approuver",
  "goBack" : "Annuler",
  "done" : "Terminé",
  "subAccountTransferred" : "Le sous-compte a été transfert avec succès!",
  "subAccountTransferDescription" : "Veuillez Note que même si Mailgun sous-compte a été transfert à froid, certains actifs peuvent toujours être reflétés in la destination.",
  "activeSubscriptionsAlert" : "L'emplacement a un abonnement dynamique. Veuillez annuler tout abonnement avant de l'emplacement à froid. Liste d'abonnements dynamiques : {subscriptions}",
  "isSaasEnabled" : "SaaS Activé",
  "typeToSearch" : "Type pour rechercher",
  "dateAdded" : "Date d’ajout",
  "emailEngagement" : "Affectation des Courriel",
  "emailReaction" : "Réaction par Courriel",
  "emailDeliverySummary" : "Résumé de la Livraison par Courriel",
  "facebook" : "Facebook",
  "sms" : "Message texte",
  "instagram" : "Instagram",
  "accountSummarySubAccount" : "Résumé du Compte - {subAccountName}",
  "test" : "Essai",
  "scheduleExists" : "Le calendrier existe",
  "aToZ" : "A - Zoom",
  "zToA" : "Zoom - A",
  "onlyAdminUsersCanAddNewSubAccounts" : "Seuls les administrateurs peuvent inclure de nouveaux sous-comptes.",
  "locationName" : "Nom de Emplacement",
  "transferCompleted" : "Les demandes de transfert pour cet emplacement sont déjà traitée. Veuillez rafraîchir.",
  "unauthorizedTransfer" : "Vous n'êtes pas autorisé à fournir cette Action. Seuls les propriétaires de l'agence peuvent fournir cette Action.",
  "bulkLocationTransfer" : "Sous-compte de transfert en masse",
  "singleRequest" : "Demandes individuelles",
  "bulkRequest" : "Demandes en masse",
  "backToLocationTransfer" : "Transfert de Retour à Emplacement",
  "initiateBulkTransfer" : "sous-compter en masse",
  "initiateBulkTransferDescription" : "Veuillez sélectionner le sous-compte, vous souhaitez obtenir des .",
  "subAccountId" : "Idde la sous-compte",
  "selectFromList" : "Sélectionner de la liste",
  "addManually" : "Ajouter manuelle",
  "accountsSelected" : "{count}",
  "selectAllSubAccounts" : "Sélectionner tout",
  "removeAll" : "Tout supplémentaire",
  "addManualLocations" : "Id de l'emplacement avec des séparateurs via com",
  "manualAllLocationIds" : "Id de l'emplacement du sous-compte, vous souhaitez effectuer un transfert, séparer les coms.",
  "enterLocationIds" : "Saisir l'Id de Emplacement",
  "selectSubAccounts" : "Sélectionner un sous-compte et un transfert vers une agence actuelle",
  "subAccountTransferWarning" : "Tous les sous-comptes et l'utilisateur auront reçu un transfert à froid.",
  "proceed" : "Poursuivre",
  "bulkRequestSubmitted" : "Demandes de transfert en masse soumises",
  "bulkRequestSubmittedDescription" : "Tous les sous-comptes seront sur demande une fois que l'agence Reçu accepte les demandes de transfert. Vous pouvez suivre le statut de la{pagename} . (Des qu'ils sont valides)",
  "bulkTransferRequestFailed" : "Les demandes de transfert en masse Échec",
  "errorLocations" : "Sous-compte avec erreur",
  "viewDetails" : "Voir les détails",
  "somethingWrong" : "Une erreur s'est produite! Veuillez réessayer.",
  "confirmBulkRequest" : "Confirmez-vous en masse?",
  "bulkTransferAccounts" : "Transfert en {count} '",
  "confirmDetails" : "Veuillez confirmer Détails avant le transfert à froid",
  "transferConsent" : "J'accepte la lecture et le consentement",
  "transferInfo" : "Sous-compte",
  "receivingRelNo" : "Nombre de contacts de l'agence",
  "enterRelNo" : "Saisissez le nombre de liens",
  "transferConfirmation" : "Une fois cette Action terminée, les demandes de transfert en masse seront envoyées à l'{ relNo } Tous les sous-comptes, vous avez sélectionnés seront transfert à froid une fois que l'agence de l'agence l'accepte. Veuillez Note que cette Action est irréversible pour tout sous-compte inclus in le transfert.",
  "confirmPassword" : "Confirmez en tapant via password ci-dessous",
  "enterPassword" : "Entrez le mot de passe",
  "requestTransfer" : "Transfert des demandes",
  "confirmTransfer" : "Confirmer le Transfert",
  "notNow" : "Pas maintenant",
  "invalidRelNo" : "Nombre de liens non valides",
  "invalidPassword" : "password non valide.",
  "searchSubAccounts" : "Rechercher un sous-compte",
  "searchSubAccountsId" : "Idde sous-compte",
  "takeAction" : "Entrer Action",
  "sub_account_details" : "Sous- profil",
  "no_of_contacts" : "Non de Amis",
  "transfer_status" : "Statut du Transfert",
  "error_info" : "Info sur Erreur",
  "bulk_transfer_status" : "Statut du transfert en masse",
  "pending" : "En attente",
  "processed" : "Traité",
  "reject_all" : "Rejeter tout",
  "accept_all" : "Accepter tout",
  "cancel_all" : "Annuler tout",
  "clearAll" : "Clair tout",
  "refresh" : "Rafraîchir",
  "rejected" : "Refuser",
  "decline_transfer" : "Rejeter le Transfert",
  "decline_bulk_transfer" : "Rejeter des demandes de transfert en masse ?",
  "decline_bulk_transfer_description" : "Les demandes seront refusées et le sous-compte de la Collection de {length} de .",
  "accept_bulk_transfer" : "Accepter les demandes de transfert en masse ?",
  "accept_bulk_transfer_description" : "Les demandes seront acceptés et le sous-compte de la Collection de {length} de .",
  "cancel_bulk_transfer" : "Annuler le Transfert En masse ?",
  "cancel_bulk_transfer_description" : "Voulez-vous vraiment {length} ce transfert en masse {name}",
  "consent_sub_account_transfer" : "Veuillez lire attentivement et fournir du consentement pour {link}",
  "sub_account_transfer_guidelines" : "Sous-compter des instructions de transfert",
  "valid_rel" : "Saisissez un nombre de lien valide",
  "something_went_wrong" : "Une erreur s'est produite! Veuillez réessayer.",
  "export" : "Exporter",
  "export_success" : "Les données que vous avez demandées sont en cours de traitement. Vous Hériter d'un courriel avec les données in une Date.",
  "export_failed" : "Les données que vous avez demandées n'ont pas pu être traitée. Veuillez réessayer plus tard.",
  "selectAllError" : "Vous ne pouvez afficher que 1000 sous-comptes à une Date.",
  "max1000" : "(Max 1000)",
  "subAccountCount" : "Nombre de sous-comptes"
}
